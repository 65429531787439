export const country_list = [
    { code: "BD", name: "Bangladesh" },
    { code: "BE", name: "Belgium" },
    { code: "BF", name: "Burkina Faso" },
    { code: "BG", name: "Bulgaria" },
    { code: "BA", name: "Bosnia and Herzegovina" },
    { code: "BB", name: "Barbados" },
    { code: "WF", name: "Wallis and Futuna" },
    { code: "BL", name: "Saint Barthelemy" },
    { code: "BM", name: "Bermuda" },
    { code: "BN", name: "Brunei" },
    { code: "BO", name: "Bolivia" },
    { code: "BH", name: "Bahrain" },
    { code: "BI", name: "Burundi" },
    { code: "BJ", name: "Benin" },
    { code: "BT", name: "Bhutan" },
    { code: "JM", name: "Jamaica" },
    { code: "BV", name: "Bouvet Island" },
    { code: "BW", name: "Botswana" },
    { code: "WS", name: "Samoa" },
    { code: "BQ", name: "Bonaire,Saint Eustatius and Saba " },
    { code: "BR", name: "Brazil" },
    { code: "BS", name: "Bahamas" },
    { code: "JE", name: "Jersey" },
    { code: "BY", name: "Belarus" },
    { code: "BZ", name: "Belize" },
    { code: "RU", name: "Russia" },
    { code: "RW", name: "Rwanda" },
    { code: "RS", name: "Serbia" },
    { code: "TL", name: "East Timor" },
    { code: "RE", name: "Reunion" },
    { code: "TM", name: "Turkmenistan" },
    { code: "TJ", name: "Tajikistan" },
    { code: "RO", name: "Romania" },
    { code: "TK", name: "Tokelau" },
    { code: "GW", name: "Guinea-Bissau" },
    { code: "GU", name: "Guam" },
    { code: "GT", name: "Guatemala" },
    { code: "GS", name: "South Georgia and the South Sandwich Islands" },
    { code: "GR", name: "Greece" },
    { code: "GQ", name: "Equatorial Guinea" },
    { code: "GP", name: "Guadeloupe" },
    { code: "JP", name: "Japan" },
    { code: "GY", name: "Guyana" },
    { code: "GG", name: "Guernsey" },
    { code: "GF", name: "French Guiana" },
    { code: "GE", name: "Georgia" },
    { code: "GD", name: "Grenada" },
    { code: "GB", name: "United Kingdom" },
    { code: "GA", name: "Gabon" },
    { code: "SV", name: "El Salvador" },
    { code: "GN", name: "Guinea" },
    { code: "GM", name: "Gambia" },
    { code: "GL", name: "Greenland" },
    { code: "GI", name: "Gibraltar" },
    { code: "GH", name: "Ghana" },
    { code: "OM", name: "Oman" },
    { code: "TN", name: "Tunisia" },
    { code: "JO", name: "Jordan" },
    { code: "HR", name: "Croatia" },
    { code: "HT", name: "Haiti" },
    { code: "HU", name: "Hungary" },
    { code: "HK", name: "Hong Kong" },
    { code: "HN", name: "Honduras" },
    { code: "HM", name: "Heard Island and McDonald Islands" },
    { code: "VE", name: "Venezuela" },
    { code: "PR", name: "Puerto Rico" },
    { code: "PS", name: "Palestinian Territory" },
    { code: "PW", name: "Palau", "PT": "Portugal" },
    { code: "SJ", name: "Svalbard and Jan Mayen" },
    { code: "PY", name: "Paraguay", "IQ": "Iraq" },
    { code: "PA", name: "Panama" },
    { code: "PF", name: "French Polynesia" },
    { code: "PG", name: "Papua New Guinea" },
    { code: "PE", name: "Peru" },
    { code: "PK", name: "Pakistan" },
    { code: "PH", name: "Philippines" },
    { code: "PN", name: "Pitcairn" },
    { code: "PL", name: "Poland" },
    { code: "PM", name: "Saint Pierre and Miquelon" },
    { code: "ZM", name: "Zambia" },
    { code: "EH", name: "Western Sahara" },
    { code: "EE", name: "Estonia" },
    { code: "EG", name: "Egypt" },
    { code: "ZA", name: "South Africa" },
    { code: "EC", name: "Ecuador" },
    { code: "IT", name: "Italy" },
    { code: "VN", name: "Vietnam" },
    { code: "SB", name: "Solomon Islands" },
    { code: "ET", name: "Ethiopia" },
    { code: "SO", name: "Somalia" },
    { code: "ZW", name: "Zimbabwe" },
    { code: "SA", name: "Saudi Arabia" },
    { code: "ES", name: "Spain" },
    { code: "ER", name: "Eritrea" },
    { code: "ME", name: "Montenegro" },
    { code: "MD", name: "Moldova" },
    { code: "MG", name: "Madagascar" },
    { code: "MF", name: "Saint Martin" },
    { code: "MA", name: "Morocco" },
    { code: "MC", name: "Monaco" },
    { code: "UZ", name: "Uzbekistan" },
    { code: "MM", name: "Myanmar" },
    { code: "ML", name: "Mali" },
    { code: "MO", name: "Macao" },
    { code: "MN", name: "Mongolia" },
    { code: "MH", name: "Marshall Islands" },
    { code: "MK", name: "Macedonia" },
    { code: "MU", name: "Mauritius" },
    { code: "MT", name: "Malta" },
    { code: "MW", name: "Malawi" },
    { code: "MV", name: "Maldives" },
    { code: "MQ", name: "Martinique" },
    { code: "MP", name: "Northern Mariana Islands" },
    { code: "MS", name: "Montserrat" },
    { code: "MR", name: "Mauritania" },
    { code: "IM", name: "Isle of Man" },
    { code: "UG", name: "Uganda" },
    { code: "TZ", name: "Tanzania" },
    { code: "MY", name: "Malaysia" },
    { code: "MX", name: "Mexico" },
    { code: "IL", name: "Israel" },
    { code: "FR", name: "France" },
    { code: "IO", name: "British Indian Ocean Territory" },
    { code: "SH", name: "Saint Helena" },
    { code: "FI", name: "Finland" },
    { code: "FJ", name: "Fiji" },
    { code: "FK", name: "Falkland Islands" },
    { code: "FM", name: "Micronesia" },
    { code: "FO", name: "Faroe Islands" },
    { code: "NI", name: "Nicaragua" },
    { code: "NL", name: "Netherlands" },
    { code: "NO", name: "Norway" },
    { code: "NA", name: "Namibia" },
    { code: "VU", name: "Vanuatu" },
    { code: "NC", name: "New Caledonia" },
    { code: "NE", name: "Niger" },
    { code: "NF", name: "Norfolk Island" },
    { code: "NG", name: "Nigeria" },
    { code: "NZ", name: "New Zealand" },
    { code: "NP", name: "Nepal" },
    { code: "NR", name: "Nauru" },
    { code: "NU", name: "Niue" },
    { code: "CK", name: "Cook Islands" },
    { code: "XK", name: "Kosovo" },
    { code: "CI", name: "Ivory Coast" },
    { code: "CH", name: "Switzerland" },
    { code: "CO", name: "Colombia" },
    { code: "CN", name: "China" },
    { code: "CM", name: "Cameroon" },
    { code: "CL", name: "Chile" },
    { code: "CC", name: "Cocos Islands" },
    { code: "CA", name: "Canada" },
    { code: "CG", name: "Republic of the Congo" },
    { code: "CF", name: "Central African Republic" },
    { code: "CD", name: "Democratic Republic of the Congo" },
    { code: "CZ", name: "Czech Republic" },
    { code: "CY", name: "Cyprus" },
    { code: "CX", name: "Christmas Island" },
    { code: "CR", name: "Costa Rica" },
    { code: "CW", name: "Curacao" },
    { code: "CV", name: "Cape Verde" },
    { code: "CU", name: "Cuba" },
    { code: "SZ", name: "Swaziland" },
    { code: "SY", name: "Syria" },
    { code: "SX", name: "Sint Maarten" },
    { code: "KG", name: "Kyrgyzstan" },
    { code: "KE", name: "Kenya" },
    { code: "SS", name: "South Sudan" },
    { code: "SR", name: "Suriname" },
    { code: "KI", name: "Kiribati" },
    { code: "KH", name: "Cambodia" },
    { code: "KN", name: "Saint Kitts and Nevis" },
    { code: "KM", name: "Comoros" },
    { code: "ST", name: "Sao Tome and Principe" },
    { code: "SK", name: "Slovakia" },
    { code: "KR", name: "South Korea" },
    { code: "SI", name: "Slovenia" },
    { code: "KP", name: "North Korea" },
    { code: "KW", name: "Kuwait" },
    { code: "SN", name: "Senegal" },
    { code: "SM", name: "San Marino" },
    { code: "SL", name: "Sierra Leone" },
    { code: "SC", name: "Seychelles" },
    { code: "KZ", name: "Kazakhstan" },
    { code: "KY", name: "Cayman Islands" },
    { code: "SG", name: "Singapore" },
    { code: "SE", name: "Sweden" },
    { code: "SD", name: "Sudan" },
    { code: "DO", name: "Dominican Republic" },
    { code: "DM", name: "Dominica" },
    { code: "DJ", name: "Djibouti" },
    { code: "DK", name: "Denmark" },
    { code: "VG", name: "British Virgin Islands" },
    { code: "DE", name: "Germany" },
    { code: "YE", name: "Yemen" },
    { code: "DZ", name: "Algeria" },
    { code: "US", name: "United States" },
    { code: "UY", name: "Uruguay" },
    { code: "YT", name: "Mayotte" },
    { code: "UM", name: "United States Minor Outlying Islands" },
    { code: "LB", name: "Lebanon" },
    { code: "LC", name: "Saint Lucia" },
    { code: "LA", name: "Laos" },
    { code: "TV", name: "Tuvalu" },
    { code: "TW", name: "Taiwan" },
    { code: "TT", name: "Trinidad and Tobago" },
    { code: "TR", name: "Turkey" },
    { code: "LK", name: "Sri Lanka" },
    { code: "LI", name: "Liechtenstein" },
    { code: "LV", name: "Latvia" },
    { code: "TO", name: "Tonga" },
    { code: "LT", name: "Lithuania" },
    { code: "LU", name: "Luxembourg" },
    { code: "LR", name: "Liberia" },
    { code: "LS", name: "Lesotho" },
    { code: "TH", name: "Thailand" },
    { code: "TF", name: "French Southern Territories" },
    { code: "TG", name: "Togo" },
    { code: "TD", name: "Chad" },
    { code: "TC", name: "Turks and Caicos Islands" },
    { code: "LY", name: "Libya" },
    { code: "VA", name: "Vatican" },
    { code: "VC", name: "Saint Vincent and the Grenadines" },
    { code: "AE", name: "United Arab Emirates" },
    { code: "AD", name: "Andorra" },
    { code: "AG", name: "Antigua and Barbuda" },
    { code: "AF", name: "Afghanistan" },
    { code: "AI", name: "Anguilla" },
    { code: "VI", name: "U.S. Virgin Islands" },
    { code: "IS", name: "Iceland" },
    { code: "IR", name: "Iran" },
    { code: "AM", name: "Armenia" },
    { code: "AL", name: "Albania" },
    { code: "AO", name: "Angola" },
    { code: "AQ", name: "Antarctica" },
    { code: "AS", name: "American Samoa" },
    { code: "AR", name: "Argentina" },
    { code: "AU", name: "Australia" },
    { code: "AT", name: "Austria" },
    { code: "AW", name: "Aruba" },
    { code: "IN", name: "India" },
    { code: "AX", name: "Aland Islands" },
    { code: "AZ", name: "Azerbaijan" },
    { code: "IE", name: "Ireland" },
    { code: "ID", name: "Indonesia" },
    { code: "UA", name: "Ukraine" },
    { code: "QA", name: "Qatar" },
    { code: "MZ", name: "Mozambique" }
].sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))

export const states = {
    "AF": [
        { "name": "Badakhshan", "state_code": "BDS" },

        { "name": "Badghis", "state_code": "BDG" },

        { "name": "Baghlan", "state_code": "BGL" },

        { "name": "Balkh", "state_code": "BAL" },

        { "name": "Bamyan", "state_code": "BAM" },

        { "name": "Daykundi", "state_code": "DAY" },
        { "name": "Farah", "state_code": "FRA" },
        { "name": "Faryab", "state_code": "FYB" },
        { "name": "Ghazni", "state_code": "GHA" },
        { "name": "Ghōr", "state_code": "GHO" },
        { "name": "Helmand", "state_code": "HEL" },
        { "name": "Herat", "state_code": "HER" },
        { "name": "Jowzjan", "state_code": "JOW" },
        { "name": "Kabul", "state_code": "KAB" },
        { "name": "Kandahar", "state_code": "KAN" },
        { "name": "Kapisa", "state_code": "KAP" },
        { "name": "Khost", "state_code": "KHO" },
        { "name": "Kunar", "state_code": "KNR" },
        { "name": "Kunduz Province", "state_code": "KDZ" },
        { "name": "Laghman", "state_code": "LAG" },
        { "name": "Logar", "state_code": "LOG" },
        { "name": "Nangarhar", "state_code": "NAN" },
        { "name": "Nimruz", "state_code": "NIM" },
        { "name": "Nuristan", "state_code": "NUR" },
        { "name": "Paktia", "state_code": "PIA" },
        { "name": "Paktika", "state_code": "PKA" },
        { "name": "Panjshir", "state_code": "PAN" },
        { "name": "Parwan", "state_code": "PAR" },
        { "name": "Samangan", "state_code": "SAM" },
        { "name": "Sar-e Pol", "state_code": "SAR" },
        { "name": "Takhar", "state_code": "TAK" },
        { "name": "Urozgan", "state_code": "URU" },
        { "name": "Zabul", "state_code": "ZAB" }],
    "AX": [],
    "AL": [{ "name": "Berat County", "state_code": "01" },
    { "name": "Berat District", "state_code": "BR" },
    { "name": "Bulqizë District", "state_code": "BU" },
    { "name": "Delvinë District", "state_code": "DL" },
    { "name": "Devoll District", "state_code": "DV" },
    { "name": "Dibër County", "state_code": "09" },
    { "name": "Dibër District", "state_code": "DI" },
    { "name": "Durrës County", "state_code": "02" },
    { "name": "Durrës District", "state_code": "DR" },
    { "name": "Elbasan County", "state_code": "03" },
    { "name": "Fier County", "state_code": "04" },
    { "name": "Fier District", "state_code": "FR" },
    { "name": "Gjirokastër County", "state_code": "05" },
    { "name": "Gjirokastër District", "state_code": "GJ" },
    { "name": "Gramsh District", "state_code": "GR" },
    { "name": "Has District", "state_code": "HA" },
    { "name": "Kavajë District", "state_code": "KA" },
    { "name": "Kolonjë District", "state_code": "ER" },
    { "name": "Korçë County", "state_code": "06" },
    { "name": "Korçë District", "state_code": "KO" },
    { "name": "Krujë District", "state_code": "KR" },
    { "name": "Kuçovë District", "state_code": "KC" },
    { "name": "Kukës County", "state_code": "07" },
    { "name": "Kukës District", "state_code": "KU" },
    { "name": "Kurbin District", "state_code": "KB" },
    { "name": "Lezhë County", "state_code": "08" },
    { "name": "Lezhë District", "state_code": "LE" },
    { "name": "Librazhd District", "state_code": "LB" },
    { "name": "Lushnjë District", "state_code": "LU" },
    { "name": "Malësi e Madhe District", "state_code": "MM" },
    { "name": "Mallakastër District", "state_code": "MK" },
    { "name": "Mat District", "state_code": "MT" },
    { "name": "Mirditë District", "state_code": "MR" },
    { "name": "Peqin District", "state_code": "PQ" },
    { "name": "Përmet District", "state_code": "PR" },
    { "name": "Pogradec District", "state_code": "PG" },
    { "name": "Pukë District", "state_code": "PU" },
    { "name": "Sarandë District", "state_code": "SR" },
    { "name": "Shkodër County", "state_code": "10" },
    { "name": "Shkodër District", "state_code": "SH" },
    { "name": "Skrapar District", "state_code": "SK" },
    { "name": "Tepelenë District", "state_code": "TE" },
    { "name": "Tirana County", "state_code": "11" },
    { "name": "Tirana District", "state_code": "TR" },
    { "name": "Tropojë District", "state_code": "TP" },
    { "name": "Vlorë County", "state_code": "12" },
    { "name": "Vlorë District", "state_code": "VL" }],
    "DZ": [{ "name": "Adrar", "state_code": "01" },
    { "name": "Aïn Defla", "state_code": "44" },
    { "name": "Aïn Témouchent", "state_code": "46" },
    { "name": "Algiers", "state_code": "16" },
    { "name": "Annaba", "state_code": "23" },
    { "name": "Batna", "state_code": "05" },
    { "name": "Béchar", "state_code": "08" },
    { "name": "Béjaïa", "state_code": "06" },
    { "name": "Béni Abbès", "state_code": "53" },
    { "name": "Biskra", "state_code": "07" },
    { "name": "Blida", "state_code": "09" },
    { "name": "Bordj Baji Mokhtar", "state_code": "52" },
    { "name": "Bordj Bou Arréridj", "state_code": "34" },
    { "name": "Bouïra", "state_code": "10" },
    { "name": "Boumerdès", "state_code": "35" },
    { "name": "Chlef", "state_code": "02" },
    { "name": "Constantine", "state_code": "25" },
    { "name": "Djanet", "state_code": "56" },
    { "name": "Djelfa", "state_code": "17" },
    { "name": "El Bayadh", "state_code": "32" },
    { "name": "El M'ghair", "state_code": "49" },
    { "name": "El Menia", "state_code": "50" },
    { "name": "El Oued", "state_code": "39" },
    { "name": "El Tarf", "state_code": "36" },
    { "name": "Ghardaïa", "state_code": "47" },
    { "name": "Guelma", "state_code": "24" },
    { "name": "Illizi", "state_code": "33" },
    { "name": "In Guezzam", "state_code": "58" },
    { "name": "In Salah", "state_code": "57" },
    { "name": "Jijel", "state_code": "18" },
    { "name": "Khenchela", "state_code": "40" },
    { "name": "Laghouat", "state_code": "03" },
    { "name": "M'Sila", "state_code": "28" },
    { "name": "Mascara", "state_code": "29" },
    { "name": "Médéa", "state_code": "26" },
    { "name": "Mila", "state_code": "43" },
    { "name": "Mostaganem", "state_code": "27" },
    { "name": "Naama", "state_code": "45" },
    { "name": "Oran", "state_code": "31" },
    { "name": "Ouargla", "state_code": "30" },
    { "name": "Ouled Djellal", "state_code": "51" },
    { "name": "Oum El Bouaghi", "state_code": "04" },
    { "name": "Relizane", "state_code": "48" },
    { "name": "Saïda", "state_code": "20" },
    { "name": "Sétif", "state_code": "19" },
    { "name": "Sidi Bel Abbès", "state_code": "22" },
    { "name": "Skikda", "state_code": "21" },
    { "name": "Souk Ahras", "state_code": "41" },
    { "name": "Tamanghasset", "state_code": "11" },
    { "name": "Tébessa", "state_code": "12" },
    { "name": "Tiaret", "state_code": "14" },
    { "name": "Timimoun", "state_code": "54" },
    { "name": "Tindouf", "state_code": "37" },
    { "name": "Tipasa", "state_code": "42" },
    { "name": "Tissemsilt", "state_code": "38" },
    { "name": "Tizi Ouzou", "state_code": "15" },
    { "name": "Tlemcen", "state_code": "13" },
    { "name": "Touggourt", "state_code": "55" }],
    "AS": [],
    "AD": [{ "name": "Andorra la Vella", "state_code": "07" },
    { "name": "Canillo", "state_code": "02" },
    { "name": "Encamp", "state_code": "03" },
    { "name": "Escaldes-Engordany", "state_code": "08" },
    { "name": "La Massana", "state_code": "04" },
    { "name": "Ordino", "state_code": "05" },
    { "name": "Sant Julià de Lòria", "state_code": "06" }],
    "AO": [{ "name": "Bengo Province", "state_code": "BGO" },
    { "name": "Benguela Province", "state_code": "BGU" },
    { "name": "Bié Province", "state_code": "BIE" },
    { "name": "Cabinda Province", "state_code": "CAB" },
    { "name": "Cuando Cubango Province", "state_code": "CCU" },
    { "name": "Cuanza Norte Province", "state_code": "CNO" },
    { "name": "Cuanza Sul", "state_code": "CUS" },
    { "name": "Cunene Province", "state_code": "CNN" },
    { "name": "Huambo Province", "state_code": "HUA" },
    { "name": "Huíla Province", "state_code": "HUI" },
    { "name": "Luanda Province", "state_code": "LUA" },
    { "name": "Lunda Norte Province", "state_code": "LNO" },
    { "name": "Lunda Sul Province", "state_code": "LSU" },
    { "name": "Malanje Province", "state_code": "MAL" },
    { "name": "Moxico Province", "state_code": "MOX" },
    { "name": "Uíge Province", "state_code": "UIG" },
    { "name": "Zaire Province", "state_code": "ZAI" }],
    "AI": [],
    "AQ": [],
    "AG": [{ "name": "Barbuda", "state_code": "10" },
    { "name": "Redonda", "state_code": "11" },
    { "name": "Saint George Parish", "state_code": "03" },
    { "name": "Saint John Parish", "state_code": "04" },
    { "name": "Saint Mary Parish", "state_code": "05" },
    { "name": "Saint Paul Parish", "state_code": "06" },
    { "name": "Saint Peter Parish", "state_code": "07" },
    { "name": "Saint Philip Parish", "state_code": "08" }],
    "AR": [{ "name": "Buenos Aires", "state_code": "B" },
    { "name": "Catamarca", "state_code": "K" },
    { "name": "Chaco", "state_code": "H" },
    { "name": "Chubut", "state_code": "U" },
    { "name": "Ciudad Autónoma de Buenos Aires", "state_code": "C" },
    { "name": "Córdoba", "state_code": "X" },
    { "name": "Corrientes", "state_code": "W" },
    { "name": "Entre Ríos", "state_code": "E" },
    { "name": "Formosa", "state_code": "P" },
    { "name": "Jujuy", "state_code": "Y" },
    { "name": "La Pampa", "state_code": "L" },
    { "name": "La Rioja", "state_code": "F" },
    { "name": "Mendoza", "state_code": "M" },
    { "name": "Misiones", "state_code": "N" },
    { "name": "Neuquén", "state_code": "Q" },
    { "name": "Río Negro", "state_code": "R" },
    { "name": "Salta", "state_code": "A" },
    { "name": "San Juan", "state_code": "J" },
    { "name": "San Luis", "state_code": "D" },
    { "name": "Santa Cruz", "state_code": "Z" },
    { "name": "Santa Fe", "state_code": "S" },
    { "name": "Santiago del Estero", "state_code": "G" },
    { "name": "Tierra del Fuego", "state_code": "V" },
    { "name": "Tucumán", "state_code": "T" }],
    "AM": [{ "name": "Aragatsotn Region", "state_code": "AG" },
    { "name": "Ararat Province", "state_code": "AR" },
    { "name": "Armavir Region", "state_code": "AV" },
    { "name": "Gegharkunik Province", "state_code": "GR" },
    { "name": "Kotayk Region", "state_code": "KT" },
    { "name": "Lori Region", "state_code": "LO" },
    { "name": "Shirak Region", "state_code": "SH" },
    { "name": "Syunik Province", "state_code": "SU" },
    { "name": "Tavush Region", "state_code": "TV" },
    { "name": "Vayots Dzor Region", "state_code": "VD" },
    { "name": "Yerevan", "state_code": "ER" }],
    "AW": [],
    "AU": [{ "name": "Australian Capital Territory", "state_code": "ACT" },
    { "name": "New South Wales", "state_code": "NSW" },
    { "name": "Northern Territory", "state_code": "NT" },
    { "name": "Queensland", "state_code": "QLD" },
    { "name": "South Australia", "state_code": "SA" },
    { "name": "Tasmania", "state_code": "TAS" },
    { "name": "Victoria", "state_code": "VIC" },
    { "name": "Western Australia", "state_code": "WA" }],
    "AT": [{ "name": "Burgenland", "state_code": "1" },
    { "name": "Carinthia", "state_code": "2" },
    { "name": "Lower Austria", "state_code": "3" },
    { "name": "Salzburg", "state_code": "5" },
    { "name": "Styria", "state_code": "6" },
    { "name": "Tyrol", "state_code": "7" },
    { "name": "Upper Austria", "state_code": "4" },
    { "name": "Vienna", "state_code": "9" },
    { "name": "Vorarlberg", "state_code": "8" }],
    "AZ": [{ "name": "Absheron District", "state_code": "ABS" },
    { "name": "Agdam District", "state_code": "AGM" },
    { "name": "Agdash District", "state_code": "AGS" },
    { "name": "Aghjabadi District", "state_code": "AGC" },
    { "name": "Agstafa District", "state_code": "AGA" },
    { "name": "Agsu District", "state_code": "AGU" },
    { "name": "Astara District", "state_code": "AST" },
    { "name": "Babek District", "state_code": "BAB" },
    { "name": "Baku", "state_code": "BA" },
    { "name": "Balakan District", "state_code": "BAL" },
    { "name": "Barda District", "state_code": "BAR" },
    { "name": "Beylagan District", "state_code": "BEY" },
    { "name": "Bilasuvar District", "state_code": "BIL" },
    { "name": "Dashkasan District", "state_code": "DAS" },
    { "name": "Fizuli District", "state_code": "FUZ" },
    { "name": "Ganja", "state_code": "GA" },
    { "name": "Gədəbəy", "state_code": "GAD" },
    { "name": "Gobustan District", "state_code": "QOB" },
    { "name": "Goranboy District", "state_code": "GOR" },
    { "name": "Goychay", "state_code": "GOY" },
    { "name": "Goygol District", "state_code": "GYG" },
    { "name": "Hajigabul District", "state_code": "HAC" },
    { "name": "Imishli District", "state_code": "IMI" },
    { "name": "Ismailli District", "state_code": "ISM" },
    { "name": "Jabrayil District", "state_code": "CAB" },
    { "name": "Jalilabad District", "state_code": "CAL" },
    { "name": "Julfa District", "state_code": "CUL" },
    { "name": "Kalbajar District", "state_code": "KAL" },
    { "name": "Kangarli District", "state_code": "KAN" },
    { "name": "Khachmaz District", "state_code": "XAC" },
    { "name": "Khizi District", "state_code": "XIZ" },
    { "name": "Khojali District", "state_code": "XCI" },
    { "name": "Kurdamir District", "state_code": "KUR" },
    { "name": "Lachin District", "state_code": "LAC" },
    { "name": "Lankaran", "state_code": "LAN" },
    { "name": "Lankaran District", "state_code": "LA" },
    { "name": "Lerik District", "state_code": "LER" },
    { "name": "Martuni", "state_code": "XVD" },
    { "name": "Masally District", "state_code": "MAS" },
    { "name": "Mingachevir", "state_code": "MI" },
    { "name": "Nakhchivan Autonomous Republic", "state_code": "NX" },
    { "name": "Neftchala District", "state_code": "NEF" },
    { "name": "Oghuz District", "state_code": "OGU" },
    { "name": "Ordubad District", "state_code": "ORD" },
    { "name": "Qabala District", "state_code": "QAB" },
    { "name": "Qakh District", "state_code": "QAX" },
    { "name": "Qazakh District", "state_code": "QAZ" },
    { "name": "Quba District", "state_code": "QBA" },
    { "name": "Qubadli District", "state_code": "QBI" },
    { "name": "Qusar District", "state_code": "QUS" },
    { "name": "Saatly District", "state_code": "SAT" },
    { "name": "Sabirabad District", "state_code": "SAB" },
    { "name": "Sadarak District", "state_code": "SAD" },
    { "name": "Salyan District", "state_code": "SAL" },
    { "name": "Samukh District", "state_code": "SMX" },
    { "name": "Shabran District", "state_code": "SBN" },
    { "name": "Shahbuz District", "state_code": "SAH" },
    { "name": "Shaki", "state_code": "SA" },
    { "name": "Shaki District", "state_code": "SAK" },
    { "name": "Shamakhi District", "state_code": "SMI" },
    { "name": "Shamkir District", "state_code": "SKR" },
    { "name": "Sharur District", "state_code": "SAR" },
    { "name": "Shirvan", "state_code": "SR" },
    { "name": "Shusha District", "state_code": "SUS" },
    { "name": "Siazan District", "state_code": "SIY" },
    { "name": "Sumqayit", "state_code": "SM" },
    { "name": "Tartar District", "state_code": "TAR" },
    { "name": "Tovuz District", "state_code": "TOV" },
    { "name": "Ujar District", "state_code": "UCA" },
    { "name": "Yardymli District", "state_code": "YAR" },
    { "name": "Yevlakh", "state_code": "YE" },
    { "name": "Yevlakh District", "state_code": "YEV" },
    { "name": "Zangilan District", "state_code": "ZAN" },
    { "name": "Zaqatala District", "state_code": "ZAQ" },
    { "name": "Zardab District", "state_code": "ZAR" }],
    "BH": [{ "name": "Capital Governorate", "state_code": "13" },
    { "name": "Central Governorate", "state_code": "16" },
    { "name": "Muharraq Governorate", "state_code": "15" },
    { "name": "Northern Governorate", "state_code": "17" },
    { "name": "Southern Governorate", "state_code": "14" }],
    "BD": [{ "name": "Bagerhat District", "state_code": "05" },
    { "name": "Bahadia", "state_code": "33" },
    { "name": "Bandarban District", "state_code": "01" },
    { "name": "Barguna District", "state_code": "02" },
    { "name": "Barisal District", "state_code": "06" },
    { "name": "Barisal Division", "state_code": "A" },
    { "name": "Bhola District", "state_code": "07" },
    { "name": "Bogra District", "state_code": "03" },
    { "name": "Brahmanbaria District", "state_code": "04" },
    { "name": "Chandpur District", "state_code": "09" },
    { "name": "Chapai Nawabganj District", "state_code": "45" },
    { "name": "Chittagong District", "state_code": "10" },
    { "name": "Chittagong Division", "state_code": "B" },
    { "name": "Chuadanga District", "state_code": "12" },
    { "name": "Comilla District", "state_code": "08" },
    { "name": "Cox's Bazar District", "state_code": "11" },
    { "name": "Dhaka District", "state_code": "13" },
    { "name": "Dhaka Division", "state_code": "C" },
    { "name": "Dinajpur District", "state_code": "14" },
    { "name": "Faridpur District", "state_code": "15" },
    { "name": "Feni District", "state_code": "16" },
    { "name": "Gaibandha District", "state_code": "19" },
    { "name": "Gazipur District", "state_code": "18" },
    { "name": "Gopalganj District", "state_code": "17" },
    { "name": "Habiganj District", "state_code": "20" },
    { "name": "Jamalpur District", "state_code": "21" },
    { "name": "Jessore District", "state_code": "22" },
    { "name": "Jhalokati District", "state_code": "25" },
    { "name": "Jhenaidah District", "state_code": "23" },
    { "name": "Joypurhat District", "state_code": "24" },
    { "name": "Khagrachari District", "state_code": "29" },
    { "name": "Khulna District", "state_code": "27" },
    { "name": "Khulna Division", "state_code": "D" },
    { "name": "Kishoreganj District", "state_code": "26" },
    { "name": "Kurigram District", "state_code": "28" },
    { "name": "Kushtia District", "state_code": "30" },
    { "name": "Lakshmipur District", "state_code": "31" },
    { "name": "Lalmonirhat District", "state_code": "32" },
    { "name": "Madaripur District", "state_code": "36" },
    { "name": "Meherpur District", "state_code": "39" },
    { "name": "Moulvibazar District", "state_code": "38" },
    { "name": "Munshiganj District", "state_code": "35" },
    { "name": "Mymensingh District", "state_code": "34" },
    { "name": "Mymensingh Division", "state_code": "H" },
    { "name": "Naogaon District", "state_code": "48" },
    { "name": "Narail District", "state_code": "43" },
    { "name": "Narayanganj District", "state_code": "40" },
    { "name": "Natore District", "state_code": "44" },
    { "name": "Netrokona District", "state_code": "41" },
    { "name": "Nilphamari District", "state_code": "46" },
    { "name": "Noakhali District", "state_code": "47" },
    { "name": "Pabna District", "state_code": "49" },
    { "name": "Panchagarh District", "state_code": "52" },
    { "name": "Patuakhali District", "state_code": "51" },
    { "name": "Pirojpur District", "state_code": "50" },
    { "name": "Rajbari District", "state_code": "53" },
    { "name": "Rajshahi District", "state_code": "54" },
    { "name": "Rajshahi Division", "state_code": "E" },
    { "name": "Rangamati Hill District", "state_code": "56" },
    { "name": "Rangpur District", "state_code": "55" },
    { "name": "Rangpur Division", "state_code": "F" },
    { "name": "Satkhira District", "state_code": "58" },
    { "name": "Shariatpur District", "state_code": "62" },
    { "name": "Sherpur District", "state_code": "57" },
    { "name": "Sirajganj District", "state_code": "59" },
    { "name": "Sunamganj District", "state_code": "61" },
    { "name": "Sylhet District", "state_code": "60" },
    { "name": "Sylhet Division", "state_code": "G" },
    { "name": "Tangail District", "state_code": "63" },
    { "name": "Thakurgaon District", "state_code": "64" }],
    "BB": [{ "name": "Christ Church", "state_code": "01" },
    { "name": "Saint Andrew", "state_code": "02" },
    { "name": "Saint George", "state_code": "03" },
    { "name": "Saint James", "state_code": "04" },
    { "name": "Saint John", "state_code": "05" },
    { "name": "Saint Joseph", "state_code": "06" },
    { "name": "Saint Lucy", "state_code": "07" },
    { "name": "Saint Michael", "state_code": "08" },
    { "name": "Saint Peter", "state_code": "09" },
    { "name": "Saint Philip", "state_code": "10" },
    { "name": "Saint Thomas", "state_code": "11" }],
    "BY": [{ "name": "Brest Region", "state_code": "BR" },
    { "name": "Gomel Region", "state_code": "HO" },
    { "name": "Grodno Region", "state_code": "HR" },
    { "name": "Minsk", "state_code": "HM" },
    { "name": "Minsk Region", "state_code": "MI" },
    { "name": "Mogilev Region", "state_code": "MA" },
    { "name": "Vitebsk Region", "state_code": "VI" }],
    "BE": [{ "name": "Antwerp", "state_code": "VAN" },
    { "name": "Brussels-Capital Region", "state_code": "BRU" },
    { "name": "East Flanders", "state_code": "VOV" },
    { "name": "Flanders", "state_code": "VLG" },
    { "name": "Flemish Brabant", "state_code": "VBR" },
    { "name": "Hainaut", "state_code": "WHT" },
    { "name": "Liège", "state_code": "WLG" },
    { "name": "Limburg", "state_code": "VLI" },
    { "name": "Luxembourg", "state_code": "WLX" },
    { "name": "Namur", "state_code": "WNA" },
    { "name": "Wallonia", "state_code": "WAL" },
    { "name": "Walloon Brabant", "state_code": "WBR" },
    { "name": "West Flanders", "state_code": "VWV" }],
    "BZ": [{ "name": "Belize District", "state_code": "BZ" },
    { "name": "Cayo District", "state_code": "CY" },
    { "name": "Corozal District", "state_code": "CZL" },
    { "name": "Orange Walk District", "state_code": "OW" },
    { "name": "Stann Creek District", "state_code": "SC" },
    { "name": "Toledo District", "state_code": "TOL" }],
    "BJ": [{ "name": "Alibori Department", "state_code": "AL" },
    { "name": "Atakora Department", "state_code": "AK" },
    { "name": "Atlantique Department", "state_code": "AQ" },
    { "name": "Borgou Department", "state_code": "BO" },
    { "name": "Collines Department", "state_code": "CO" },
    { "name": "Donga Department", "state_code": "DO" },
    { "name": "Kouffo Department", "state_code": "KO" },
    { "name": "Littoral Department", "state_code": "LI" },
    { "name": "Mono Department", "state_code": "MO" },
    { "name": "Ouémé Department", "state_code": "OU" },
    { "name": "Plateau Department", "state_code": "PL" },
    { "name": "Zou Department", "state_code": "ZO" }],
    "BM": [{ "name": "Devonshire Parish", "state_code": "DEV" },
    { "name": "Hamilton Parish", "state_code": "HA" },
    { "name": "Paget Parish", "state_code": "PAG" },
    { "name": "Pembroke Parish", "state_code": "PEM" },
    { "name": "Saint George's Parish", "state_code": "SGE" },
    { "name": "Sandys Parish", "state_code": "SAN" },
    { "name": "Smith's Parish,", "state_code": "SMI" },
    { "name": "Southampton Parish", "state_code": "SOU" },
    { "name": "Warwick Parish", "state_code": "WAR" }],
    "BT": [{ "name": "Bumthang District", "state_code": "33" },
    { "name": "Chukha District", "state_code": "12" },
    { "name": "Dagana District", "state_code": "22" },
    { "name": "Gasa District", "state_code": "GA" },
    { "name": "Haa District", "state_code": "13" },
    { "name": "Lhuntse District", "state_code": "44" },
    { "name": "Mongar District", "state_code": "42" },
    { "name": "Paro District", "state_code": "11" },
    { "name": "Pemagatshel District", "state_code": "43" },
    { "name": "Punakha District", "state_code": "23" },
    { "name": "Samdrup Jongkhar District", "state_code": "45" },
    { "name": "Samtse District", "state_code": "14" },
    { "name": "Sarpang District", "state_code": "31" },
    { "name": "Thimphu District", "state_code": "15" },
    { "name": "Trashigang District", "state_code": "41" },
    { "name": "Trongsa District", "state_code": "32" },
    { "name": "Tsirang District", "state_code": "21" },
    { "name": "Wangdue Phodrang District", "state_code": "24" },
    { "name": "Zhemgang District", "state_code": "34" }],
    "BO": [{ "name": "Beni Department", "state_code": "B" },
    { "name": "Chuquisaca Department", "state_code": "H" },
    { "name": "Cochabamba Department", "state_code": "C" },
    { "name": "La Paz Department", "state_code": "L" },
    { "name": "Oruro Department", "state_code": "O" },
    { "name": "Pando Department", "state_code": "N" },
    { "name": "Potosí Department", "state_code": "P" },
    { "name": "Santa Cruz Department", "state_code": "S" },
    { "name": "Tarija Department", "state_code": "T" }],
    "BQ": [],
    "BA": [{ "name": "Bosnian Podrinje Canton", "state_code": "05" },
    { "name": "Brčko District", "state_code": "BRC" },
    { "name": "Canton 10", "state_code": "10" },
    { "name": "Central Bosnia Canton", "state_code": "06" },
    { "name": "Federation of Bosnia and Herzegovina", "state_code": "BIH" },
    { "name": "Herzegovina-Neretva Canton", "state_code": "07" },
    { "name": "Posavina Canton", "state_code": "02" },
    { "name": "Republika Srpska", "state_code": "SRP" },
    { "name": "Sarajevo Canton", "state_code": "09" },
    { "name": "Tuzla Canton", "state_code": "03" },
    { "name": "Una-Sana Canton", "state_code": "01" },
    { "name": "West Herzegovina Canton", "state_code": "08" },
    { "name": "Zenica-Doboj Canton", "state_code": "04" }],
    "BW": [{ "name": "Central District", "state_code": "CE" },
    { "name": "Ghanzi District", "state_code": "GH" },
    { "name": "Kgalagadi District", "state_code": "KG" },
    { "name": "Kgatleng District", "state_code": "KL" },
    { "name": "Kweneng District", "state_code": "KW" },
    { "name": "Ngamiland", "state_code": "NG" },
    { "name": "North-East District", "state_code": "NE" },
    { "name": "North-West District", "state_code": "NW" },
    { "name": "South-East District", "state_code": "SE" },
    { "name": "Southern District", "state_code": "SO" }],
    "BV": [],
    "BR": [{ "name": "Acre", "state_code": "AC" },
    { "name": "Alagoas", "state_code": "AL" },
    { "name": "Amapá", "state_code": "AP" },
    { "name": "Amazonas", "state_code": "AM" },
    { "name": "Bahia", "state_code": "BA" },
    { "name": "Ceará", "state_code": "CE" },
    { "name": "Distrito Federal", "state_code": "DF" },
    { "name": "Espírito Santo", "state_code": "ES" },
    { "name": "Goiás", "state_code": "GO" },
    { "name": "Maranhão", "state_code": "MA" },
    { "name": "Mato Grosso", "state_code": "MT" },
    { "name": "Mato Grosso do Sul", "state_code": "MS" },
    { "name": "Minas Gerais", "state_code": "MG" },
    { "name": "Pará", "state_code": "PA" },
    { "name": "Paraíba", "state_code": "PB" },
    { "name": "Paraná", "state_code": "PR" },
    { "name": "Pernambuco", "state_code": "PE" },
    { "name": "Piauí", "state_code": "PI" },
    { "name": "Rio de Janeiro", "state_code": "RJ" },
    { "name": "Rio Grande do Norte", "state_code": "RN" },
    { "name": "Rio Grande do Sul", "state_code": "RS" },
    { "name": "Rondônia", "state_code": "RO" },
    { "name": "Roraima", "state_code": "RR" },
    { "name": "Santa Catarina", "state_code": "SC" },
    { "name": "São Paulo", "state_code": "SP" },
    { "name": "Sergipe", "state_code": "SE" },
    { "name": "Tocantins", "state_code": "TO" }],
    "IO": [],
    "BN": [{ "name": "Belait District", "state_code": "BE" },
    { "name": "Brunei-Muara District", "state_code": "BM" },
    { "name": "Temburong District", "state_code": "TE" },
    { "name": "Tutong District", "state_code": "TU" }],
    "BG": [{ "name": "Blagoevgrad Province", "state_code": "01" },
    { "name": "Burgas Province", "state_code": "02" },
    { "name": "Dobrich Province", "state_code": "08" },
    { "name": "Gabrovo Province", "state_code": "07" },
    { "name": "Haskovo Province", "state_code": "26" },
    { "name": "Kardzhali Province", "state_code": "09" },
    { "name": "Kyustendil Province", "state_code": "10" },
    { "name": "Lovech Province", "state_code": "11" },
    { "name": "Montana Province", "state_code": "12" },
    { "name": "Pazardzhik Province", "state_code": "13" },
    { "name": "Pernik Province", "state_code": "14" },
    { "name": "Pleven Province", "state_code": "15" },
    { "name": "Plovdiv Province", "state_code": "16" },
    { "name": "Razgrad Province", "state_code": "17" },
    { "name": "Ruse Province", "state_code": "18" },
    { "name": "Shumen", "state_code": "27" },
    { "name": "Silistra Province", "state_code": "19" },
    { "name": "Sliven Province", "state_code": "20" },
    { "name": "Smolyan Province", "state_code": "21" },
    { "name": "Sofia City Province", "state_code": "22" },
    { "name": "Sofia Province", "state_code": "23" },
    { "name": "Stara Zagora Province", "state_code": "24" },
    { "name": "Targovishte Province", "state_code": "25" },
    { "name": "Varna Province", "state_code": "03" },
    { "name": "Veliko Tarnovo Province", "state_code": "04" },
    { "name": "Vidin Province", "state_code": "05" },
    { "name": "Vratsa Province", "state_code": "06" },
    { "name": "Yambol Province", "state_code": "28" }],
    "BF": [{ "name": "Balé Province", "state_code": "BAL" },
    { "name": "Bam Province", "state_code": "BAM" },
    { "name": "Banwa Province", "state_code": "BAN" },
    { "name": "Bazèga Province", "state_code": "BAZ" },
    { "name": "Boucle du Mouhoun Region", "state_code": "01" },
    { "name": "Bougouriba Province", "state_code": "BGR" },
    { "name": "Boulgou", "state_code": "BLG" },
    { "name": "Cascades Region", "state_code": "02" },
    { "name": "Centre", "state_code": "03" },
    { "name": "Centre-Est Region", "state_code": "04" },
    { "name": "Centre-Nord Region", "state_code": "05" },
    { "name": "Centre-Ouest Region", "state_code": "06" },
    { "name": "Centre-Sud Region", "state_code": "07" },
    { "name": "Comoé Province", "state_code": "COM" },
    { "name": "Est Region", "state_code": "08" },
    { "name": "Ganzourgou Province", "state_code": "GAN" },
    { "name": "Gnagna Province", "state_code": "GNA" },
    { "name": "Gourma Province", "state_code": "GOU" },
    { "name": "Hauts-Bassins Region", "state_code": "09" },
    { "name": "Houet Province", "state_code": "HOU" },
    { "name": "Ioba Province", "state_code": "IOB" },
    { "name": "Kadiogo Province", "state_code": "KAD" },
    { "name": "Kénédougou Province", "state_code": "KEN" },
    { "name": "Komondjari Province", "state_code": "KMD" },
    { "name": "Kompienga Province", "state_code": "KMP" },
    { "name": "Kossi Province", "state_code": "KOS" },
    { "name": "Koulpélogo Province", "state_code": "KOP" },
    { "name": "Kouritenga Province", "state_code": "KOT" },
    { "name": "Kourwéogo Province", "state_code": "KOW" },
    { "name": "Léraba Province", "state_code": "LER" },
    { "name": "Loroum Province", "state_code": "LOR" },
    { "name": "Mouhoun", "state_code": "MOU" },
    { "name": "Nahouri Province", "state_code": "NAO" },
    { "name": "Namentenga Province", "state_code": "NAM" },
    { "name": "Nayala Province", "state_code": "NAY" },
    { "name": "Nord Region, Burkina Faso", "state_code": "10" },
    { "name": "Noumbiel Province", "state_code": "NOU" },
    { "name": "Oubritenga Province", "state_code": "OUB" },
    { "name": "Oudalan Province", "state_code": "OUD" },
    { "name": "Passoré Province", "state_code": "PAS" },
    { "name": "Plateau-Central Region", "state_code": "11" },
    { "name": "Poni Province", "state_code": "PON" },
    { "name": "Sahel Region", "state_code": "12" },
    { "name": "Sanguié Province", "state_code": "SNG" },
    { "name": "Sanmatenga Province", "state_code": "SMT" },
    { "name": "Séno Province", "state_code": "SEN" },
    { "name": "Sissili Province", "state_code": "SIS" },
    { "name": "Soum Province", "state_code": "SOM" },
    { "name": "Sourou Province", "state_code": "SOR" },
    { "name": "Sud-Ouest Region", "state_code": "13" },
    { "name": "Tapoa Province", "state_code": "TAP" },
    { "name": "Tuy Province", "state_code": "TUI" },
    { "name": "Yagha Province", "state_code": "YAG" },
    { "name": "Yatenga Province", "state_code": "YAT" },
    { "name": "Ziro Province", "state_code": "ZIR" },
    { "name": "Zondoma Province", "state_code": "ZON" },
    { "name": "Zoundwéogo Province", "state_code": "ZOU" }],
    "BI": [{ "name": "Bubanza Province", "state_code": "BB" },
    { "name": "Bujumbura Mairie Province", "state_code": "BM" },
    { "name": "Bujumbura Rural Province", "state_code": "BL" },
    { "name": "Bururi Province", "state_code": "BR" },
    { "name": "Cankuzo Province", "state_code": "CA" },
    { "name": "Cibitoke Province", "state_code": "CI" },
    { "name": "Gitega Province", "state_code": "GI" },
    { "name": "Karuzi Province", "state_code": "KR" },
    { "name": "Kayanza Province", "state_code": "KY" },
    { "name": "Kirundo Province", "state_code": "KI" },
    { "name": "Makamba Province", "state_code": "MA" },
    { "name": "Muramvya Province", "state_code": "MU" },
    { "name": "Muyinga Province", "state_code": "MY" },
    { "name": "Mwaro Province", "state_code": "MW" },
    { "name": "Ngozi Province", "state_code": "NG" },
    { "name": "Rumonge Province", "state_code": "RM" },
    { "name": "Rutana Province", "state_code": "RT" },
    { "name": "Ruyigi Province", "state_code": "RY" }],
    "KH": [{ "name": "Banteay Meanchey", "state_code": "1" },
    { "name": "Battambang", "state_code": "2" },
    { "name": "Kampong Cham", "state_code": "3" },
    { "name": "Kampong Chhnang", "state_code": "4" },
    { "name": "Kampong Speu", "state_code": "5" },
    { "name": "Kampong Thom", "state_code": "6" },
    { "name": "Kampot", "state_code": "7" },
    { "name": "Kandal", "state_code": "8" },
    { "name": "Kep", "state_code": "23" },
    { "name": "Koh Kong", "state_code": "9" },
    { "name": "Kratie", "state_code": "10" },
    { "name": "Mondulkiri", "state_code": "11" },
    { "name": "Oddar Meanchey", "state_code": "22" },
    { "name": "Pailin", "state_code": "24" },
    { "name": "Phnom Penh", "state_code": "12" },
    { "name": "Preah Vihear", "state_code": "13" },
    { "name": "Prey Veng", "state_code": "14" },
    { "name": "Pursat", "state_code": "15" },
    { "name": "Ratanakiri", "state_code": "16" },
    { "name": "Siem Reap", "state_code": "17" },
    { "name": "Sihanoukville", "state_code": "18" },
    { "name": "Stung Treng", "state_code": "19" },
    { "name": "Svay Rieng", "state_code": "20" },
    { "name": "Takeo", "state_code": "21" }],
    "CM": [{ "name": "Adamawa", "state_code": "AD" },
    { "name": "Centre", "state_code": "CE" },
    { "name": "East", "state_code": "ES" },
    { "name": "Far North", "state_code": "EN" },
    { "name": "Littoral", "state_code": "LT" },
    { "name": "North", "state_code": "NO" },
    { "name": "Northwest", "state_code": "NW" },
    { "name": "South", "state_code": "SU" },
    { "name": "Southwest", "state_code": "SW" },
    { "name": "West", "state_code": "OU" }],
    "CA": [{ "name": "Alberta", "state_code": "AB" },
    { "name": "British Columbia", "state_code": "BC" },
    { "name": "Manitoba", "state_code": "MB" },
    { "name": "New Brunswick", "state_code": "NB" },
    { "name": "Newfoundland and Labrador", "state_code": "NL" },
    { "name": "Northwest Territories", "state_code": "NT" },
    { "name": "Nova Scotia", "state_code": "NS" },
    { "name": "Nunavut", "state_code": "NU" },
    { "name": "Ontario", "state_code": "ON" },
    { "name": "Prince Edward Island", "state_code": "PE" },
    { "name": "Quebec", "state_code": "QC" },
    { "name": "Saskatchewan", "state_code": "SK" },
    { "name": "Yukon", "state_code": "YT" }],
    "CV": [{ "name": "Barlavento Islands", "state_code": "B" },
    { "name": "Boa Vista", "state_code": "BV" },
    { "name": "Brava", "state_code": "BR" },
    { "name": "Maio Municipality", "state_code": "MA" },
    { "name": "Mosteiros", "state_code": "MO" },
    { "name": "Paul", "state_code": "PA" },
    { "name": "Porto Novo", "state_code": "PN" },
    { "name": "Praia", "state_code": "PR" },
    { "name": "Ribeira Brava Municipality", "state_code": "RB" },
    { "name": "Ribeira Grande", "state_code": "RG" },
    { "name": "Ribeira Grande de Santiago", "state_code": "RS" },
    { "name": "Sal", "state_code": "SL" },
    { "name": "Santa Catarina", "state_code": "CA" },
    { "name": "Santa Catarina do Fogo", "state_code": "CF" },
    { "name": "Santa Cruz", "state_code": "CR" },
    { "name": "São Domingos", "state_code": "SD" },
    { "name": "São Filipe", "state_code": "SF" },
    { "name": "São Lourenço dos Órgãos", "state_code": "SO" },
    { "name": "São Miguel", "state_code": "SM" },
    { "name": "São Vicente", "state_code": "SV" },
    { "name": "Sotavento Islands", "state_code": "S" },
    { "name": "Tarrafal", "state_code": "TA" },
    { "name": "Tarrafal de São Nicolau", "state_code": "TS" }],
    "KY": [],
    "CF": [{ "name": "Bamingui-Bangoran Prefecture", "state_code": "BB" },
    { "name": "Bangui", "state_code": "BGF" },
    { "name": "Basse-Kotto Prefecture", "state_code": "BK" },
    { "name": "Haut-Mbomou Prefecture", "state_code": "HM" },
    { "name": "Haute-Kotto Prefecture", "state_code": "HK" },
    { "name": "Kémo Prefecture", "state_code": "KG" },
    { "name": "Lobaye Prefecture", "state_code": "LB" },
    { "name": "Mambéré-Kadéï", "state_code": "HS" },
    { "name": "Mbomou Prefecture", "state_code": "MB" },
    { "name": "Nana-Grébizi Economic Prefecture", "state_code": "KB" },
    { "name": "Nana-Mambéré Prefecture", "state_code": "NM" },
    { "name": "Ombella-M'Poko Prefecture", "state_code": "MP" },
    { "name": "Ouaka Prefecture", "state_code": "UK" },
    { "name": "Ouham Prefecture", "state_code": "AC" },
    { "name": "Ouham-Pendé Prefecture", "state_code": "OP" },
    { "name": "Sangha-Mbaéré", "state_code": "SE" },
    { "name": "Vakaga Prefecture", "state_code": "VK" }],
    "TD": [{ "name": "Bahr el Gazel", "state_code": "BG" },
    { "name": "Batha Region", "state_code": "BA" },
    { "name": "Borkou", "state_code": "BO" },
    { "name": "Ennedi Region", "state_code": "EN" },
    { "name": "Ennedi-Est", "state_code": "EE" },
    { "name": "Ennedi-Ouest", "state_code": "EO" },
    { "name": "Guéra Region", "state_code": "GR" },
    { "name": "Hadjer-Lamis", "state_code": "HL" },
    { "name": "Kanem Region", "state_code": "KA" },
    { "name": "Lac Region", "state_code": "LC" },
    { "name": "Logone Occidental Region", "state_code": "LO" },
    { "name": "Logone Oriental Region", "state_code": "LR" },
    { "name": "Mandoul Region", "state_code": "MA" },
    { "name": "Mayo-Kebbi Est Region", "state_code": "ME" },
    { "name": "Mayo-Kebbi Ouest Region", "state_code": "MO" },
    { "name": "Moyen-Chari Region", "state_code": "MC" },
    { "name": "N'Djamena", "state_code": "ND" },
    { "name": "Ouaddaï Region", "state_code": "OD" },
    { "name": "Salamat Region", "state_code": "SA" },
    { "name": "Sila Region", "state_code": "SI" },
    { "name": "Tandjilé Region", "state_code": "TA" },
    { "name": "Tibesti Region", "state_code": "TI" },
    { "name": "Wadi Fira Region", "state_code": "WF" }],
    "CL": [{ "name": "Aisén del General Carlos Ibañez del Campo", "state_code": "AI" },
    { "name": "Antofagasta", "state_code": "AN" },
    { "name": "Arica y Parinacota", "state_code": "AP" },
    { "name": "Atacama", "state_code": "AT" },
    { "name": "Biobío", "state_code": "BI" },
    { "name": "Coquimbo", "state_code": "CO" },
    { "name": "La Araucanía", "state_code": "AR" },
    { "name": "Libertador General Bernardo O'Higgins", "state_code": "LI" },
    { "name": "Los Lagos", "state_code": "LL" },
    { "name": "Los Ríos", "state_code": "LR" },
    { "name": "Magallanes y de la Antártica Chilena", "state_code": "MA" },
    { "name": "Maule", "state_code": "ML" },
    { "name": "Ñuble", "state_code": "NB" },
    { "name": "Región Metropolitana de Santiago", "state_code": "RM" },
    { "name": "Tarapacá", "state_code": "TA" },
    { "name": "Valparaíso", "state_code": "VS" }],
    "CN": [{ "name": "Anhui", "state_code": "AH" },
    { "name": "Beijing", "state_code": "BJ" },
    { "name": "Chongqing", "state_code": "CQ" },
    { "name": "Fujian", "state_code": "FJ" },
    { "name": "Gansu", "state_code": "GS" },
    { "name": "Guangdong", "state_code": "GD" },
    { "name": "Guangxi Zhuang", "state_code": "GX" },
    { "name": "Guizhou", "state_code": "GZ" },
    { "name": "Hainan", "state_code": "HI" },
    { "name": "Hebei", "state_code": "HE" },
    { "name": "Heilongjiang", "state_code": "HL" },
    { "name": "Henan", "state_code": "HA" },
    { "name": "Hong Kong SAR", "state_code": "HK" },
    { "name": "Hubei", "state_code": "HB" },
    { "name": "Hunan", "state_code": "HN" },
    { "name": "Inner Mongolia", "state_code": "NM" },
    { "name": "Jiangsu", "state_code": "JS" },
    { "name": "Jiangxi", "state_code": "JX" },
    { "name": "Jilin", "state_code": "JL" },
    { "name": "Liaoning", "state_code": "LN" },
    { "name": "Macau SAR", "state_code": "MO" },
    { "name": "Ningxia Huizu", "state_code": "NX" },
    { "name": "Qinghai", "state_code": "QH" },
    { "name": "Shaanxi", "state_code": "SN" },
    { "name": "Shandong", "state_code": "SD" },
    { "name": "Shanghai", "state_code": "SH" },
    { "name": "Shanxi", "state_code": "SX" },
    { "name": "Sichuan", "state_code": "SC" },
    { "name": "Taiwan", "state_code": "TW" },
    { "name": "Tianjin", "state_code": "TJ" },
    { "name": "Xinjiang", "state_code": "XJ" },
    { "name": "Xizang", "state_code": "XZ" },
    { "name": "Yunnan", "state_code": "YN" },
    { "name": "Zhejiang", "state_code": "ZJ" }],
    "CX": [],
    "CC": [],
    "CO": [{ "name": "Amazonas", "state_code": "AMA" },
    { "name": "Antioquia", "state_code": "ANT" },
    { "name": "Arauca", "state_code": "ARA" },
    { "name": "Archipiélago de San Andrés, Providencia y Santa Catalina", "state_code": "SAP" },
    { "name": "Atlántico", "state_code": "ATL" },
    { "name": "Bogotá D.C.", "state_code": "DC" },
    { "name": "Bolívar", "state_code": "BOL" },
    { "name": "Boyacá", "state_code": "BOY" },
    { "name": "Caldas", "state_code": "CAL" },
    { "name": "Caquetá", "state_code": "CAQ" },
    { "name": "Casanare", "state_code": "CAS" },
    { "name": "Cauca", "state_code": "CAU" },
    { "name": "Cesar", "state_code": "CES" },
    { "name": "Chocó", "state_code": "CHO" },
    { "name": "Córdoba", "state_code": "COR" },
    { "name": "Cundinamarca", "state_code": "CUN" },
    { "name": "Guainía", "state_code": "GUA" },
    { "name": "Guaviare", "state_code": "GUV" },
    { "name": "Huila", "state_code": "HUI" },
    { "name": "La Guajira", "state_code": "LAG" },
    { "name": "Magdalena", "state_code": "MAG" },
    { "name": "Meta", "state_code": "MET" },
    { "name": "Nariño", "state_code": "NAR" },
    { "name": "Norte de Santander", "state_code": "NSA" },
    { "name": "Putumayo", "state_code": "PUT" },
    { "name": "Quindío", "state_code": "QUI" },
    { "name": "Risaralda", "state_code": "RIS" },
    { "name": "Santander", "state_code": "SAN" },
    { "name": "Sucre", "state_code": "SUC" },
    { "name": "Tolima", "state_code": "TOL" },
    { "name": "Valle del Cauca", "state_code": "VAC" },
    { "name": "Vaupés", "state_code": "VAU" },
    { "name": "Vichada", "state_code": "VID" }],
    "KM": [{ "name": "Anjouan", "state_code": "A" },
    { "name": "Grande Comore", "state_code": "G" },
    { "name": "Mohéli", "state_code": "M" }],
    "CG": [{ "name": "Bouenza Department", "state_code": "11" },
    { "name": "Brazzaville", "state_code": "BZV" },
    { "name": "Cuvette Department", "state_code": "8" },
    { "name": "Cuvette-Ouest Department", "state_code": "15" },
    { "name": "Kouilou Department", "state_code": "5" },
    { "name": "Lékoumou Department", "state_code": "2" },
    { "name": "Likouala Department", "state_code": "7" },
    { "name": "Niari Department", "state_code": "9" },
    { "name": "Plateaux Department", "state_code": "14" },
    { "name": "Pointe-Noire", "state_code": "16" },
    { "name": "Pool Department", "state_code": "12" },
    { "name": "Sangha Department", "state_code": "13" }],
    "CK": [],
    "CR": [{ "name": "Alajuela Province", "state_code": "A" },
    { "name": "Guanacaste Province", "state_code": "G" },
    { "name": "Heredia Province", "state_code": "H" },
    { "name": "Limón Province", "state_code": "L" },
    { "name": "Provincia de Cartago", "state_code": "C" },
    { "name": "Puntarenas Province", "state_code": "P" },
    { "name": "San José Province", "state_code": "SJ" }],
    "CI": [{ "name": "Abidjan", "state_code": "AB" },
    { "name": "Agnéby", "state_code": "16" },
    { "name": "Bafing Region", "state_code": "17" },
    { "name": "Bas-Sassandra District", "state_code": "BS" },
    { "name": "Bas-Sassandra Region", "state_code": "09" },
    { "name": "Comoé District", "state_code": "CM" },
    { "name": "Denguélé District", "state_code": "DN" },
    { "name": "Denguélé Region", "state_code": "10" },
    { "name": "Dix-Huit Montagnes", "state_code": "06" },
    { "name": "Fromager", "state_code": "18" },
    { "name": "Gôh-Djiboua District", "state_code": "GD" },
    { "name": "Haut-Sassandra", "state_code": "02" },
    { "name": "Lacs District", "state_code": "LC" },
    { "name": "Lacs Region", "state_code": "07" },
    { "name": "Lagunes District", "state_code": "LG" },
    { "name": "Lagunes region", "state_code": "01" },
    { "name": "Marahoué Region", "state_code": "12" },
    { "name": "Montagnes District", "state_code": "MG" },
    { "name": "Moyen-Cavally", "state_code": "19" },
    { "name": "Moyen-Comoé", "state_code": "05" },
    { "name": "N'zi-Comoé", "state_code": "11" },
    { "name": "Sassandra-Marahoué District", "state_code": "SM" },
    { "name": "Savanes Region", "state_code": "03" },
    { "name": "Sud-Bandama", "state_code": "15" },
    { "name": "Sud-Comoé", "state_code": "13" },
    { "name": "Vallée du Bandama District", "state_code": "VB" },
    { "name": "Vallée du Bandama Region", "state_code": "04" },
    { "name": "Woroba District", "state_code": "WR" },
    { "name": "Worodougou", "state_code": "14" },
    { "name": "Yamoussoukro", "state_code": "YM" },
    { "name": "Zanzan Region", "state_code": "ZZ" }],
    "HR": [{ "name": "Bjelovar-Bilogora County", "state_code": "07" },
    { "name": "Brod-Posavina County", "state_code": "12" },
    { "name": "Dubrovnik-Neretva County", "state_code": "19" },
    { "name": "Istria County", "state_code": "18" },
    { "name": "Karlovac County", "state_code": "05" },
    { "name": "Koprivnica-Križevci County", "state_code": "06" },
    { "name": "Krapina-Zagorje County", "state_code": "02" },
    { "name": "Lika-Senj County", "state_code": "09" },
    { "name": "Međimurje County", "state_code": "20" },
    { "name": "Osijek-Baranja County", "state_code": "14" },
    { "name": "Požega-Slavonia County", "state_code": "11" },
    { "name": "Primorje-Gorski Kotar County", "state_code": "08" },
    { "name": "Šibenik-Knin County", "state_code": "15" },
    { "name": "Sisak-Moslavina County", "state_code": "03" },
    { "name": "Split-Dalmatia County", "state_code": "17" },
    { "name": "Varaždin County", "state_code": "05" },
    { "name": "Virovitica-Podravina County", "state_code": "10" },
    { "name": "Vukovar-Syrmia County", "state_code": "16" },
    { "name": "Zadar County", "state_code": "13" },
    { "name": "Zagreb", "state_code": "21" },
    { "name": "Zagreb County", "state_code": "01" }],
    "CU": [{ "name": "Artemisa Province", "state_code": "15" },
    { "name": "Camagüey Province", "state_code": "09" },
    { "name": "Ciego de Ávila Province", "state_code": "08" },
    { "name": "Cienfuegos Province", "state_code": "06" },
    { "name": "Granma Province", "state_code": "12" },
    { "name": "Guantánamo Province", "state_code": "14" },
    { "name": "Havana Province", "state_code": "03" },
    { "name": "Holguín Province", "state_code": "11" },
    { "name": "Isla de la Juventud", "state_code": "99" },
    { "name": "Las Tunas Province", "state_code": "10" },
    { "name": "Matanzas Province", "state_code": "04" },
    { "name": "Mayabeque Province", "state_code": "16" },
    { "name": "Pinar del Río Province", "state_code": "01" },
    { "name": "Sancti Spíritus Province", "state_code": "07" },
    { "name": "Santiago de Cuba Province", "state_code": "13" },
    { "name": "Villa Clara Province", "state_code": "05" }],
    "CW": [],
    "CY": [{ "name": "Famagusta District (Mağusa)", "state_code": "04" },
    { "name": "Kyrenia District (Keryneia)", "state_code": "06" },
    { "name": "Larnaca District (Larnaka)", "state_code": "03" },
    { "name": "Limassol District (Leymasun)", "state_code": "02" },
    { "name": "Nicosia District (Lefkoşa)", "state_code": "01" },
    { "name": "Paphos District (Pafos)", "state_code": "05" }],
    "CZ": [{ "name": "Benešov", "state_code": "201" },
    { "name": "Beroun", "state_code": "202" },
    { "name": "Blansko", "state_code": "641" },
    { "name": "Břeclav", "state_code": "644" },
    { "name": "Brno-město", "state_code": "642" },
    { "name": "Brno-venkov", "state_code": "643" },
    { "name": "Bruntál", "state_code": "801" },
    { "name": "Česká Lípa", "state_code": "511" },
    { "name": "České Budějovice", "state_code": "311" },
    { "name": "Český Krumlov", "state_code": "312" },
    { "name": "Cheb", "state_code": "411" },
    { "name": "Chomutov", "state_code": "422" },
    { "name": "Chrudim", "state_code": "531" },
    { "name": "Děčín", "state_code": "421" },
    { "name": "Domažlice", "state_code": "321" },
    { "name": "Frýdek-Místek", "state_code": "802" },
    { "name": "Havlíčkův Brod", "state_code": "631" },
    { "name": "Hodonín", "state_code": "645" },
    { "name": "Hradec Králové", "state_code": "521" },
    { "name": "Jablonec nad Nisou", "state_code": "512" },
    { "name": "Jeseník", "state_code": "711" },
    { "name": "Jičín", "state_code": "522" },
    { "name": "Jihlava", "state_code": "632" },
    { "name": "Jihočeský kraj", "state_code": "31" },
    { "name": "Jihomoravský kraj", "state_code": "64" },
    { "name": "Jindřichův Hradec", "state_code": "313" },
    { "name": "Karlovarský kraj", "state_code": "41" },
    { "name": "Karlovy Vary", "state_code": "412" },
    { "name": "Karviná", "state_code": "803" },
    { "name": "Kladno", "state_code": "203" },
    { "name": "Klatovy", "state_code": "322" },
    { "name": "Kolín", "state_code": "204" },
    { "name": "Kraj Vysočina", "state_code": "63" },
    { "name": "Královéhradecký kraj", "state_code": "52" },
    { "name": "Kroměříž", "state_code": "721" },
    { "name": "Kutná Hora", "state_code": "205" },
    { "name": "Liberec", "state_code": "513" },
    { "name": "Liberecký kraj", "state_code": "51" },
    { "name": "Litoměřice", "state_code": "423" },
    { "name": "Louny", "state_code": "424" },
    { "name": "Mělník", "state_code": "206" },
    { "name": "Mladá Boleslav", "state_code": "207" },
    { "name": "Moravskoslezský kraj", "state_code": "80" },
    { "name": "Most", "state_code": "425" },
    { "name": "Náchod", "state_code": "523" },
    { "name": "Nový Jičín", "state_code": "804" },
    { "name": "Nymburk", "state_code": "208" },
    { "name": "Olomouc", "state_code": "712" },
    { "name": "Olomoucký kraj", "state_code": "71" },
    { "name": "Opava", "state_code": "805" },
    { "name": "Ostrava-město", "state_code": "806" },
    { "name": "Pardubice", "state_code": "532" },
    { "name": "Pardubický kraj", "state_code": "53" },
    { "name": "Pelhřimov", "state_code": "633" },
    { "name": "Písek", "state_code": "314" },
    { "name": "Plzeň-jih", "state_code": "324" },
    { "name": "Plzeň-město", "state_code": "323" },
    { "name": "Plzeň-sever", "state_code": "325" },
    { "name": "Plzeňský kraj", "state_code": "32" },
    { "name": "Prachatice", "state_code": "315" },
    { "name": "Praha-východ", "state_code": "209" },
    { "name": "Praha-západ", "state_code": "20A" },
    { "name": "Praha, Hlavní město", "state_code": "10" },
    { "name": "Přerov", "state_code": "714" },
    { "name": "Příbram", "state_code": "20B" },
    { "name": "Prostějov", "state_code": "713" },
    { "name": "Rakovník", "state_code": "20C" },
    { "name": "Rokycany", "state_code": "326" },
    { "name": "Rychnov nad Kněžnou", "state_code": "524" },
    { "name": "Semily", "state_code": "514" },
    { "name": "Sokolov", "state_code": "413" },
    { "name": "Strakonice", "state_code": "316" },
    { "name": "Středočeský kraj", "state_code": "20" },
    { "name": "Šumperk", "state_code": "715" },
    { "name": "Svitavy", "state_code": "533" },
    { "name": "Tábor", "state_code": "317" },
    { "name": "Tachov", "state_code": "327" },
    { "name": "Teplice", "state_code": "426" },
    { "name": "Třebíč", "state_code": "634" },
    { "name": "Trutnov", "state_code": "525" },
    { "name": "Uherské Hradiště", "state_code": "722" },
    { "name": "Ústecký kraj", "state_code": "42" },
    { "name": "Ústí nad Labem", "state_code": "427" },
    { "name": "Ústí nad Orlicí", "state_code": "534" },
    { "name": "Vsetín", "state_code": "723" },
    { "name": "Vyškov", "state_code": "646" },
    { "name": "Žďár nad Sázavou", "state_code": "635" },
    { "name": "Zlín", "state_code": "724" },
    { "name": "Zlínský kraj", "state_code": "72" },
    { "name": "Znojmo", "state_code": "647" }],
    "CD": [{ "name": "Bas-Uélé", "state_code": "BU" },
    { "name": "Équateur", "state_code": "EQ" },
    { "name": "Haut-Katanga", "state_code": "HK" },
    { "name": "Haut-Lomami", "state_code": "HL" },
    { "name": "Haut-Uélé", "state_code": "HU" },
    { "name": "Ituri", "state_code": "IT" },
    { "name": "Kasaï", "state_code": "KS" },
    { "name": "Kasaï Central", "state_code": "KC" },
    { "name": "Kasaï Oriental", "state_code": "KE" },
    { "name": "Kinshasa", "state_code": "KN" },
    { "name": "Kongo Central", "state_code": "BC" },
    { "name": "Kwango", "state_code": "KG" },
    { "name": "Kwilu", "state_code": "KL" },
    { "name": "Lomami", "state_code": "LO" },
    { "name": "Lualaba", "state_code": "LU" },
    { "name": "Mai-Ndombe", "state_code": "MN" },
    { "name": "Maniema", "state_code": "MA" },
    { "name": "Mongala", "state_code": "MO" },
    { "name": "Nord-Kivu", "state_code": "NK" },
    { "name": "Nord-Ubangi", "state_code": "NU" },
    { "name": "Sankuru", "state_code": "SA" },
    { "name": "Sud-Kivu", "state_code": "SK" },
    { "name": "Sud-Ubangi", "state_code": "SU" },
    { "name": "Tanganyika", "state_code": "TA" },
    { "name": "Tshopo", "state_code": "TO" },
    { "name": "Tshuapa", "state_code": "TU" }],
    "DK": [{ "name": "Capital Region of Denmark", "state_code": "84" },
    { "name": "Central Denmark Region", "state_code": "82" },
    { "name": "North Denmark Region", "state_code": "81" },
    { "name": "Region of Southern Denmark", "state_code": "83" },
    { "name": "Region Zealand", "state_code": "85" }],
    "DJ": [{ "name": "Ali Sabieh Region", "state_code": "AS" },
    { "name": "Arta Region", "state_code": "AR" },
    { "name": "Dikhil Region", "state_code": "DI" },
    { "name": "Djibouti", "state_code": "DJ" },
    { "name": "Obock Region", "state_code": "OB" },
    { "name": "Tadjourah Region", "state_code": "TA" }],
    "DM": [{ "name": "Saint Andrew Parish", "state_code": "02" },
    { "name": "Saint David Parish", "state_code": "03" },
    { "name": "Saint George Parish", "state_code": "04" },
    { "name": "Saint John Parish", "state_code": "05" },
    { "name": "Saint Joseph Parish", "state_code": "06" },
    { "name": "Saint Luke Parish", "state_code": "07" },
    { "name": "Saint Mark Parish", "state_code": "08" },
    { "name": "Saint Patrick Parish", "state_code": "09" },
    { "name": "Saint Paul Parish", "state_code": "10" },
    { "name": "Saint Peter Parish", "state_code": "11" }],
    "DO": [{ "name": "Azua Province", "state_code": "02" },
    { "name": "Baoruco Province", "state_code": "03" },
    { "name": "Barahona Province", "state_code": "04" },
    { "name": "Dajabón Province", "state_code": "05" },
    { "name": "Distrito Nacional", "state_code": "01" },
    { "name": "Duarte Province", "state_code": "06" },
    { "name": "El Seibo Province", "state_code": "08" },
    { "name": "Espaillat Province", "state_code": "09" },
    { "name": "Hato Mayor Province", "state_code": "30" },
    { "name": "Hermanas Mirabal Province", "state_code": "19" },
    { "name": "Independencia", "state_code": "10" },
    { "name": "La Altagracia Province", "state_code": "11" },
    { "name": "La Romana Province", "state_code": "12" },
    { "name": "La Vega Province", "state_code": "13" },
    { "name": "María Trinidad Sánchez Province", "state_code": "14" },
    { "name": "Monseñor Nouel Province", "state_code": "28" },
    { "name": "Monte Cristi Province", "state_code": "15" },
    { "name": "Monte Plata Province", "state_code": "29" },
    { "name": "Pedernales Province", "state_code": "16" },
    { "name": "Peravia Province", "state_code": "17" },
    { "name": "Puerto Plata Province", "state_code": "18" },
    { "name": "Samaná Province", "state_code": "20" },
    { "name": "San Cristóbal Province", "state_code": "21" },
    { "name": "San José de Ocoa Province", "state_code": "31" },
    { "name": "San Juan Province", "state_code": "22" },
    { "name": "San Pedro de Macorís", "state_code": "23" },
    { "name": "Sánchez Ramírez Province", "state_code": "24" },
    { "name": "Santiago Province", "state_code": "25" },
    { "name": "Santiago Rodríguez Province", "state_code": "26" },
    { "name": "Santo Domingo Province", "state_code": "32" },
    { "name": "Valverde Province", "state_code": "27" }],
    "TL": [{ "name": "Aileu municipality", "state_code": "AL" },
    { "name": "Ainaro Municipality", "state_code": "AN" },
    { "name": "Baucau Municipality", "state_code": "BA" },
    { "name": "Bobonaro Municipality", "state_code": "BO" },
    { "name": "Cova Lima Municipality", "state_code": "CO" },
    { "name": "Dili municipality", "state_code": "DI" },
    { "name": "Ermera District", "state_code": "ER" },
    { "name": "Lautém Municipality", "state_code": "LA" },
    { "name": "Liquiçá Municipality", "state_code": "LI" },
    { "name": "Manatuto District", "state_code": "MT" },
    { "name": "Manufahi Municipality", "state_code": "MF" },
    { "name": "Viqueque Municipality", "state_code": "VI" }],
    "EC": [{ "name": "Azuay", "state_code": "A" },
    { "name": "Bolívar", "state_code": "B" },
    { "name": "Cañar", "state_code": "F" },
    { "name": "Carchi", "state_code": "C" },
    { "name": "Chimborazo", "state_code": "H" },
    { "name": "Cotopaxi", "state_code": "X" },
    { "name": "El Oro", "state_code": "O" },
    { "name": "Esmeraldas", "state_code": "E" },
    { "name": "Galápagos", "state_code": "W" },
    { "name": "Guayas", "state_code": "G" },
    { "name": "Imbabura", "state_code": "I" },
    { "name": "Loja", "state_code": "L" },
    { "name": "Los Ríos", "state_code": "R" },
    { "name": "Manabí", "state_code": "M" },
    { "name": "Morona-Santiago", "state_code": "S" },
    { "name": "Napo", "state_code": "N" },
    { "name": "Orellana", "state_code": "D" },
    { "name": "Pastaza", "state_code": "Y" },
    { "name": "Pichincha", "state_code": "P" },
    { "name": "Santa Elena", "state_code": "SE" },
    { "name": "Santo Domingo de los Tsáchilas", "state_code": "SD" },
    { "name": "Sucumbíos", "state_code": "U" },
    { "name": "Tungurahua", "state_code": "T" },
    { "name": "Zamora Chinchipe", "state_code": "Z" }],
    "EG": [{ "name": "Alexandria", "state_code": "ALX" },
    { "name": "Aswan", "state_code": "ASN" },
    { "name": "Asyut", "state_code": "AST" },
    { "name": "Beheira", "state_code": "BH" },
    { "name": "Beni Suef", "state_code": "BNS" },
    { "name": "Cairo", "state_code": "C" },
    { "name": "Dakahlia", "state_code": "DK" },
    { "name": "Damietta", "state_code": "DT" },
    { "name": "Faiyum", "state_code": "FYM" },
    { "name": "Gharbia", "state_code": "GH" },
    { "name": "Giza", "state_code": "GZ" },
    { "name": "Ismailia", "state_code": "IS" },
    { "name": "Kafr el-Sheikh", "state_code": "KFS" },
    { "name": "Luxor", "state_code": "LX" },
    { "name": "Matrouh", "state_code": "MT" },
    { "name": "Minya", "state_code": "MN" },
    { "name": "Monufia", "state_code": "MNF" },
    { "name": "New Valley", "state_code": "WAD" },
    { "name": "North Sinai", "state_code": "SIN" },
    { "name": "Port Said", "state_code": "PTS" },
    { "name": "Qalyubia", "state_code": "KB" },
    { "name": "Qena", "state_code": "KN" },
    { "name": "Red Sea", "state_code": "BA" },
    { "name": "Sharqia", "state_code": "SHR" },
    { "name": "Sohag", "state_code": "SHG" },
    { "name": "South Sinai", "state_code": "JS" },
    { "name": "Suez", "state_code": "SUZ" }],
    "SV": [{ "name": "Ahuachapán Department", "state_code": "AH" },
    { "name": "Cabañas Department", "state_code": "CA" },
    { "name": "Chalatenango Department", "state_code": "CH" },
    { "name": "Cuscatlán Department", "state_code": "CU" },
    { "name": "La Libertad Department", "state_code": "LI" },
    { "name": "La Paz Department", "state_code": "PA" },
    { "name": "La Unión Department", "state_code": "UN" },
    { "name": "Morazán Department", "state_code": "MO" },
    { "name": "San Miguel Department", "state_code": "SM" },
    { "name": "San Salvador Department", "state_code": "SS" },
    { "name": "San Vicente Department", "state_code": "SV" },
    { "name": "Santa Ana Department", "state_code": "SA" },
    { "name": "Sonsonate Department", "state_code": "SO" },
    { "name": "Usulután Department", "state_code": "US" }],
    "GQ": [{ "name": "Annobón Province", "state_code": "AN" },
    { "name": "Bioko Norte Province", "state_code": "BN" },
    { "name": "Bioko Sur Province", "state_code": "BS" },
    { "name": "Centro Sur Province", "state_code": "CS" },
    { "name": "Insular Region", "state_code": "I" },
    { "name": "Kié-Ntem Province", "state_code": "KN" },
    { "name": "Litoral Province", "state_code": "LI" },
    { "name": "Río Muni", "state_code": "C" },
    { "name": "Wele-Nzas Province", "state_code": "WN" }],
    "ER": [{ "name": "Anseba Region", "state_code": "AN" },
    { "name": "Debub Region", "state_code": "DU" },
    { "name": "Gash-Barka Region", "state_code": "GB" },
    { "name": "Maekel Region", "state_code": "MA" },
    { "name": "Northern Red Sea Region", "state_code": "SK" },
    { "name": "Southern Red Sea Region", "state_code": "DK" }],
    "EE": [{ "name": "Harju County", "state_code": "37" },
    { "name": "Hiiu County", "state_code": "39" },
    { "name": "Ida-Viru County", "state_code": "44" },
    { "name": "Järva County", "state_code": "51" },
    { "name": "Jõgeva County", "state_code": "49" },
    { "name": "Lääne County", "state_code": "57" },
    { "name": "Lääne-Viru County", "state_code": "59" },
    { "name": "Pärnu County", "state_code": "67" },
    { "name": "Põlva County", "state_code": "65" },
    { "name": "Rapla County", "state_code": "70" },
    { "name": "Saare County", "state_code": "74" },
    { "name": "Tartu County", "state_code": "78" },
    { "name": "Valga County", "state_code": "82" },
    { "name": "Viljandi County", "state_code": "84" },
    { "name": "Võru County", "state_code": "86" }],
    "ET": [{ "name": "Addis Ababa", "state_code": "AA" },
    { "name": "Afar Region", "state_code": "AF" },
    { "name": "Amhara Region", "state_code": "AM" },
    { "name": "Benishangul-Gumuz Region", "state_code": "BE" },
    { "name": "Dire Dawa", "state_code": "DD" },
    { "name": "Gambela Region", "state_code": "GA" },
    { "name": "Harari Region", "state_code": "HA" },
    { "name": "Oromia Region", "state_code": "OR" },
    { "name": "Somali Region", "state_code": "SO" },
    { "name": "Southern Nations, Nationalities, and Peoples' Region", "state_code": "SN" },
    { "name": "Tigray Region", "state_code": "TI" }],
    "FK": [],
    "FO": [],
    "FJ": [{ "name": "Ba", "state_code": "01" },
    { "name": "Bua", "state_code": "02" },
    { "name": "Cakaudrove", "state_code": "03" },
    { "name": "Central Division", "state_code": "C" },
    { "name": "Eastern Division", "state_code": "E" },
    { "name": "Kadavu", "state_code": "04" },
    { "name": "Lau", "state_code": "05" },
    { "name": "Lomaiviti", "state_code": "06" },
    { "name": "Macuata", "state_code": "07" },
    { "name": "Nadroga-Navosa", "state_code": "08" },
    { "name": "Naitasiri", "state_code": "09" },
    { "name": "Namosi", "state_code": "10" },
    { "name": "Northern Division", "state_code": "N" },
    { "name": "Ra", "state_code": "11" },
    { "name": "Rewa", "state_code": "12" },
    { "name": "Rotuma", "state_code": "R" },
    { "name": "Serua", "state_code": "13" },
    { "name": "Tailevu", "state_code": "14" },
    { "name": "Western Division", "state_code": "W" }],
    "FI": [{ "name": "Åland Islands", "state_code": "01" },
    { "name": "Central Finland", "state_code": "08" },
    { "name": "Central Ostrobothnia", "state_code": "07" },
    { "name": "Eastern Finland Province", "state_code": "IS" },
    { "name": "Finland Proper", "state_code": "19" },
    { "name": "Kainuu", "state_code": "05" },
    { "name": "Kymenlaakso", "state_code": "09" },
    { "name": "Lapland", "state_code": "LL" },
    { "name": "North Karelia", "state_code": "13" },
    { "name": "Northern Ostrobothnia", "state_code": "14" },
    { "name": "Northern Savonia", "state_code": "15" },
    { "name": "Ostrobothnia", "state_code": "12" },
    { "name": "Oulu Province", "state_code": "OL" },
    { "name": "Päijänne Tavastia", "state_code": "16" },
    { "name": "Pirkanmaa", "state_code": "11" },
    { "name": "Satakunta", "state_code": "17" },
    { "name": "South Karelia", "state_code": "02" },
    { "name": "Southern Ostrobothnia", "state_code": "03" },
    { "name": "Southern Savonia", "state_code": "04" },
    { "name": "Tavastia Proper", "state_code": "06" },
    { "name": "Uusimaa", "state_code": "18" }],
    "FR": [{ "name": "Ain", "state_code": "01" },
    { "name": "Aisne", "state_code": "02" },
    { "name": "Allier", "state_code": "03" },
    { "name": "Alpes-de-Haute-Provence", "state_code": "04" },
    { "name": "Alpes-Maritimes", "state_code": "06" },
    { "name": "Alsace", "state_code": "6AE" },
    { "name": "Ardèche", "state_code": "07" },
    { "name": "Ardennes", "state_code": "08" },
    { "name": "Ariège", "state_code": "09" },
    { "name": "Aube", "state_code": "10" },
    { "name": "Aude", "state_code": "11" },
    { "name": "Auvergne-Rhône-Alpes", "state_code": "ARA" },
    { "name": "Aveyron", "state_code": "12" },
    { "name": "Bas-Rhin", "state_code": "67" },
    { "name": "Bouches-du-Rhône", "state_code": "13" },
    { "name": "Bourgogne-Franche-Comté", "state_code": "BFC" },
    { "name": "Bretagne", "state_code": "BRE" },
    { "name": "Calvados", "state_code": "14" },
    { "name": "Cantal", "state_code": "15" },
    { "name": "Centre-Val de Loire", "state_code": "CVL" },
    { "name": "Charente", "state_code": "16" },
    { "name": "Charente-Maritime", "state_code": "17" },
    { "name": "Cher", "state_code": "18" },
    { "name": "Clipperton", "state_code": "CP" },
    { "name": "Corrèze", "state_code": "19" },
    { "name": "Corse", "state_code": "20R" },
    { "name": "Corse-du-Sud", "state_code": "2A" },
    { "name": "Côte-d'Or", "state_code": "21" },
    { "name": "Côtes-d'Armor", "state_code": "22" },
    { "name": "Creuse", "state_code": "23" },
    { "name": "Deux-Sèvres", "state_code": "79" },
    { "name": "Dordogne", "state_code": "24" },
    { "name": "Doubs", "state_code": "25" },
    { "name": "Drôme", "state_code": "26" },
    { "name": "Essonne", "state_code": "91" },
    { "name": "Eure", "state_code": "27" },
    { "name": "Eure-et-Loir", "state_code": "28" },
    { "name": "Finistère", "state_code": "29" },
    { "name": "French Guiana", "state_code": "973" },
    { "name": "French Polynesia", "state_code": "PF" },
    { "name": "French Southern and Antarctic Lands", "state_code": "TF" },
    { "name": "Gard", "state_code": "30" },
    { "name": "Gers", "state_code": "32" },
    { "name": "Gironde", "state_code": "33" },
    { "name": "Grand-Est", "state_code": "GES" },
    { "name": "Guadeloupe", "state_code": "971" },
    { "name": "Haut-Rhin", "state_code": "68" },
    { "name": "Haute-Corse", "state_code": "2B" },
    { "name": "Haute-Garonne", "state_code": "31" },
    { "name": "Haute-Loire", "state_code": "43" },
    { "name": "Haute-Marne", "state_code": "52" },
    { "name": "Haute-Saône", "state_code": "70" },
    { "name": "Haute-Savoie", "state_code": "74" },
    { "name": "Haute-Vienne", "state_code": "87" },
    { "name": "Hautes-Alpes", "state_code": "05" },
    { "name": "Hautes-Pyrénées", "state_code": "65" },
    { "name": "Hauts-de-France", "state_code": "HDF" },
    { "name": "Hauts-de-Seine", "state_code": "92" },
    { "name": "Hérault", "state_code": "34" },
    { "name": "Île-de-France", "state_code": "IDF" },
    { "name": "Ille-et-Vilaine", "state_code": "35" },
    { "name": "Indre", "state_code": "36" },
    { "name": "Indre-et-Loire", "state_code": "37" },
    { "name": "Isère", "state_code": "38" },
    { "name": "Jura", "state_code": "39" },
    { "name": "La Réunion", "state_code": "974" },
    { "name": "Landes", "state_code": "40" },
    { "name": "Loir-et-Cher", "state_code": "41" },
    { "name": "Loire", "state_code": "42" },
    { "name": "Loire-Atlantique", "state_code": "44" },
    { "name": "Loiret", "state_code": "45" },
    { "name": "Lot", "state_code": "46" },
    { "name": "Lot-et-Garonne", "state_code": "47" },
    { "name": "Lozère", "state_code": "48" },
    { "name": "Maine-et-Loire", "state_code": "49" },
    { "name": "Manche", "state_code": "50" },
    { "name": "Marne", "state_code": "51" },
    { "name": "Martinique", "state_code": "972" },
    { "name": "Mayenne", "state_code": "53" },
    { "name": "Mayotte", "state_code": "976" },
    { "name": "Métropole de Lyon", "state_code": "69M" },
    { "name": "Meurthe-et-Moselle", "state_code": "54" },
    { "name": "Meuse", "state_code": "55" },
    { "name": "Morbihan", "state_code": "56" },
    { "name": "Moselle", "state_code": "57" },
    { "name": "Nièvre", "state_code": "58" },
    { "name": "Nord", "state_code": "59" },
    { "name": "Normandie", "state_code": "NOR" },
    { "name": "Nouvelle-Aquitaine", "state_code": "NAQ" },
    { "name": "Occitanie", "state_code": "OCC" },
    { "name": "Oise", "state_code": "60" },
    { "name": "Orne", "state_code": "61" },
    { "name": "Paris", "state_code": "75C" },
    { "name": "Pas-de-Calais", "state_code": "62" },
    { "name": "Pays-de-la-Loire", "state_code": "PDL" },
    { "name": "Provence-Alpes-Côte-d’Azur", "state_code": "PAC" },
    { "name": "Puy-de-Dôme", "state_code": "63" },
    { "name": "Pyrénées-Atlantiques", "state_code": "64" },
    { "name": "Pyrénées-Orientales", "state_code": "66" },
    { "name": "Rhône", "state_code": "69" },
    { "name": "Saint Pierre and Miquelon", "state_code": "PM" },
    { "name": "Saint-Barthélemy", "state_code": "BL" },
    { "name": "Saint-Martin", "state_code": "MF" },
    { "name": "Saône-et-Loire", "state_code": "71" },
    { "name": "Sarthe", "state_code": "72" },
    { "name": "Savoie", "state_code": "73" },
    { "name": "Seine-et-Marne", "state_code": "77" },
    { "name": "Seine-Maritime", "state_code": "76" },
    { "name": "Seine-Saint-Denis", "state_code": "93" },
    { "name": "Somme", "state_code": "80" },
    { "name": "Tarn", "state_code": "81" },
    { "name": "Tarn-et-Garonne", "state_code": "82" },
    { "name": "Territoire de Belfort", "state_code": "90" },
    { "name": "Val-d'Oise", "state_code": "95" },
    { "name": "Val-de-Marne", "state_code": "94" },
    { "name": "Var", "state_code": "83" },
    { "name": "Vaucluse", "state_code": "84" },
    { "name": "Vendée", "state_code": "85" },
    { "name": "Vienne", "state_code": "86" },
    { "name": "Vosges", "state_code": "88" },
    { "name": "Wallis and Futuna", "state_code": "WF" },
    { "name": "Yonne", "state_code": "89" },
    { "name": "Yvelines", "state_code": "78" }],
    "GF": [],
    "PF": [],
    "TF": [],
    "GA": [{ "name": "Estuaire Province", "state_code": "1" },
    { "name": "Haut-Ogooué Province", "state_code": "2" },
    { "name": "Moyen-Ogooué Province", "state_code": "3" },
    { "name": "Ngounié Province", "state_code": "4" },
    { "name": "Nyanga Province", "state_code": "5" },
    { "name": "Ogooué-Ivindo Province", "state_code": "6" },
    { "name": "Ogooué-Lolo Province", "state_code": "7" },
    { "name": "Ogooué-Maritime Province", "state_code": "8" },
    { "name": "Woleu-Ntem Province", "state_code": "9" }],
    "GM": [{ "name": "Banjul", "state_code": "B" },
    { "name": "Central River Division", "state_code": "M" },
    { "name": "Lower River Division", "state_code": "L" },
    { "name": "North Bank Division", "state_code": "N" },
    { "name": "Upper River Division", "state_code": "U" },
    { "name": "West Coast Division", "state_code": "W" }],
    "GE": [{ "name": "Adjara", "state_code": "AJ" },
    { "name": "Autonomous Republic of Abkhazia", "state_code": "AB" },
    { "name": "Guria", "state_code": "GU" },
    { "name": "Imereti", "state_code": "IM" },
    { "name": "Kakheti", "state_code": "KA" },
    { "name": "Khelvachauri Municipality", "state_code": "29" },
    { "name": "Kvemo Kartli", "state_code": "KK" },
    { "name": "Mtskheta-Mtianeti", "state_code": "MM" },
    { "name": "Racha-Lechkhumi and Kvemo Svaneti", "state_code": "RL" },
    { "name": "Samegrelo-Zemo Svaneti", "state_code": "SZ" },
    { "name": "Samtskhe-Javakheti", "state_code": "SJ" },
    { "name": "Senaki Municipality", "state_code": "50" },
    { "name": "Shida Kartli", "state_code": "SK" },
    { "name": "Tbilisi", "state_code": "TB" }],
    "DE": [{ "name": "Baden-Württemberg", "state_code": "BW" },
    { "name": "Bavaria", "state_code": "BY" },
    { "name": "Berlin", "state_code": "BE" },
    { "name": "Brandenburg", "state_code": "BB" },
    { "name": "Bremen", "state_code": "HB" },
    { "name": "Hamburg", "state_code": "HH" },
    { "name": "Hesse", "state_code": "HE" },
    { "name": "Lower Saxony", "state_code": "NI" },
    { "name": "Mecklenburg-Vorpommern", "state_code": "MV" },
    { "name": "North Rhine-Westphalia", "state_code": "NW" },
    { "name": "Rhineland-Palatinate", "state_code": "RP" },
    { "name": "Saarland", "state_code": "SL" },
    { "name": "Saxony", "state_code": "SN" },
    { "name": "Saxony-Anhalt", "state_code": "ST" },
    { "name": "Schleswig-Holstein", "state_code": "SH" },
    { "name": "Thuringia", "state_code": "TH" }],
    "GH": [{ "name": "Ahafo", "state_code": "AF" },
    { "name": "Ashanti", "state_code": "AH" },
    { "name": "Bono", "state_code": "BO" },
    { "name": "Bono East", "state_code": "BE" },
    { "name": "Central", "state_code": "CP" },
    { "name": "Eastern", "state_code": "EP" },
    { "name": "Greater Accra", "state_code": "AA" },
    { "name": "North East", "state_code": "NE" },
    { "name": "Northern", "state_code": "NP" },
    { "name": "Oti", "state_code": "OT" },
    { "name": "Savannah", "state_code": "SV" },
    { "name": "Upper East", "state_code": "UE" },
    { "name": "Upper West", "state_code": "UW" },
    { "name": "Volta", "state_code": "TV" },
    { "name": "Western", "state_code": "WP" },
    { "name": "Western North", "state_code": "WN" }],
    "GI": [],
    "GR": [{ "name": "Achaea Regional Unit", "state_code": "13" },
    { "name": "Aetolia-Acarnania Regional Unit", "state_code": "01" },
    { "name": "Arcadia Prefecture", "state_code": "12" },
    { "name": "Argolis Regional Unit", "state_code": "11" },
    { "name": "Attica Region", "state_code": "I" },
    { "name": "Boeotia Regional Unit", "state_code": "03" },
    { "name": "Central Greece Region", "state_code": "H" },
    { "name": "Central Macedonia", "state_code": "B" },
    { "name": "Chania Regional Unit", "state_code": "94" },
    { "name": "Corfu Prefecture", "state_code": "22" },
    { "name": "Corinthia Regional Unit", "state_code": "15" },
    { "name": "Crete Region", "state_code": "M" },
    { "name": "Drama Regional Unit", "state_code": "52" },
    { "name": "East Attica Regional Unit", "state_code": "A2" },
    { "name": "East Macedonia and Thrace", "state_code": "A" },
    { "name": "Epirus Region", "state_code": "D" },
    { "name": "Euboea", "state_code": "04" },
    { "name": "Grevena Prefecture", "state_code": "51" },
    { "name": "Imathia Regional Unit", "state_code": "53" },
    { "name": "Ioannina Regional Unit", "state_code": "33" },
    { "name": "Ionian Islands Region", "state_code": "F" },
    { "name": "Karditsa Regional Unit", "state_code": "41" },
    { "name": "Kastoria Regional Unit", "state_code": "56" },
    { "name": "Kefalonia Prefecture", "state_code": "23" },
    { "name": "Kilkis Regional Unit", "state_code": "57" },
    { "name": "Kozani Prefecture", "state_code": "58" },
    { "name": "Laconia", "state_code": "16" },
    { "name": "Larissa Prefecture", "state_code": "42" },
    { "name": "Lefkada Regional Unit", "state_code": "24" },
    { "name": "Pella Regional Unit", "state_code": "59" },
    { "name": "Peloponnese Region", "state_code": "J" },
    { "name": "Phthiotis Prefecture", "state_code": "06" },
    { "name": "Preveza Prefecture", "state_code": "34" },
    { "name": "Serres Prefecture", "state_code": "62" },
    { "name": "South Aegean", "state_code": "L" },
    { "name": "Thessaloniki Regional Unit", "state_code": "54" },
    { "name": "West Greece Region", "state_code": "G" },
    { "name": "West Macedonia Region", "state_code": "C" }],
    "GL": [],
    "GD": [{ "name": "Carriacou and Petite Martinique", "state_code": "10" },
    { "name": "Saint Andrew Parish", "state_code": "01" },
    { "name": "Saint David Parish", "state_code": "02" },
    { "name": "Saint George Parish", "state_code": "03" },
    { "name": "Saint John Parish", "state_code": "04" },
    { "name": "Saint Mark Parish", "state_code": "05" },
    { "name": "Saint Patrick Parish", "state_code": "06" }],
    "GP": [],
    "GU": [],
    "GT": [{ "name": "Alta Verapaz Department", "state_code": "AV" },
    { "name": "Baja Verapaz Department", "state_code": "BV" },
    { "name": "Chimaltenango Department", "state_code": "CM" },
    { "name": "Chiquimula Department", "state_code": "CQ" },
    { "name": "El Progreso Department", "state_code": "PR" },
    { "name": "Escuintla Department", "state_code": "ES" },
    { "name": "Guatemala Department", "state_code": "GU" },
    { "name": "Huehuetenango Department", "state_code": "HU" },
    { "name": "Izabal Department", "state_code": "IZ" },
    { "name": "Jalapa Department", "state_code": "JA" },
    { "name": "Jutiapa Department", "state_code": "JU" },
    { "name": "Petén Department", "state_code": "PE" },
    { "name": "Quetzaltenango Department", "state_code": "QZ" },
    { "name": "Quiché Department", "state_code": "QC" },
    { "name": "Retalhuleu Department", "state_code": "RE" },
    { "name": "Sacatepéquez Department", "state_code": "SA" },
    { "name": "San Marcos Department", "state_code": "SM" },
    { "name": "Santa Rosa Department", "state_code": "SR" },
    { "name": "Sololá Department", "state_code": "SO" },
    { "name": "Suchitepéquez Department", "state_code": "SU" },
    { "name": "Totonicapán Department", "state_code": "TO" }],
    "GG": [],
    "GN": [{ "name": "Beyla Prefecture", "state_code": "BE" },
    { "name": "Boffa Prefecture", "state_code": "BF" },
    { "name": "Boké Prefecture", "state_code": "BK" },
    { "name": "Boké Region", "state_code": "B" },
    { "name": "Conakry", "state_code": "C" },
    { "name": "Coyah Prefecture", "state_code": "CO" },
    { "name": "Dabola Prefecture", "state_code": "DB" },
    { "name": "Dalaba Prefecture", "state_code": "DL" },
    { "name": "Dinguiraye Prefecture", "state_code": "DI" },
    { "name": "Dubréka Prefecture", "state_code": "DU" },
    { "name": "Faranah Prefecture", "state_code": "FA" },
    { "name": "Forécariah Prefecture", "state_code": "FO" },
    { "name": "Fria Prefecture", "state_code": "FR" },
    { "name": "Gaoual Prefecture", "state_code": "GA" },
    { "name": "Guéckédou Prefecture", "state_code": "GU" },
    { "name": "Kankan Prefecture", "state_code": "KA" },
    { "name": "Kankan Region", "state_code": "K" },
    { "name": "Kérouané Prefecture", "state_code": "KE" },
    { "name": "Kindia Prefecture", "state_code": "KD" },
    { "name": "Kindia Region", "state_code": "D" },
    { "name": "Kissidougou Prefecture", "state_code": "KS" },
    { "name": "Koubia Prefecture", "state_code": "KB" },
    { "name": "Koundara Prefecture", "state_code": "KN" },
    { "name": "Kouroussa Prefecture", "state_code": "KO" },
    { "name": "Labé Prefecture", "state_code": "LA" },
    { "name": "Labé Region", "state_code": "L" },
    { "name": "Lélouma Prefecture", "state_code": "LE" },
    { "name": "Lola Prefecture", "state_code": "LO" },
    { "name": "Macenta Prefecture", "state_code": "MC" },
    { "name": "Mali Prefecture", "state_code": "ML" },
    { "name": "Mamou Prefecture", "state_code": "MM" },
    { "name": "Mamou Region", "state_code": "M" },
    { "name": "Mandiana Prefecture", "state_code": "MD" },
    { "name": "Nzérékoré Prefecture", "state_code": "NZ" },
    { "name": "Nzérékoré Region", "state_code": "N" },
    { "name": "Pita Prefecture", "state_code": "PI" },
    { "name": "Siguiri Prefecture", "state_code": "SI" },
    { "name": "Télimélé Prefecture", "state_code": "TE" },
    { "name": "Tougué Prefecture", "state_code": "TO" },
    { "name": "Yomou Prefecture", "state_code": "YO" }],
    "GW": [{ "name": "Bafatá", "state_code": "BA" },
    { "name": "Biombo Region", "state_code": "BM" },
    { "name": "Bolama Region", "state_code": "BL" },
    { "name": "Cacheu Region", "state_code": "CA" },
    { "name": "Gabú Region", "state_code": "GA" },
    { "name": "Leste Province", "state_code": "L" },
    { "name": "Norte Province", "state_code": "N" },
    { "name": "Oio Region", "state_code": "OI" },
    { "name": "Quinara Region", "state_code": "QU" },
    { "name": "Sul Province", "state_code": "S" },
    { "name": "Tombali Region", "state_code": "TO" }],
    "GY": [{ "name": "Barima-Waini", "state_code": "BA" },
    { "name": "Cuyuni-Mazaruni", "state_code": "CU" },
    { "name": "Demerara-Mahaica", "state_code": "DE" },
    { "name": "East Berbice-Corentyne", "state_code": "EB" },
    { "name": "Essequibo Islands-West Demerara", "state_code": "ES" },
    { "name": "Mahaica-Berbice", "state_code": "MA" },
    { "name": "Pomeroon-Supenaam", "state_code": "PM" },
    { "name": "Potaro-Siparuni", "state_code": "PT" },
    { "name": "Upper Demerara-Berbice", "state_code": "UD" },
    { "name": "Upper Takutu-Upper Essequibo", "state_code": "UT" }],
    "HT": [{ "name": "Artibonite", "state_code": "AR" },
    { "name": "Centre", "state_code": "CE" },
    { "name": "Grand'Anse", "state_code": "GA" },
    { "name": "Nippes", "state_code": "NI" },
    { "name": "Nord", "state_code": "ND" },
    { "name": "Nord-Est", "state_code": "NE" },
    { "name": "Nord-Ouest", "state_code": "NO" },
    { "name": "Ouest", "state_code": "OU" },
    { "name": "Sud", "state_code": "SD" },
    { "name": "Sud-Est", "state_code": "SE" }],
    "HM": [],
    "HN": [{ "name": "Atlántida Department", "state_code": "AT" },
    { "name": "Bay Islands Department", "state_code": "IB" },
    { "name": "Choluteca Department", "state_code": "CH" },
    { "name": "Colón Department", "state_code": "CL" },
    { "name": "Comayagua Department", "state_code": "CM" },
    { "name": "Copán Department", "state_code": "CP" },
    { "name": "Cortés Department", "state_code": "CR" },
    { "name": "El Paraíso Department", "state_code": "EP" },
    { "name": "Francisco Morazán Department", "state_code": "FM" },
    { "name": "Gracias a Dios Department", "state_code": "GD" },
    { "name": "Intibucá Department", "state_code": "IN" },
    { "name": "La Paz Department", "state_code": "LP" },
    { "name": "Lempira Department", "state_code": "LE" },
    { "name": "Ocotepeque Department", "state_code": "OC" },
    { "name": "Olancho Department", "state_code": "OL" },
    { "name": "Santa Bárbara Department", "state_code": "SB" },
    { "name": "Valle Department", "state_code": "VA" },
    { "name": "Yoro Department", "state_code": "YO" }],
    "HK": [{ "name": "Central and Western District", "state_code": "HCW" },
    { "name": "Eastern", "state_code": "HEA" },
    { "name": "Islands District", "state_code": "NIS" },
    { "name": "Kowloon City", "state_code": "KKC" },
    { "name": "Kwai Tsing", "state_code": "NKT" },
    { "name": "Kwun Tong", "state_code": "KKT" },
    { "name": "North", "state_code": "NNO" },
    { "name": "Sai Kung District", "state_code": "NSK" },
    { "name": "Sha Tin", "state_code": "NST" },
    { "name": "Sham Shui Po", "state_code": "KSS" },
    { "name": "Southern", "state_code": "HSO" },
    { "name": "Tai Po District", "state_code": "NTP" },
    { "name": "Tsuen Wan District", "state_code": "NTW" },
    { "name": "Tuen Mun", "state_code": "NTM" },
    { "name": "Wan Chai", "state_code": "HWC" },
    { "name": "Wong Tai Sin", "state_code": "KWT" },
    { "name": "Yau Tsim Mong", "state_code": "KYT" },
    { "name": "Yuen Long District", "state_code": "NYL" }],
    "HU": [{ "name": "Bács-Kiskun County", "state_code": "BK" },
    { "name": "Baranya County", "state_code": "BA" },
    { "name": "Békés County", "state_code": "BE" },
    { "name": "Békéscsaba", "state_code": "BC" },
    { "name": "Borsod-Abaúj-Zemplén County", "state_code": "BZ" },
    { "name": "Budapest", "state_code": "BU" },
    { "name": "Csongrád County", "state_code": "CS" },
    { "name": "Debrecen", "state_code": "DE" },
    { "name": "Dunaújváros", "state_code": "DU" },
    { "name": "Eger", "state_code": "EG" },
    { "name": "Érd", "state_code": "ER" },
    { "name": "Fejér County", "state_code": "FE" },
    { "name": "Győr", "state_code": "GY" },
    { "name": "Győr-Moson-Sopron County", "state_code": "GS" },
    { "name": "Hajdú-Bihar County", "state_code": "HB" },
    { "name": "Heves County", "state_code": "HE" },
    { "name": "Hódmezővásárhely", "state_code": "HV" },
    { "name": "Jász-Nagykun-Szolnok County", "state_code": "JN" },
    { "name": "Kaposvár", "state_code": "KV" },
    { "name": "Kecskemét", "state_code": "KM" },
    { "name": "Miskolc", "state_code": "MI" },
    { "name": "Nagykanizsa", "state_code": "NK" },
    { "name": "Nógrád County", "state_code": "NO" },
    { "name": "Nyíregyháza", "state_code": "NY" },
    { "name": "Pécs", "state_code": "PS" },
    { "name": "Pest County", "state_code": "PE" },
    { "name": "Salgótarján", "state_code": "ST" },
    { "name": "Somogy County", "state_code": "SO" },
    { "name": "Sopron", "state_code": "SN" },
    { "name": "Szabolcs-Szatmár-Bereg County", "state_code": "SZ" },
    { "name": "Szeged", "state_code": "SD" },
    { "name": "Székesfehérvár", "state_code": "SF" },
    { "name": "Szekszárd", "state_code": "SS" },
    { "name": "Szolnok", "state_code": "SK" },
    { "name": "Szombathely", "state_code": "SH" },
    { "name": "Tatabánya", "state_code": "TB" },
    { "name": "Tolna County", "state_code": "TO" },
    { "name": "Vas County", "state_code": "VA" },
    { "name": "Veszprém", "state_code": "VM" },
    { "name": "Veszprém County", "state_code": "VE" },
    { "name": "Zala County", "state_code": "ZA" },
    { "name": "Zalaegerszeg", "state_code": "ZE" }],
    "IS": [{ "name": "Capital Region", "state_code": "1" },
    { "name": "Eastern Region", "state_code": "7" },
    { "name": "Northeastern Region", "state_code": "6" },
    { "name": "Northwestern Region", "state_code": "5" },
    { "name": "Southern Peninsula Region", "state_code": "2" },
    { "name": "Southern Region", "state_code": "8" },
    { "name": "Western Region", "state_code": "3" },
    { "name": "Westfjords", "state_code": "4" }],
    "IN": [{ "name": "Andaman and Nicobar Islands", "state_code": "AN" },
    { "name": "Andhra Pradesh", "state_code": "AP" },
    { "name": "Arunachal Pradesh", "state_code": "AR" },
    { "name": "Assam", "state_code": "AS" },
    { "name": "Bihar", "state_code": "BR" },
    { "name": "Chandigarh", "state_code": "CH" },
    { "name": "Chhattisgarh", "state_code": "CT" },
    { "name": "Dadra and Nagar Haveli and Daman and Diu", "state_code": "DH" },
    { "name": "Delhi", "state_code": "DL" },
    { "name": "Goa", "state_code": "GA" },
    { "name": "Gujarat", "state_code": "GJ" },
    { "name": "Haryana", "state_code": "HR" },
    { "name": "Himachal Pradesh", "state_code": "HP" },
    { "name": "Jammu and Kashmir", "state_code": "JK" },
    { "name": "Jharkhand", "state_code": "JH" },
    { "name": "Karnataka", "state_code": "KA" },
    { "name": "Kerala", "state_code": "KL" },
    { "name": "Ladakh", "state_code": "LA" },
    { "name": "Lakshadweep", "state_code": "LD" },
    { "name": "Madhya Pradesh", "state_code": "MP" },
    { "name": "Maharashtra", "state_code": "MH" },
    { "name": "Manipur", "state_code": "MN" },
    { "name": "Meghalaya", "state_code": "ML" },
    { "name": "Mizoram", "state_code": "MZ" },
    { "name": "Nagaland", "state_code": "NL" },
    { "name": "Odisha", "state_code": "OR" },
    { "name": "Puducherry", "state_code": "PY" },
    { "name": "Punjab", "state_code": "PB" },
    { "name": "Rajasthan", "state_code": "RJ" },
    { "name": "Sikkim", "state_code": "SK" },
    { "name": "Tamil Nadu", "state_code": "TN" },
    { "name": "Telangana", "state_code": "TG" },
    { "name": "Tripura", "state_code": "TR" },
    { "name": "Uttar Pradesh", "state_code": "UP" },
    { "name": "Uttarakhand", "state_code": "UT" },
    { "name": "West Bengal", "state_code": "WB" }],
    "ID": [{ "name": "Aceh", "state_code": "AC" },
    { "name": "Bali", "state_code": "BA" },
    { "name": "Banten", "state_code": "BT" },
    { "name": "Bengkulu", "state_code": "BE" },
    { "name": "DI Yogyakarta", "state_code": "YO" },
    { "name": "DKI Jakarta", "state_code": "JK" },
    { "name": "Gorontalo", "state_code": "GO" },
    { "name": "Jambi", "state_code": "JA" },
    { "name": "Jawa Barat", "state_code": "JB" },
    { "name": "Jawa Tengah", "state_code": "JT" },
    { "name": "Jawa Timur", "state_code": "JI" },
    { "name": "Kalimantan Barat", "state_code": "KA" },
    { "name": "Kalimantan Selatan", "state_code": "KS" },
    { "name": "Kalimantan Tengah", "state_code": "KT" },
    { "name": "Kalimantan Timur", "state_code": "KI" },
    { "name": "Kalimantan Utara", "state_code": "KU" },
    { "name": "Kepulauan Bangka Belitung", "state_code": "BB" },
    { "name": "Kepulauan Riau", "state_code": "KR" },
    { "name": "Lampung", "state_code": "LA" },
    { "name": "Maluku", "state_code": "MA" },
    { "name": "Maluku Utara", "state_code": "MU" },
    { "name": "Nusa Tenggara Barat", "state_code": "NB" },
    { "name": "Nusa Tenggara Timur", "state_code": "NT" },
    { "name": "Papua", "state_code": "PA" },
    { "name": "Papua Barat", "state_code": "PB" },
    { "name": "Riau", "state_code": "RI" },
    { "name": "Sulawesi Barat", "state_code": "SR" },
    { "name": "Sulawesi Selatan", "state_code": "SN" },
    { "name": "Sulawesi Tengah", "state_code": "ST" },
    { "name": "Sulawesi Tenggara", "state_code": "SG" },
    { "name": "Sulawesi Utara", "state_code": "SA" },
    { "name": "Sumatera Barat", "state_code": "SB" },
    { "name": "Sumatera Selatan", "state_code": "SS" },
    { "name": "Sumatera Utara", "state_code": "SU" }],
    "IR": [{ "name": "Alborz", "state_code": "30" },
    { "name": "Ardabil", "state_code": "24" },
    { "name": "Bushehr", "state_code": "18" },
    { "name": "Chaharmahal and Bakhtiari", "state_code": "14" },
    { "name": "East Azerbaijan", "state_code": "03" },
    { "name": "Fars", "state_code": "07" },
    { "name": "Gilan", "state_code": "01" },
    { "name": "Golestan", "state_code": "27" },
    { "name": "Hamadan", "state_code": "13" },
    { "name": "Hormozgan", "state_code": "22" },
    { "name": "Ilam", "state_code": "16" },
    { "name": "Isfahan", "state_code": "10" },
    { "name": "Kerman", "state_code": "08" },
    { "name": "Kermanshah", "state_code": "05" },
    { "name": "Khuzestan", "state_code": "06" },
    { "name": "Kohgiluyeh and Boyer-Ahmad", "state_code": "17" },
    { "name": "Kurdistan", "state_code": "12" },
    { "name": "Lorestan", "state_code": "15" },
    { "name": "Markazi", "state_code": "00" },
    { "name": "Mazandaran", "state_code": "02" },
    { "name": "North Khorasan", "state_code": "28" },
    { "name": "Qazvin", "state_code": "26" },
    { "name": "Qom", "state_code": "25" },
    { "name": "Razavi Khorasan", "state_code": "09" },
    { "name": "Semnan", "state_code": "20" },
    { "name": "Sistan and Baluchestan", "state_code": "11" },
    { "name": "South Khorasan", "state_code": "29" },
    { "name": "Tehran", "state_code": "23" },
    { "name": "West Azarbaijan", "state_code": "04" },
    { "name": "Yazd", "state_code": "21" },
    { "name": "Zanjan", "state_code": "19" }],
    "IQ": [{ "name": "Al Anbar Governorate", "state_code": "AN" },
    { "name": "Al Muthanna Governorate", "state_code": "MU" },
    { "name": "Al-Qādisiyyah Governorate", "state_code": "QA" },
    { "name": "Babylon Governorate", "state_code": "BB" },
    { "name": "Baghdad Governorate", "state_code": "BG" },
    { "name": "Basra Governorate", "state_code": "BA" },
    { "name": "Dhi Qar Governorate", "state_code": "DQ" },
    { "name": "Diyala Governorate", "state_code": "DI" },
    { "name": "Dohuk Governorate", "state_code": "DA" },
    { "name": "Erbil Governorate", "state_code": "AR" },
    { "name": "Karbala Governorate", "state_code": "KA" },
    { "name": "Kirkuk Governorate", "state_code": "KI" },
    { "name": "Maysan Governorate", "state_code": "MA" },
    { "name": "Najaf Governorate", "state_code": "NA" },
    { "name": "Nineveh Governorate", "state_code": "NI" },
    { "name": "Saladin Governorate", "state_code": "SD" },
    { "name": "Sulaymaniyah Governorate", "state_code": "SU" },
    { "name": "Wasit Governorate", "state_code": "WA" }],
    "IE": [{ "name": "Carlow", "state_code": "CW" },
    { "name": "Cavan", "state_code": "CN" },
    { "name": "Clare", "state_code": "CE" },
    { "name": "Connacht", "state_code": "C" },
    { "name": "Cork", "state_code": "CO" },
    { "name": "Donegal", "state_code": "DL" },
    { "name": "Dublin", "state_code": "D" },
    { "name": "Galway", "state_code": "G" },
    { "name": "Kerry", "state_code": "KY" },
    { "name": "Kildare", "state_code": "KE" },
    { "name": "Kilkenny", "state_code": "KK" },
    { "name": "Laois", "state_code": "LS" },
    { "name": "Leinster", "state_code": "L" },
    { "name": "Limerick", "state_code": "LK" },
    { "name": "Longford", "state_code": "LD" },
    { "name": "Louth", "state_code": "LH" },
    { "name": "Mayo", "state_code": "MO" },
    { "name": "Meath", "state_code": "MH" },
    { "name": "Monaghan", "state_code": "MN" },
    { "name": "Munster", "state_code": "M" },
    { "name": "Offaly", "state_code": "OY" },
    { "name": "Roscommon", "state_code": "RN" },
    { "name": "Sligo", "state_code": "SO" },
    { "name": "Tipperary", "state_code": "TA" },
    { "name": "Ulster", "state_code": "U" },
    { "name": "Waterford", "state_code": "WD" },
    { "name": "Westmeath", "state_code": "WH" },
    { "name": "Wexford", "state_code": "WX" },
    { "name": "Wicklow", "state_code": "WW" }],
    "IL": [{ "name": "Central District", "state_code": "M" },
    { "name": "Haifa District", "state_code": "HA" },
    { "name": "Jerusalem District", "state_code": "JM" },
    { "name": "Northern District", "state_code": "Z" },
    { "name": "Southern District", "state_code": "D" },
    { "name": "Tel Aviv District", "state_code": "TA" }],
    "IT": [{ "name": "Abruzzo", "state_code": "65" },
    { "name": "Aosta Valley", "state_code": "23" },
    { "name": "Apulia", "state_code": "75" },
    { "name": "Basilicata", "state_code": "77" },
    { "name": "Benevento Province", "state_code": "BN" },
    { "name": "Calabria", "state_code": "78" },
    { "name": "Campania", "state_code": "72" },
    { "name": "Emilia-Romagna", "state_code": "45" },
    { "name": "Friuli–Venezia Giulia", "state_code": "36" },
    { "name": "Lazio", "state_code": "62" },
    { "name": "Libero consorzio comunale di Agrigento", "state_code": "AG" },
    { "name": "Libero consorzio comunale di Caltanissetta", "state_code": "CL" },
    { "name": "Libero consorzio comunale di Enna", "state_code": "EN" },
    { "name": "Libero consorzio comunale di Ragusa", "state_code": "RG" },
    { "name": "Libero consorzio comunale di Siracusa", "state_code": "SR" },
    { "name": "Libero consorzio comunale di Trapani", "state_code": "TP" },
    { "name": "Liguria", "state_code": "42" },
    { "name": "Lombardy", "state_code": "25" },
    { "name": "Marche", "state_code": "57" },
    { "name": "Metropolitan City of Bari", "state_code": "BA" },
    { "name": "Metropolitan City of Bologna", "state_code": "BO" },
    { "name": "Metropolitan City of Cagliari", "state_code": "CA" },
    { "name": "Metropolitan City of Catania", "state_code": "CT" },
    { "name": "Metropolitan City of Florence", "state_code": "FI" },
    { "name": "Metropolitan City of Genoa", "state_code": "GE" },
    { "name": "Metropolitan City of Messina", "state_code": "ME" },
    { "name": "Metropolitan City of Milan", "state_code": "MI" },
    { "name": "Metropolitan City of Naples", "state_code": "NA" },
    { "name": "Metropolitan City of Palermo", "state_code": "PA" },
    { "name": "Metropolitan City of Reggio Calabria", "state_code": "RC" },
    { "name": "Metropolitan City of Rome", "state_code": "RM" },
    { "name": "Metropolitan City of Turin", "state_code": "TO" },
    { "name": "Metropolitan City of Venice", "state_code": "VE" },
    { "name": "Molise", "state_code": "67" },
    { "name": "Pesaro and Urbino Province", "state_code": "PU" },
    { "name": "Piedmont", "state_code": "21" },
    { "name": "Province of Alessandria", "state_code": "AL" },
    { "name": "Province of Ancona", "state_code": "AN" },
    { "name": "Province of Ascoli Piceno", "state_code": "AP" },
    { "name": "Province of Asti", "state_code": "AT" },
    { "name": "Province of Avellino", "state_code": "AV" },
    { "name": "Province of Barletta-Andria-Trani", "state_code": "BT" },
    { "name": "Province of Belluno", "state_code": "BL" },
    { "name": "Province of Bergamo", "state_code": "BG" },
    { "name": "Province of Biella", "state_code": "BI" },
    { "name": "Province of Brescia", "state_code": "BS" },
    { "name": "Province of Brindisi", "state_code": "BR" },
    { "name": "Province of Campobasso", "state_code": "CB" },
    { "name": "Province of Carbonia-Iglesias", "state_code": "CI" },
    { "name": "Province of Caserta", "state_code": "CE" },
    { "name": "Province of Catanzaro", "state_code": "CZ" },
    { "name": "Province of Chieti", "state_code": "CH" },
    { "name": "Province of Como", "state_code": "CO" },
    { "name": "Province of Cosenza", "state_code": "CS" },
    { "name": "Province of Cremona", "state_code": "CR" },
    { "name": "Province of Crotone", "state_code": "KR" },
    { "name": "Province of Cuneo", "state_code": "CN" },
    { "name": "Province of Fermo", "state_code": "FM" },
    { "name": "Province of Ferrara", "state_code": "FE" },
    { "name": "Province of Foggia", "state_code": "FG" },
    { "name": "Province of Forlì-Cesena", "state_code": "FC" },
    { "name": "Province of Frosinone", "state_code": "FR" },
    { "name": "Province of Gorizia", "state_code": "GO" },
    { "name": "Province of Grosseto", "state_code": "GR" },
    { "name": "Province of Imperia", "state_code": "IM" },
    { "name": "Province of Isernia", "state_code": "IS" },
    { "name": "Province of L'Aquila", "state_code": "AQ" },
    { "name": "Province of La Spezia", "state_code": "SP" },
    { "name": "Province of Latina", "state_code": "LT" },
    { "name": "Province of Lecce", "state_code": "LE" },
    { "name": "Province of Lecco", "state_code": "LC" },
    { "name": "Province of Livorno", "state_code": "LI" },
    { "name": "Province of Lodi", "state_code": "LO" },
    { "name": "Province of Lucca", "state_code": "LU" },
    { "name": "Province of Macerata", "state_code": "MC" },
    { "name": "Province of Mantua", "state_code": "MN" },
    { "name": "Province of Massa and Carrara", "state_code": "MS" },
    { "name": "Province of Matera", "state_code": "MT" },
    { "name": "Province of Medio Campidano", "state_code": "VS" },
    { "name": "Province of Modena", "state_code": "MO" },
    { "name": "Province of Monza and Brianza", "state_code": "MB" },
    { "name": "Province of Novara", "state_code": "NO" },
    { "name": "Province of Nuoro", "state_code": "NU" },
    { "name": "Province of Ogliastra", "state_code": "OG" },
    { "name": "Province of Olbia-Tempio", "state_code": "OT" },
    { "name": "Province of Oristano", "state_code": "OR" },
    { "name": "Province of Padua", "state_code": "PD" },
    { "name": "Province of Parma", "state_code": "PR" },
    { "name": "Province of Pavia", "state_code": "PV" },
    { "name": "Province of Perugia", "state_code": "PG" },
    { "name": "Province of Pescara", "state_code": "PE" },
    { "name": "Province of Piacenza", "state_code": "PC" },
    { "name": "Province of Pisa", "state_code": "PI" },
    { "name": "Province of Pistoia", "state_code": "PT" },
    { "name": "Province of Pordenone", "state_code": "PN" },
    { "name": "Province of Potenza", "state_code": "PZ" },
    { "name": "Province of Prato", "state_code": "PO" },
    { "name": "Province of Ravenna", "state_code": "RA" },
    { "name": "Province of Reggio Emilia", "state_code": "RE" },
    { "name": "Province of Rieti", "state_code": "RI" },
    { "name": "Province of Rimini", "state_code": "RN" },
    { "name": "Province of Rovigo", "state_code": "RO" },
    { "name": "Province of Salerno", "state_code": "SA" },
    { "name": "Province of Sassari", "state_code": "SS" },
    { "name": "Province of Savona", "state_code": "SV" },
    { "name": "Province of Siena", "state_code": "SI" },
    { "name": "Province of Sondrio", "state_code": "SO" },
    { "name": "Province of Taranto", "state_code": "TA" },
    { "name": "Province of Teramo", "state_code": "TE" },
    { "name": "Province of Terni", "state_code": "TR" },
    { "name": "Province of Treviso", "state_code": "TV" },
    { "name": "Province of Trieste", "state_code": "TS" },
    { "name": "Province of Udine", "state_code": "UD" },
    { "name": "Province of Varese", "state_code": "VA" },
    { "name": "Province of Verbano-Cusio-Ossola", "state_code": "VB" },
    { "name": "Province of Vercelli", "state_code": "VC" },
    { "name": "Province of Verona", "state_code": "VR" },
    { "name": "Province of Vibo Valentia", "state_code": "VV" },
    { "name": "Province of Vicenza", "state_code": "VI" },
    { "name": "Province of Viterbo", "state_code": "VT" },
    { "name": "Sardinia", "state_code": "88" },
    { "name": "Sicily", "state_code": "82" },
    { "name": "South Tyrol", "state_code": "BZ" },
    { "name": "Trentino", "state_code": "TN" },
    { "name": "Trentino-South Tyrol", "state_code": "32" },
    { "name": "Tuscany", "state_code": "52" },
    { "name": "Umbria", "state_code": "55" },
    { "name": "Veneto", "state_code": "34" }],
    "JM": [{ "name": "Clarendon Parish", "state_code": "13" },
    { "name": "Hanover Parish", "state_code": "09" },
    { "name": "Kingston Parish", "state_code": "01" },
    { "name": "Manchester Parish", "state_code": "12" },
    { "name": "Portland Parish", "state_code": "04" },
    { "name": "Saint Andrew", "state_code": "02" },
    { "name": "Saint Ann Parish", "state_code": "06" },
    { "name": "Saint Catherine Parish", "state_code": "14" },
    { "name": "Saint Elizabeth Parish", "state_code": "11" },
    { "name": "Saint James Parish", "state_code": "08" },
    { "name": "Saint Mary Parish", "state_code": "05" },
    { "name": "Saint Thomas Parish", "state_code": "03" },
    { "name": "Trelawny Parish", "state_code": "07" },
    { "name": "Westmoreland Parish", "state_code": "10" }],
    "JP": [{ "name": "Aichi Prefecture", "state_code": "23" },
    { "name": "Akita Prefecture", "state_code": "05" },
    { "name": "Aomori Prefecture", "state_code": "02" },
    { "name": "Chiba Prefecture", "state_code": "12" },
    { "name": "Ehime Prefecture", "state_code": "38" },
    { "name": "Fukui Prefecture", "state_code": "18" },
    { "name": "Fukuoka Prefecture", "state_code": "40" },
    { "name": "Fukushima Prefecture", "state_code": "07" },
    { "name": "Gifu Prefecture", "state_code": "21" },
    { "name": "Gunma Prefecture", "state_code": "10" },
    { "name": "Hiroshima Prefecture", "state_code": "34" },
    { "name": "Hokkaidō Prefecture", "state_code": "01" },
    { "name": "Hyōgo Prefecture", "state_code": "28" },
    { "name": "Ibaraki Prefecture", "state_code": "08" },
    { "name": "Ishikawa Prefecture", "state_code": "17" },
    { "name": "Iwate Prefecture", "state_code": "03" },
    { "name": "Kagawa Prefecture", "state_code": "37" },
    { "name": "Kagoshima Prefecture", "state_code": "46" },
    { "name": "Kanagawa Prefecture", "state_code": "14" },
    { "name": "Kōchi Prefecture", "state_code": "39" },
    { "name": "Kumamoto Prefecture", "state_code": "43" },
    { "name": "Kyōto Prefecture", "state_code": "26" },
    { "name": "Mie Prefecture", "state_code": "24" },
    { "name": "Miyagi Prefecture", "state_code": "04" },
    { "name": "Miyazaki Prefecture", "state_code": "45" },
    { "name": "Nagano Prefecture", "state_code": "20" },
    { "name": "Nagasaki Prefecture", "state_code": "42" },
    { "name": "Nara Prefecture", "state_code": "29" },
    { "name": "Niigata Prefecture", "state_code": "15" },
    { "name": "Ōita Prefecture", "state_code": "44" },
    { "name": "Okayama Prefecture", "state_code": "33" },
    { "name": "Okinawa Prefecture", "state_code": "47" },
    { "name": "Ōsaka Prefecture", "state_code": "27" },
    { "name": "Saga Prefecture", "state_code": "41" },
    { "name": "Saitama Prefecture", "state_code": "11" },
    { "name": "Shiga Prefecture", "state_code": "25" },
    { "name": "Shimane Prefecture", "state_code": "32" },
    { "name": "Shizuoka Prefecture", "state_code": "22" },
    { "name": "Tochigi Prefecture", "state_code": "09" },
    { "name": "Tokushima Prefecture", "state_code": "36" },
    { "name": "Tokyo", "state_code": "13" },
    { "name": "Tottori Prefecture", "state_code": "31" },
    { "name": "Toyama Prefecture", "state_code": "16" },
    { "name": "Wakayama Prefecture", "state_code": "30" },
    { "name": "Yamagata Prefecture", "state_code": "06" },
    { "name": "Yamaguchi Prefecture", "state_code": "35" },
    { "name": "Yamanashi Prefecture", "state_code": "19" }],
    "JE": [],
    "JO": [{ "name": "Ajloun Governorate", "state_code": "AJ" },
    { "name": "Amman Governorate", "state_code": "AM" },
    { "name": "Aqaba Governorate", "state_code": "AQ" },
    { "name": "Balqa Governorate", "state_code": "BA" },
    { "name": "Irbid Governorate", "state_code": "IR" },
    { "name": "Jerash Governorate", "state_code": "JA" },
    { "name": "Karak Governorate", "state_code": "KA" },
    { "name": "Ma'an Governorate", "state_code": "MN" },
    { "name": "Madaba Governorate", "state_code": "MD" },
    { "name": "Mafraq Governorate", "state_code": "MA" },
    { "name": "Tafilah Governorate", "state_code": "AT" },
    { "name": "Zarqa Governorate", "state_code": "AZ" }],
    "KZ": [{ "name": "Akmola Region", "state_code": "AKM" },
    { "name": "Aktobe Region", "state_code": "AKT" },
    { "name": "Almaty", "state_code": "ALA" },
    { "name": "Almaty Region", "state_code": "ALM" },
    { "name": "Atyrau Region", "state_code": "ATY" },
    { "name": "Baikonur", "state_code": "BAY" },
    { "name": "East Kazakhstan Region", "state_code": "VOS" },
    { "name": "Jambyl Region", "state_code": "ZHA" },
    { "name": "Karaganda Region", "state_code": "KAR" },
    { "name": "Kostanay Region", "state_code": "KUS" },
    { "name": "Kyzylorda Region", "state_code": "KZY" },
    { "name": "Mangystau Region", "state_code": "MAN" },
    { "name": "North Kazakhstan Region", "state_code": "SEV" },
    { "name": "Nur-Sultan", "state_code": "AST" },
    { "name": "Pavlodar Region", "state_code": "PAV" },
    { "name": "Turkestan Region", "state_code": "YUZ" },
    { "name": "West Kazakhstan Province", "state_code": "ZAP" }],
    "KE": [{ "name": "Baringo", "state_code": "01" },
    { "name": "Bomet", "state_code": "02" },
    { "name": "Bungoma", "state_code": "03" },
    { "name": "Busia", "state_code": "04" },
    { "name": "Elgeyo-Marakwet", "state_code": "05" },
    { "name": "Embu", "state_code": "06" },
    { "name": "Garissa", "state_code": "07" },
    { "name": "Homa Bay", "state_code": "08" },
    { "name": "Isiolo", "state_code": "09" },
    { "name": "Kajiado", "state_code": "10" },
    { "name": "Kakamega", "state_code": "11" },
    { "name": "Kericho", "state_code": "12" },
    { "name": "Kiambu", "state_code": "13" },
    { "name": "Kilifi", "state_code": "14" },
    { "name": "Kirinyaga", "state_code": "15" },
    { "name": "Kisii", "state_code": "16" },
    { "name": "Kisumu", "state_code": "17" },
    { "name": "Kitui", "state_code": "18" },
    { "name": "Kwale", "state_code": "19" },
    { "name": "Laikipia", "state_code": "20" },
    { "name": "Lamu", "state_code": "21" },
    { "name": "Machakos", "state_code": "22" },
    { "name": "Makueni", "state_code": "23" },
    { "name": "Mandera", "state_code": "24" },
    { "name": "Marsabit", "state_code": "25" },
    { "name": "Meru", "state_code": "26" },
    { "name": "Migori", "state_code": "27" },
    { "name": "Mombasa", "state_code": "28" },
    { "name": "Murang'a", "state_code": "29" },
    { "name": "Nairobi City", "state_code": "30" },
    { "name": "Nakuru", "state_code": "31" },
    { "name": "Nandi", "state_code": "32" },
    { "name": "Narok", "state_code": "33" },
    { "name": "Nyamira", "state_code": "34" },
    { "name": "Nyandarua", "state_code": "35" },
    { "name": "Nyeri", "state_code": "36" },
    { "name": "Samburu", "state_code": "37" },
    { "name": "Siaya", "state_code": "38" },
    { "name": "Taita–Taveta", "state_code": "39" },
    { "name": "Tana River", "state_code": "40" },
    { "name": "Tharaka-Nithi", "state_code": "41" },
    { "name": "Trans Nzoia", "state_code": "42" },
    { "name": "Turkana", "state_code": "43" },
    { "name": "Uasin Gishu", "state_code": "44" },
    { "name": "Vihiga", "state_code": "45" },
    { "name": "Wajir", "state_code": "46" },
    { "name": "West Pokot", "state_code": "47" }],
    "KI": [{ "name": "Gilbert Islands", "state_code": "G" },
    { "name": "Line Islands", "state_code": "L" },
    { "name": "Phoenix Islands", "state_code": "P" }],
    "XK": [{ "name": "Đakovica District (Gjakove)", "state_code": "XDG" },
    { "name": "Gjilan District", "state_code": "XGJ" },
    { "name": "Kosovska Mitrovica District", "state_code": "XKM" },
    { "name": "Peć District", "state_code": "XPE" },
    { "name": "Pristina (Priştine)", "state_code": "XPI" },
    { "name": "Prizren District", "state_code": "XPR" },
    { "name": "Uroševac District (Ferizaj)", "state_code": "XUF" }],
    "KW": [{ "name": "Al Ahmadi Governorate", "state_code": "AH" },
    { "name": "Al Farwaniyah Governorate", "state_code": "FA" },
    { "name": "Al Jahra Governorate", "state_code": "JA" },
    { "name": "Capital Governorate", "state_code": "KU" },
    { "name": "Hawalli Governorate", "state_code": "HA" },
    { "name": "Mubarak Al-Kabeer Governorate", "state_code": "MU" }],
    "KG": [{ "name": "Batken Region", "state_code": "B" },
    { "name": "Bishkek", "state_code": "GB" },
    { "name": "Chuy Region", "state_code": "C" },
    { "name": "Issyk-Kul Region", "state_code": "Y" },
    { "name": "Jalal-Abad Region", "state_code": "J" },
    { "name": "Naryn Region", "state_code": "N" },
    { "name": "Osh", "state_code": "GO" },
    { "name": "Osh Region", "state_code": "O" },
    { "name": "Talas Region", "state_code": "T" }],
    "LA": [{ "name": "Attapeu Province", "state_code": "AT" },
    { "name": "Bokeo Province", "state_code": "BK" },
    { "name": "Bolikhamsai Province", "state_code": "BL" },
    { "name": "Champasak Province", "state_code": "CH" },
    { "name": "Houaphanh Province", "state_code": "HO" },
    { "name": "Khammouane Province", "state_code": "KH" },
    { "name": "Luang Namtha Province", "state_code": "LM" },
    { "name": "Luang Prabang Province", "state_code": "LP" },
    { "name": "Oudomxay Province", "state_code": "OU" },
    { "name": "Phongsaly Province", "state_code": "PH" },
    { "name": "Sainyabuli Province", "state_code": "XA" },
    { "name": "Salavan Province", "state_code": "SL" },
    { "name": "Savannakhet Province", "state_code": "SV" },
    { "name": "Sekong Province", "state_code": "XE" },
    { "name": "Vientiane Prefecture", "state_code": "VT" },
    { "name": "Vientiane Province", "state_code": "VI" },
    { "name": "Xaisomboun", "state_code": "XN" },
    { "name": "Xaisomboun Province", "state_code": "XS" },
    { "name": "Xiangkhouang Province", "state_code": "XI" }],
    "LV": [{ "name": "Aglona Municipality", "state_code": "001" },
    { "name": "Aizkraukle Municipality", "state_code": "002" },
    { "name": "Aizpute Municipality", "state_code": "003" },
    { "name": "Aknīste Municipality", "state_code": "004" },
    { "name": "Aloja Municipality", "state_code": "005" },
    { "name": "Alsunga Municipality", "state_code": "006" },
    { "name": "Alūksne Municipality", "state_code": "007" },
    { "name": "Amata Municipality", "state_code": "008" },
    { "name": "Ape Municipality", "state_code": "009" },
    { "name": "Auce Municipality", "state_code": "010" },
    { "name": "Babīte Municipality", "state_code": "012" },
    { "name": "Baldone Municipality", "state_code": "013" },
    { "name": "Baltinava Municipality", "state_code": "014" },
    { "name": "Balvi Municipality", "state_code": "015" },
    { "name": "Bauska Municipality", "state_code": "016" },
    { "name": "Beverīna Municipality", "state_code": "017" },
    { "name": "Brocēni Municipality", "state_code": "018" },
    { "name": "Burtnieki Municipality", "state_code": "019" },
    { "name": "Carnikava Municipality", "state_code": "020" },
    { "name": "Cēsis Municipality", "state_code": "022" },
    { "name": "Cesvaine Municipality", "state_code": "021" },
    { "name": "Cibla Municipality", "state_code": "023" },
    { "name": "Dagda Municipality", "state_code": "024" },
    { "name": "Daugavpils", "state_code": "DGV" },
    { "name": "Daugavpils Municipality", "state_code": "025" },
    { "name": "Dobele Municipality", "state_code": "026" },
    { "name": "Dundaga Municipality", "state_code": "027" },
    { "name": "Durbe Municipality", "state_code": "028" },
    { "name": "Engure Municipality", "state_code": "029" },
    { "name": "Ērgļi Municipality", "state_code": "030" },
    { "name": "Garkalne Municipality", "state_code": "031" },
    { "name": "Grobiņa Municipality", "state_code": "032" },
    { "name": "Gulbene Municipality", "state_code": "033" },
    { "name": "Iecava Municipality", "state_code": "034" },
    { "name": "Ikšķile Municipality", "state_code": "035" },
    { "name": "Ilūkste Municipality", "state_code": "036" },
    { "name": "Inčukalns Municipality", "state_code": "037" },
    { "name": "Jaunjelgava Municipality", "state_code": "038" },
    { "name": "Jaunpiebalga Municipality", "state_code": "039" },
    { "name": "Jaunpils Municipality", "state_code": "040" },
    { "name": "Jēkabpils", "state_code": "JKB" },
    { "name": "Jēkabpils Municipality", "state_code": "042" },
    { "name": "Jelgava", "state_code": "JEL" },
    { "name": "Jelgava Municipality", "state_code": "041" },
    { "name": "Jūrmala", "state_code": "JUR" },
    { "name": "Kandava Municipality", "state_code": "043" },
    { "name": "Kārsava Municipality", "state_code": "044" },
    { "name": "Ķegums Municipality", "state_code": "051" },
    { "name": "Ķekava Municipality", "state_code": "052" },
    { "name": "Kocēni Municipality", "state_code": "045" },
    { "name": "Koknese Municipality", "state_code": "046" },
    { "name": "Krāslava Municipality", "state_code": "047" },
    { "name": "Krimulda Municipality", "state_code": "048" },
    { "name": "Krustpils Municipality", "state_code": "049" },
    { "name": "Kuldīga Municipality", "state_code": "050" },
    { "name": "Lielvārde Municipality", "state_code": "053" },
    { "name": "Liepāja", "state_code": "LPX" },
    { "name": "Līgatne Municipality", "state_code": "055" },
    { "name": "Limbaži Municipality", "state_code": "054" },
    { "name": "Līvāni Municipality", "state_code": "056" },
    { "name": "Lubāna Municipality", "state_code": "057" },
    { "name": "Ludza Municipality", "state_code": "058" },
    { "name": "Madona Municipality", "state_code": "059" },
    { "name": "Mālpils Municipality", "state_code": "061" },
    { "name": "Mārupe Municipality", "state_code": "062" },
    { "name": "Mazsalaca Municipality", "state_code": "060" },
    { "name": "Mērsrags Municipality", "state_code": "063" },
    { "name": "Naukšēni Municipality", "state_code": "064" },
    { "name": "Nereta Municipality", "state_code": "065" },
    { "name": "Nīca Municipality", "state_code": "066" },
    { "name": "Ogre Municipality", "state_code": "067" },
    { "name": "Olaine Municipality", "state_code": "068" },
    { "name": "Ozolnieki Municipality", "state_code": "069" },
    { "name": "Pārgauja Municipality", "state_code": "070" },
    { "name": "Pāvilosta Municipality", "state_code": "071" },
    { "name": "Pļaviņas Municipality", "state_code": "072" },
    { "name": "Preiļi Municipality", "state_code": "073" },
    { "name": "Priekule Municipality", "state_code": "074" },
    { "name": "Priekuļi Municipality", "state_code": "075" },
    { "name": "Rauna Municipality", "state_code": "076" },
    { "name": "Rēzekne", "state_code": "REZ" },
    { "name": "Rēzekne Municipality", "state_code": "077" },
    { "name": "Riebiņi Municipality", "state_code": "078" },
    { "name": "Riga", "state_code": "RIX" },
    { "name": "Roja Municipality", "state_code": "079" },
    { "name": "Ropaži Municipality", "state_code": "080" },
    { "name": "Rucava Municipality", "state_code": "081" },
    { "name": "Rugāji Municipality", "state_code": "082" },
    { "name": "Rūjiena Municipality", "state_code": "084" },
    { "name": "Rundāle Municipality", "state_code": "083" },
    { "name": "Sala Municipality", "state_code": "085" },
    { "name": "Salacgrīva Municipality", "state_code": "086" },
    { "name": "Salaspils Municipality", "state_code": "087" },
    { "name": "Saldus Municipality", "state_code": "088" },
    { "name": "Saulkrasti Municipality", "state_code": "089" },
    { "name": "Sēja Municipality", "state_code": "090" },
    { "name": "Sigulda Municipality", "state_code": "091" },
    { "name": "Skrīveri Municipality", "state_code": "092" },
    { "name": "Skrunda Municipality", "state_code": "093" },
    { "name": "Smiltene Municipality", "state_code": "094" },
    { "name": "Stopiņi Municipality", "state_code": "095" },
    { "name": "Strenči Municipality", "state_code": "096" },
    { "name": "Talsi Municipality", "state_code": "097" },
    { "name": "Tērvete Municipality", "state_code": "098" },
    { "name": "Tukums Municipality", "state_code": "099" },
    { "name": "Vaiņode Municipality", "state_code": "100" },
    { "name": "Valka Municipality", "state_code": "101" },
    { "name": "Valmiera", "state_code": "VMR" },
    { "name": "Varakļāni Municipality", "state_code": "102" },
    { "name": "Vārkava Municipality", "state_code": "103" },
    { "name": "Vecpiebalga Municipality", "state_code": "104" },
    { "name": "Vecumnieki Municipality", "state_code": "105" },
    { "name": "Ventspils", "state_code": "VEN" },
    { "name": "Ventspils Municipality", "state_code": "106" },
    { "name": "Viesīte Municipality", "state_code": "107" },
    { "name": "Viļaka Municipality", "state_code": "108" },
    { "name": "Viļāni Municipality", "state_code": "109" },
    { "name": "Zilupe Municipality", "state_code": "110" }],
    "LB": [{ "name": "Akkar Governorate", "state_code": "AK" },
    { "name": "Baalbek-Hermel Governorate", "state_code": "BH" },
    { "name": "Beirut Governorate", "state_code": "BA" },
    { "name": "Beqaa Governorate", "state_code": "BI" },
    { "name": "Mount Lebanon Governorate", "state_code": "JL" },
    { "name": "Nabatieh Governorate", "state_code": "NA" },
    { "name": "North Governorate", "state_code": "AS" },
    { "name": "South Governorate", "state_code": "JA" }],
    "LS": [{ "name": "Berea District", "state_code": "D" },
    { "name": "Butha-Buthe District", "state_code": "B" },
    { "name": "Leribe District", "state_code": "C" },
    { "name": "Mafeteng District", "state_code": "E" },
    { "name": "Maseru District", "state_code": "A" },
    { "name": "Mohale's Hoek District", "state_code": "F" },
    { "name": "Mokhotlong District", "state_code": "J" },
    { "name": "Qacha's Nek District", "state_code": "H" },
    { "name": "Quthing District", "state_code": "G" },
    { "name": "Thaba-Tseka District", "state_code": "K" }],
    "LR": [{ "name": "Bomi County", "state_code": "BM" },
    { "name": "Bong County", "state_code": "BG" },
    { "name": "Gbarpolu County", "state_code": "GP" },
    { "name": "Grand Bassa County", "state_code": "GB" },
    { "name": "Grand Cape Mount County", "state_code": "CM" },
    { "name": "Grand Gedeh County", "state_code": "GG" },
    { "name": "Grand Kru County", "state_code": "GK" },
    { "name": "Lofa County", "state_code": "LO" },
    { "name": "Margibi County", "state_code": "MG" },
    { "name": "Maryland County", "state_code": "MY" },
    { "name": "Montserrado County", "state_code": "MO" },
    { "name": "Nimba", "state_code": "NI" },
    { "name": "River Cess County", "state_code": "RI" },
    { "name": "River Gee County", "state_code": "RG" },
    { "name": "Sinoe County", "state_code": "SI" }],
    "LY": [{ "name": "Al Wahat District", "state_code": "WA" },
    { "name": "Benghazi", "state_code": "BA" },
    { "name": "Derna District", "state_code": "DR" },
    { "name": "Ghat District", "state_code": "GT" },
    { "name": "Jabal al Akhdar", "state_code": "JA" },
    { "name": "Jabal al Gharbi District", "state_code": "JG" },
    { "name": "Jafara", "state_code": "JI" },
    { "name": "Jufra", "state_code": "JU" },
    { "name": "Kufra District", "state_code": "KF" },
    { "name": "Marj District", "state_code": "MJ" },
    { "name": "Misrata District", "state_code": "MI" },
    { "name": "Murqub", "state_code": "MB" },
    { "name": "Murzuq District", "state_code": "MQ" },
    { "name": "Nalut District", "state_code": "NL" },
    { "name": "Nuqat al Khams", "state_code": "NQ" },
    { "name": "Sabha District", "state_code": "SB" },
    { "name": "Sirte District", "state_code": "SR" },
    { "name": "Tripoli District", "state_code": "TB" },
    { "name": "Wadi al Hayaa District", "state_code": "WD" },
    { "name": "Wadi al Shatii District", "state_code": "WS" },
    { "name": "Zawiya District", "state_code": "ZA" }],
    "LI": [{ "name": "Balzers", "state_code": "01" },
    { "name": "Eschen", "state_code": "02" },
    { "name": "Gamprin", "state_code": "03" },
    { "name": "Mauren", "state_code": "04" },
    { "name": "Planken", "state_code": "05" },
    { "name": "Ruggell", "state_code": "06" },
    { "name": "Schaan", "state_code": "07" },
    { "name": "Schellenberg", "state_code": "08" },
    { "name": "Triesen", "state_code": "09" },
    { "name": "Triesenberg", "state_code": "10" },
    { "name": "Vaduz", "state_code": "11" }],
    "LT": [{ "name": "Akmenė District Municipality", "state_code": "01" },
    { "name": "Alytus City Municipality", "state_code": "02" },
    { "name": "Alytus County", "state_code": "AL" },
    { "name": "Alytus District Municipality", "state_code": "03" },
    { "name": "Birštonas Municipality", "state_code": "05" },
    { "name": "Biržai District Municipality", "state_code": "06" },
    { "name": "Druskininkai municipality", "state_code": "07" },
    { "name": "Elektrėnai municipality", "state_code": "08" },
    { "name": "Ignalina District Municipality", "state_code": "09" },
    { "name": "Jonava District Municipality", "state_code": "10" },
    { "name": "Joniškis District Municipality", "state_code": "11" },
    { "name": "Jurbarkas District Municipality", "state_code": "12" },
    { "name": "Kaišiadorys District Municipality", "state_code": "13" },
    { "name": "Kalvarija municipality", "state_code": "14" },
    { "name": "Kaunas City Municipality", "state_code": "15" },
    { "name": "Kaunas County", "state_code": "KU" },
    { "name": "Kaunas District Municipality", "state_code": "16" },
    { "name": "Kazlų Rūda municipality", "state_code": "17" },
    { "name": "Kėdainiai District Municipality", "state_code": "18" },
    { "name": "Kelmė District Municipality", "state_code": "19" },
    { "name": "Klaipeda City Municipality", "state_code": "20" },
    { "name": "Klaipėda County", "state_code": "KL" },
    { "name": "Klaipėda District Municipality", "state_code": "21" },
    { "name": "Kretinga District Municipality", "state_code": "22" },
    { "name": "Kupiškis District Municipality", "state_code": "23" },
    { "name": "Lazdijai District Municipality", "state_code": "24" },
    { "name": "Marijampolė County", "state_code": "MR" },
    { "name": "Marijampolė Municipality", "state_code": "25" },
    { "name": "Mažeikiai District Municipality", "state_code": "26" },
    { "name": "Molėtai District Municipality", "state_code": "27" },
    { "name": "Neringa Municipality", "state_code": "28" },
    { "name": "Pagėgiai municipality", "state_code": "29" },
    { "name": "Pakruojis District Municipality", "state_code": "30" },
    { "name": "Palanga City Municipality", "state_code": "31" },
    { "name": "Panevėžys City Municipality", "state_code": "32" },
    { "name": "Panevėžys County", "state_code": "PN" },
    { "name": "Panevėžys District Municipality", "state_code": "33" },
    { "name": "Pasvalys District Municipality", "state_code": "34" },
    { "name": "Plungė District Municipality", "state_code": "35" },
    { "name": "Prienai District Municipality", "state_code": "36" },
    { "name": "Radviliškis District Municipality", "state_code": "37" },
    { "name": "Raseiniai District Municipality", "state_code": "38" },
    { "name": "Rietavas municipality", "state_code": "39" },
    { "name": "Rokiškis District Municipality", "state_code": "40" },
    { "name": "Šakiai District Municipality", "state_code": "41" },
    { "name": "Šalčininkai District Municipality", "state_code": "42" },
    { "name": "Šiauliai City Municipality", "state_code": "43" },
    { "name": "Šiauliai County", "state_code": "SA" },
    { "name": "Šiauliai District Municipality", "state_code": "44" },
    { "name": "Šilalė District Municipality", "state_code": "45" },
    { "name": "Šilutė District Municipality", "state_code": "46" },
    { "name": "Širvintos District Municipality", "state_code": "47" },
    { "name": "Skuodas District Municipality", "state_code": "48" },
    { "name": "Švenčionys District Municipality", "state_code": "49" },
    { "name": "Tauragė County", "state_code": "TA" },
    { "name": "Tauragė District Municipality", "state_code": "50" },
    { "name": "Telšiai County", "state_code": "TE" },
    { "name": "Telšiai District Municipality", "state_code": "51" },
    { "name": "Trakai District Municipality", "state_code": "52" },
    { "name": "Ukmergė District Municipality", "state_code": "53" },
    { "name": "Utena County", "state_code": "UT" },
    { "name": "Utena District Municipality", "state_code": "54" },
    { "name": "Varėna District Municipality", "state_code": "55" },
    { "name": "Vilkaviškis District Municipality", "state_code": "56" },
    { "name": "Vilnius City Municipality", "state_code": "57" },
    { "name": "Vilnius County", "state_code": "VL" },
    { "name": "Vilnius District Municipality", "state_code": "58" },
    { "name": "Visaginas Municipality", "state_code": "59" },
    { "name": "Zarasai District Municipality", "state_code": "60" }],
    "LU": [{ "name": "Canton of Capellen", "state_code": "CA" },
    { "name": "Canton of Clervaux", "state_code": "CL" },
    { "name": "Canton of Diekirch", "state_code": "DI" },
    { "name": "Canton of Echternach", "state_code": "EC" },
    { "name": "Canton of Esch-sur-Alzette", "state_code": "ES" },
    { "name": "Canton of Grevenmacher", "state_code": "GR" },
    { "name": "Canton of Luxembourg", "state_code": "LU" },
    { "name": "Canton of Mersch", "state_code": "ME" },
    { "name": "Canton of Redange", "state_code": "RD" },
    { "name": "Canton of Remich", "state_code": "RM" },
    { "name": "Canton of Vianden", "state_code": "VD" },
    { "name": "Canton of Wiltz", "state_code": "WI" },
    { "name": "Diekirch District", "state_code": "D" },
    { "name": "Grevenmacher District", "state_code": "G" },
    { "name": "Luxembourg District", "state_code": "L" }],
    "MO": [],
    "MK": [{ "name": "Aerodrom Municipality", "state_code": "01" },
    { "name": "Aračinovo Municipality", "state_code": "02" },
    { "name": "Berovo Municipality", "state_code": "03" },
    { "name": "Bitola Municipality", "state_code": "04" },
    { "name": "Bogdanci Municipality", "state_code": "05" },
    { "name": "Bogovinje Municipality", "state_code": "06" },
    { "name": "Bosilovo Municipality", "state_code": "07" },
    { "name": "Brvenica Municipality", "state_code": "08" },
    { "name": "Butel Municipality", "state_code": "09" },
    { "name": "Čair Municipality", "state_code": "79" },
    { "name": "Čaška Municipality", "state_code": "80" },
    { "name": "Centar Municipality", "state_code": "77" },
    { "name": "Centar Župa Municipality", "state_code": "78" },
    { "name": "Češinovo-Obleševo Municipality", "state_code": "81" },
    { "name": "Čučer-Sandevo Municipality", "state_code": "82" },
    { "name": "Debarca Municipality", "state_code": "22" },
    { "name": "Delčevo Municipality", "state_code": "23" },
    { "name": "Demir Hisar Municipality", "state_code": "25" },
    { "name": "Demir Kapija Municipality", "state_code": "24" },
    { "name": "Dojran Municipality", "state_code": "26" },
    { "name": "Dolneni Municipality", "state_code": "27" },
    { "name": "Drugovo Municipality", "state_code": "28" },
    { "name": "Gazi Baba Municipality", "state_code": "17" },
    { "name": "Gevgelija Municipality", "state_code": "18" },
    { "name": "Gjorče Petrov Municipality", "state_code": "29" },
    { "name": "Gostivar Municipality", "state_code": "19" },
    { "name": "Gradsko Municipality", "state_code": "20" },
    { "name": "Greater Skopje", "state_code": "85" },
    { "name": "Ilinden Municipality", "state_code": "34" },
    { "name": "Jegunovce Municipality", "state_code": "35" },
    { "name": "Karbinci", "state_code": "37" },
    { "name": "Karpoš Municipality", "state_code": "38" },
    { "name": "Kavadarci Municipality", "state_code": "36" },
    { "name": "Kičevo Municipality", "state_code": "40" },
    { "name": "Kisela Voda Municipality", "state_code": "39" },
    { "name": "Kočani Municipality", "state_code": "42" },
    { "name": "Konče Municipality", "state_code": "41" },
    { "name": "Kratovo Municipality", "state_code": "43" },
    { "name": "Kriva Palanka Municipality", "state_code": "44" },
    { "name": "Krivogaštani Municipality", "state_code": "45" },
    { "name": "Kruševo Municipality", "state_code": "46" },
    { "name": "Kumanovo Municipality", "state_code": "47" },
    { "name": "Lipkovo Municipality", "state_code": "48" },
    { "name": "Lozovo Municipality", "state_code": "49" },
    { "name": "Makedonska Kamenica Municipality", "state_code": "51" },
    { "name": "Makedonski Brod Municipality", "state_code": "52" },
    { "name": "Mavrovo and Rostuša Municipality", "state_code": "50" },
    { "name": "Mogila Municipality", "state_code": "53" },
    { "name": "Negotino Municipality", "state_code": "54" },
    { "name": "Novaci Municipality", "state_code": "55" },
    { "name": "Novo Selo Municipality", "state_code": "56" },
    { "name": "Ohrid Municipality", "state_code": "58" },
    { "name": "Oslomej Municipality", "state_code": "57" },
    { "name": "Pehčevo Municipality", "state_code": "60" },
    { "name": "Petrovec Municipality", "state_code": "59" },
    { "name": "Plasnica Municipality", "state_code": "61" },
    { "name": "Prilep Municipality", "state_code": "62" },
    { "name": "Probištip Municipality", "state_code": "63" },
    { "name": "Radoviš Municipality", "state_code": "64" },
    { "name": "Rankovce Municipality", "state_code": "65" },
    { "name": "Resen Municipality", "state_code": "66" },
    { "name": "Rosoman Municipality", "state_code": "67" },
    { "name": "Saraj Municipality", "state_code": "68" },
    { "name": "Sopište Municipality", "state_code": "70" },
    { "name": "Staro Nagoričane Municipality", "state_code": "71" },
    { "name": "Štip Municipality", "state_code": "83" },
    { "name": "Struga Municipality", "state_code": "72" },
    { "name": "Strumica Municipality", "state_code": "73" },
    { "name": "Studeničani Municipality", "state_code": "74" },
    { "name": "Šuto Orizari Municipality", "state_code": "84" },
    { "name": "Sveti Nikole Municipality", "state_code": "69" },
    { "name": "Tearce Municipality", "state_code": "75" },
    { "name": "Tetovo Municipality", "state_code": "76" },
    { "name": "Valandovo Municipality", "state_code": "10" },
    { "name": "Vasilevo Municipality", "state_code": "11" },
    { "name": "Veles Municipality", "state_code": "13" },
    { "name": "Vevčani Municipality", "state_code": "12" },
    { "name": "Vinica Municipality", "state_code": "14" },
    { "name": "Vraneštica Municipality", "state_code": "15" },
    { "name": "Vrapčište Municipality", "state_code": "16" },
    { "name": "Zajas Municipality", "state_code": "31" },
    { "name": "Zelenikovo Municipality", "state_code": "32" },
    { "name": "Želino Municipality", "state_code": "30" },
    { "name": "Zrnovci Municipality", "state_code": "33" }],
    "MG": [{ "name": "Antananarivo Province", "state_code": "T" },
    { "name": "Antsiranana Province", "state_code": "D" },
    { "name": "Fianarantsoa Province", "state_code": "F" },
    { "name": "Mahajanga Province", "state_code": "M" },
    { "name": "Toamasina Province", "state_code": "A" },
    { "name": "Toliara Province", "state_code": "U" }],
    "MW": [{ "name": "Balaka District", "state_code": "BA" },
    { "name": "Blantyre District", "state_code": "BL" },
    { "name": "Central Region", "state_code": "C" },
    { "name": "Chikwawa District", "state_code": "CK" },
    { "name": "Chiradzulu District", "state_code": "CR" },
    { "name": "Chitipa district", "state_code": "CT" },
    { "name": "Dedza District", "state_code": "DE" },
    { "name": "Dowa District", "state_code": "DO" },
    { "name": "Karonga District", "state_code": "KR" },
    { "name": "Kasungu District", "state_code": "KS" },
    { "name": "Likoma District", "state_code": "LK" },
    { "name": "Lilongwe District", "state_code": "LI" },
    { "name": "Machinga District", "state_code": "MH" },
    { "name": "Mangochi District", "state_code": "MG" },
    { "name": "Mchinji District", "state_code": "MC" },
    { "name": "Mulanje District", "state_code": "MU" },
    { "name": "Mwanza District", "state_code": "MW" },
    { "name": "Mzimba District", "state_code": "MZ" },
    { "name": "Nkhata Bay District", "state_code": "NB" },
    { "name": "Nkhotakota District", "state_code": "NK" },
    { "name": "Northern Region", "state_code": "N" },
    { "name": "Nsanje District", "state_code": "NS" },
    { "name": "Ntcheu District", "state_code": "NU" },
    { "name": "Ntchisi District", "state_code": "NI" },
    { "name": "Phalombe District", "state_code": "PH" },
    { "name": "Rumphi District", "state_code": "RU" },
    { "name": "Salima District", "state_code": "SA" },
    { "name": "Southern Region", "state_code": "S" },
    { "name": "Thyolo District", "state_code": "TH" },
    { "name": "Zomba District", "state_code": "ZO" }],
    "MY": [{ "name": "Johor", "state_code": "01" },
    { "name": "Kedah", "state_code": "02" },
    { "name": "Kelantan", "state_code": "03" },
    { "name": "Kuala Lumpur", "state_code": "14" },
    { "name": "Labuan", "state_code": "15" },
    { "name": "Malacca", "state_code": "04" },
    { "name": "Negeri Sembilan", "state_code": "05" },
    { "name": "Pahang", "state_code": "06" },
    { "name": "Penang", "state_code": "07" },
    { "name": "Perak", "state_code": "08" },
    { "name": "Perlis", "state_code": "09" },
    { "name": "Putrajaya", "state_code": "16" },
    { "name": "Sabah", "state_code": "12" },
    { "name": "Sarawak", "state_code": "13" },
    { "name": "Selangor", "state_code": "10" },
    { "name": "Terengganu", "state_code": "11" }],
    "MV": [{ "name": "Addu Atoll", "state_code": "01" },
    { "name": "Alif Alif Atoll", "state_code": "02" },
    { "name": "Alif Dhaal Atoll", "state_code": "00" },
    { "name": "Central Province", "state_code": "CE" },
    { "name": "Dhaalu Atoll", "state_code": "17" },
    { "name": "Faafu Atoll", "state_code": "14" },
    { "name": "Gaafu Alif Atoll", "state_code": "27" },
    { "name": "Gaafu Dhaalu Atoll", "state_code": "28" },
    { "name": "Gnaviyani Atoll", "state_code": "29" },
    { "name": "Haa Alif Atoll", "state_code": "07" },
    { "name": "Haa Dhaalu Atoll", "state_code": "23" },
    { "name": "Kaafu Atoll", "state_code": "26" },
    { "name": "Laamu Atoll", "state_code": "05" },
    { "name": "Lhaviyani Atoll", "state_code": "03" },
    { "name": "Malé", "state_code": "MLE" },
    { "name": "Meemu Atoll", "state_code": "12" },
    { "name": "Noonu Atoll", "state_code": "25" },
    { "name": "North Central Province", "state_code": "NC" },
    { "name": "North Province", "state_code": "NO" },
    { "name": "Raa Atoll", "state_code": "13" },
    { "name": "Shaviyani Atoll", "state_code": "24" },
    { "name": "South Central Province", "state_code": "SC" },
    { "name": "South Province", "state_code": "SU" },
    { "name": "Thaa Atoll", "state_code": "08" },
    { "name": "Upper South Province", "state_code": "US" },
    { "name": "Vaavu Atoll", "state_code": "04" }],
    "ML": [{ "name": "Bamako", "state_code": "BKO" },
    { "name": "Gao Region", "state_code": "7" },
    { "name": "Kayes Region", "state_code": "1" },
    { "name": "Kidal Region", "state_code": "8" },
    { "name": "Koulikoro Region", "state_code": "2" },
    { "name": "Ménaka Region", "state_code": "9" },
    { "name": "Mopti Region", "state_code": "5" },
    { "name": "Ségou Region", "state_code": "4" },
    { "name": "Sikasso Region", "state_code": "3" },
    { "name": "Taoudénit Region", "state_code": "10" },
    { "name": "Tombouctou Region", "state_code": "6" }],
    "MT": [{ "name": "Attard", "state_code": "01" },
    { "name": "Balzan", "state_code": "02" },
    { "name": "Birgu", "state_code": "03" },
    { "name": "Birkirkara", "state_code": "04" },
    { "name": "Birżebbuġa", "state_code": "05" },
    { "name": "Cospicua", "state_code": "06" },
    { "name": "Dingli", "state_code": "07" },
    { "name": "Fgura", "state_code": "08" },
    { "name": "Floriana", "state_code": "09" },
    { "name": "Fontana", "state_code": "10" },
    { "name": "Għajnsielem", "state_code": "13" },
    { "name": "Għarb", "state_code": "14" },
    { "name": "Għargħur", "state_code": "15" },
    { "name": "Għasri", "state_code": "16" },
    { "name": "Għaxaq", "state_code": "17" },
    { "name": "Gudja", "state_code": "11" },
    { "name": "Gżira", "state_code": "12" },
    { "name": "Ħamrun", "state_code": "18" },
    { "name": "Iklin", "state_code": "19" },
    { "name": "Kalkara", "state_code": "21" },
    { "name": "Kerċem", "state_code": "22" },
    { "name": "Kirkop", "state_code": "23" },
    { "name": "Lija", "state_code": "24" },
    { "name": "Luqa", "state_code": "25" },
    { "name": "Marsa", "state_code": "26" },
    { "name": "Marsaskala", "state_code": "27" },
    { "name": "Marsaxlokk", "state_code": "28" },
    { "name": "Mdina", "state_code": "29" },
    { "name": "Mellieħa", "state_code": "30" },
    { "name": "Mġarr", "state_code": "31" },
    { "name": "Mosta", "state_code": "32" },
    { "name": "Mqabba", "state_code": "33" },
    { "name": "Msida", "state_code": "34" },
    { "name": "Mtarfa", "state_code": "35" },
    { "name": "Munxar", "state_code": "36" },
    { "name": "Nadur", "state_code": "37" },
    { "name": "Naxxar", "state_code": "38" },
    { "name": "Paola", "state_code": "39" },
    { "name": "Pembroke", "state_code": "40" },
    { "name": "Pietà", "state_code": "41" },
    { "name": "Qala", "state_code": "42" },
    { "name": "Qormi", "state_code": "43" },
    { "name": "Qrendi", "state_code": "44" },
    { "name": "Rabat", "state_code": "46" },
    { "name": "Saint Lawrence", "state_code": "50" },
    { "name": "San Ġwann", "state_code": "49" },
    { "name": "Sannat", "state_code": "52" },
    { "name": "Santa Luċija", "state_code": "53" },
    { "name": "Santa Venera", "state_code": "54" },
    { "name": "Senglea", "state_code": "20" },
    { "name": "Siġġiewi", "state_code": "55" },
    { "name": "Sliema", "state_code": "56" },
    { "name": "St. Julian's", "state_code": "48" },
    { "name": "St. Paul's Bay", "state_code": "51" },
    { "name": "Swieqi", "state_code": "57" },
    { "name": "Ta' Xbiex", "state_code": "58" },
    { "name": "Tarxien", "state_code": "59" },
    { "name": "Valletta", "state_code": "60" },
    { "name": "Victoria", "state_code": "45" },
    { "name": "Xagħra", "state_code": "61" },
    { "name": "Xewkija", "state_code": "62" },
    { "name": "Xgħajra", "state_code": "63" },
    { "name": "Żabbar", "state_code": "64" },
    { "name": "Żebbuġ Gozo", "state_code": "65" },
    { "name": "Żebbuġ Malta", "state_code": "66" },
    { "name": "Żejtun", "state_code": "67" },
    { "name": "Żurrieq", "state_code": "68" }],
    "IM": [],
    "MH": [{ "name": "Ralik Chain", "state_code": "L" },
    { "name": "Ratak Chain", "state_code": "T" }],
    "MQ": [],
    "MR": [{ "name": "Adrar Region", "state_code": "07" },
    { "name": "Assaba Region", "state_code": "03" },
    { "name": "Brakna Region", "state_code": "05" },
    { "name": "Dakhlet Nouadhibou", "state_code": "08" },
    { "name": "Gorgol Region", "state_code": "04" },
    { "name": "Guidimaka Region", "state_code": "10" },
    { "name": "Hodh Ech Chargui Region", "state_code": "01" },
    { "name": "Hodh El Gharbi Region", "state_code": "02" },
    { "name": "Inchiri Region", "state_code": "12" },
    { "name": "Nouakchott-Nord Region", "state_code": "14" },
    { "name": "Nouakchott-Ouest Region", "state_code": "13" },
    { "name": "Nouakchott-Sud Region", "state_code": "15" },
    { "name": "Tagant Region", "state_code": "09" },
    { "name": "Tiris Zemmour Region", "state_code": "11" },
    { "name": "Trarza Region", "state_code": "06" }],
    "MU": [{ "name": "Agaléga", "state_code": "AG" },
    { "name": "Beau Bassin-Rose Hill", "state_code": "BR" },
    { "name": "Cargados Carajos", "state_code": "CC" },
    { "name": "Curepipe", "state_code": "CU" },
    { "name": "Flacq District", "state_code": "FL" },
    { "name": "Grand Port District", "state_code": "GP" },
    { "name": "Moka District", "state_code": "MO" },
    { "name": "Pamplemousses District", "state_code": "PA" },
    { "name": "Plaines Wilhems District", "state_code": "PW" },
    { "name": "Port Louis", "state_code": "PU" },
    { "name": "Port Louis District", "state_code": "PL" },
    { "name": "Quatre Bornes", "state_code": "QB" },
    { "name": "Rivière du Rempart District", "state_code": "RR" },
    { "name": "Rivière Noire District", "state_code": "BL" },
    { "name": "Rodrigues", "state_code": "RO" },
    { "name": "Savanne District", "state_code": "SA" },
    { "name": "Vacoas-Phoenix", "state_code": "VP" }],
    "YT": [],
    "MX": [{ "name": "Aguascalientes", "state_code": "AGU" },
    { "name": "Baja California", "state_code": "BCN" },
    { "name": "Baja California Sur", "state_code": "BCS" },
    { "name": "Campeche", "state_code": "CAM" },
    { "name": "Chiapas", "state_code": "CHP" },
    { "name": "Chihuahua", "state_code": "CHH" },
    { "name": "Ciudad de México", "state_code": "CDMX" },
    { "name": "Coahuila de Zaragoza", "state_code": "COA" },
    { "name": "Colima", "state_code": "COL" },
    { "name": "Durango", "state_code": "DUR" },
    { "name": "Estado de México", "state_code": "MEX" },
    { "name": "Guanajuato", "state_code": "GUA" },
    { "name": "Guerrero", "state_code": "GRO" },
    { "name": "Hidalgo", "state_code": "HID" },
    { "name": "Jalisco", "state_code": "JAL" },
    { "name": "Michoacán de Ocampo", "state_code": "MIC" },
    { "name": "Morelos", "state_code": "MOR" },
    { "name": "Nayarit", "state_code": "NAY" },
    { "name": "Nuevo León", "state_code": "NLE" },
    { "name": "Oaxaca", "state_code": "OAX" },
    { "name": "Puebla", "state_code": "PUE" },
    { "name": "Querétaro", "state_code": "QUE" },
    { "name": "Quintana Roo", "state_code": "ROO" },
    { "name": "San Luis Potosí", "state_code": "SLP" },
    { "name": "Sinaloa", "state_code": "SIN" },
    { "name": "Sonora", "state_code": "SON" },
    { "name": "Tabasco", "state_code": "TAB" },
    { "name": "Tamaulipas", "state_code": "TAM" },
    { "name": "Tlaxcala", "state_code": "TLA" },
    { "name": "Veracruz de Ignacio de la Llave", "state_code": "VER" },
    { "name": "Yucatán", "state_code": "YUC" },
    { "name": "Zacatecas", "state_code": "ZAC" }],
    "FM": [{ "name": "Chuuk State", "state_code": "TRK" },
    { "name": "Kosrae State", "state_code": "KSA" },
    { "name": "Pohnpei State", "state_code": "PNI" },
    { "name": "Yap State", "state_code": "YAP" }],
    "MD": [{ "name": "Anenii Noi District", "state_code": "AN" },
    { "name": "Bălți Municipality", "state_code": "BA" },
    { "name": "Basarabeasca District", "state_code": "BS" },
    { "name": "Bender Municipality", "state_code": "BD" },
    { "name": "Briceni District", "state_code": "BR" },
    { "name": "Cahul District", "state_code": "CA" },
    { "name": "Călărași District", "state_code": "CL" },
    { "name": "Cantemir District", "state_code": "CT" },
    { "name": "Căușeni District", "state_code": "CS" },
    { "name": "Chișinău Municipality", "state_code": "CU" },
    { "name": "Cimișlia District", "state_code": "CM" },
    { "name": "Criuleni District", "state_code": "CR" },
    { "name": "Dondușeni District", "state_code": "DO" },
    { "name": "Drochia District", "state_code": "DR" },
    { "name": "Dubăsari District", "state_code": "DU" },
    { "name": "Edineț District", "state_code": "ED" },
    { "name": "Fălești District", "state_code": "FA" },
    { "name": "Florești District", "state_code": "FL" },
    { "name": "Gagauzia", "state_code": "GA" },
    { "name": "Glodeni District", "state_code": "GL" },
    { "name": "Hîncești District", "state_code": "HI" },
    { "name": "Ialoveni District", "state_code": "IA" },
    { "name": "Nisporeni District", "state_code": "NI" },
    { "name": "Ocnița District", "state_code": "OC" },
    { "name": "Orhei District", "state_code": "OR" },
    { "name": "Rezina District", "state_code": "RE" },
    { "name": "Rîșcani District", "state_code": "RI" },
    { "name": "Sîngerei District", "state_code": "SI" },
    { "name": "Șoldănești District", "state_code": "SD" },
    { "name": "Soroca District", "state_code": "SO" },
    { "name": "Ștefan Vodă District", "state_code": "SV" },
    { "name": "Strășeni District", "state_code": "ST" },
    { "name": "Taraclia District", "state_code": "TA" },
    { "name": "Telenești District", "state_code": "TE" },
    { "name": "Transnistria autonomous territorial unit", "state_code": "SN" },
    { "name": "Ungheni District", "state_code": "UN" }],
    "MC": [{ "name": "La Colle", "state_code": "CL" },
    { "name": "La Condamine", "state_code": "CO" },
    { "name": "Moneghetti", "state_code": "MG" }],
    "MN": [{ "name": "Arkhangai Province", "state_code": "073" },
    { "name": "Bayan-Ölgii Province", "state_code": "071" },
    { "name": "Bayankhongor Province", "state_code": "069" },
    { "name": "Bulgan Province", "state_code": "067" },
    { "name": "Darkhan-Uul Province", "state_code": "037" },
    { "name": "Dornod Province", "state_code": "061" },
    { "name": "Dornogovi Province", "state_code": "063" },
    { "name": "Dundgovi Province", "state_code": "059" },
    { "name": "Govi-Altai Province", "state_code": "065" },
    { "name": "Govisümber Province", "state_code": "064" },
    { "name": "Khentii Province", "state_code": "039" },
    { "name": "Khovd Province", "state_code": "043" },
    { "name": "Khövsgöl Province", "state_code": "041" },
    { "name": "Ömnögovi Province", "state_code": "053" },
    { "name": "Orkhon Province", "state_code": "035" },
    { "name": "Övörkhangai Province", "state_code": "055" },
    { "name": "Selenge Province", "state_code": "049" },
    { "name": "Sükhbaatar Province", "state_code": "051" },
    { "name": "Töv Province", "state_code": "047" },
    { "name": "Uvs Province", "state_code": "046" },
    { "name": "Zavkhan Province", "state_code": "057" }],
    "ME": [{ "name": "Andrijevica Municipality", "state_code": "01" },
    { "name": "Bar Municipality", "state_code": "02" },
    { "name": "Berane Municipality", "state_code": "03" },
    { "name": "Bijelo Polje Municipality", "state_code": "04" },
    { "name": "Budva Municipality", "state_code": "05" },
    { "name": "Danilovgrad Municipality", "state_code": "07" },
    { "name": "Gusinje Municipality", "state_code": "22" },
    { "name": "Kolašin Municipality", "state_code": "09" },
    { "name": "Kotor Municipality", "state_code": "10" },
    { "name": "Mojkovac Municipality", "state_code": "11" },
    { "name": "Nikšić Municipality", "state_code": "12" },
    { "name": "Old Royal Capital Cetinje", "state_code": "06" },
    { "name": "Petnjica Municipality", "state_code": "23" },
    { "name": "Plav Municipality", "state_code": "13" },
    { "name": "Pljevlja Municipality", "state_code": "14" },
    { "name": "Plužine Municipality", "state_code": "15" },
    { "name": "Podgorica Municipality", "state_code": "16" },
    { "name": "Rožaje Municipality", "state_code": "17" },
    { "name": "Šavnik Municipality", "state_code": "18" },
    { "name": "Tivat Municipality", "state_code": "19" },
    { "name": "Ulcinj Municipality", "state_code": "20" },
    { "name": "Žabljak Municipality", "state_code": "21" }],
    "MS": [],
    "MA": [{ "name": "Agadir-Ida-Ou-Tanane", "state_code": "AGD" },
    { "name": "Al Haouz", "state_code": "HAO" },
    { "name": "Al Hoceïma", "state_code": "HOC" },
    { "name": "Aousserd (EH)", "state_code": "AOU" },
    { "name": "Assa-Zag (EH-partial)", "state_code": "ASZ" },
    { "name": "Azilal", "state_code": "AZI" },
    { "name": "Béni Mellal", "state_code": "BEM" },
    { "name": "Béni Mellal-Khénifra", "state_code": "05" },
    { "name": "Benslimane", "state_code": "BES" },
    { "name": "Berkane", "state_code": "BER" },
    { "name": "Berrechid", "state_code": "BRR" },
    { "name": "Boujdour (EH)", "state_code": "BOD" },
    { "name": "Boulemane", "state_code": "BOM" },
    { "name": "Casablanca", "state_code": "CAS" },
    { "name": "Casablanca-Settat", "state_code": "06" },
    { "name": "Chefchaouen", "state_code": "CHE" },
    { "name": "Chichaoua", "state_code": "CHI" },
    { "name": "Chtouka-Ait Baha", "state_code": "CHT" },
    { "name": "Dakhla-Oued Ed-Dahab (EH)", "state_code": "12" },
    { "name": "Drâa-Tafilalet", "state_code": "08" },
    { "name": "Driouch", "state_code": "DRI" },
    { "name": "El Hajeb", "state_code": "HAJ" },
    { "name": "El Jadida", "state_code": "JDI" },
    { "name": "El Kelâa des Sraghna", "state_code": "KES" },
    { "name": "Errachidia", "state_code": "ERR" },
    { "name": "Es-Semara (EH-partial)", "state_code": "ESM" },
    { "name": "Essaouira", "state_code": "ESI" },
    { "name": "Fahs-Anjra", "state_code": "FAH" },
    { "name": "Fès", "state_code": "FES" },
    { "name": "Fès-Meknès", "state_code": "03" },
    { "name": "Figuig", "state_code": "FIG" },
    { "name": "Fquih Ben Salah", "state_code": "FQH" },
    { "name": "Guelmim", "state_code": "GUE" },
    { "name": "Guelmim-Oued Noun (EH-partial)", "state_code": "10" },
    { "name": "Guercif", "state_code": "GUF" },
    { "name": "Ifrane", "state_code": "IFR" },
    { "name": "Inezgane-Ait Melloul", "state_code": "INE" },
    { "name": "Jerada", "state_code": "JRA" },
    { "name": "Kénitra", "state_code": "KEN" },
    { "name": "Khémisset", "state_code": "KHE" },
    { "name": "Khénifra", "state_code": "KHN" },
    { "name": "Khouribga", "state_code": "KHO" },
    { "name": "L'Oriental", "state_code": "02" },
    { "name": "Laâyoune (EH)", "state_code": "LAA" },
    { "name": "Laâyoune-Sakia El Hamra (EH-partial)", "state_code": "11" },
    { "name": "Larache", "state_code": "LAR" },
    { "name": "M’diq-Fnideq", "state_code": "MDF" },
    { "name": "Marrakech", "state_code": "MAR" },
    { "name": "Marrakesh-Safi", "state_code": "07" },
    { "name": "Médiouna", "state_code": "MED" },
    { "name": "Meknès", "state_code": "MEK" },
    { "name": "Midelt", "state_code": "MID" },
    { "name": "Mohammadia", "state_code": "MOH" },
    { "name": "Moulay Yacoub", "state_code": "MOU" },
    { "name": "Nador", "state_code": "NAD" },
    { "name": "Nouaceur", "state_code": "NOU" },
    { "name": "Ouarzazate", "state_code": "OUA" },
    { "name": "Oued Ed-Dahab (EH)", "state_code": "OUD" },
    { "name": "Ouezzane", "state_code": "OUZ" },
    { "name": "Oujda-Angad", "state_code": "OUJ" },
    { "name": "Rabat", "state_code": "RAB" },
    { "name": "Rabat-Salé-Kénitra", "state_code": "04" },
    { "name": "Rehamna", "state_code": "REH" },
    { "name": "Safi", "state_code": "SAF" },
    { "name": "Salé", "state_code": "SAL" },
    { "name": "Sefrou", "state_code": "SEF" },
    { "name": "Settat", "state_code": "SET" },
    { "name": "Sidi Bennour", "state_code": "SIB" },
    { "name": "Sidi Ifni", "state_code": "SIF" },
    { "name": "Sidi Kacem", "state_code": "SIK" },
    { "name": "Sidi Slimane", "state_code": "SIL" },
    { "name": "Skhirate-Témara", "state_code": "SKH" },
    { "name": "Souss-Massa", "state_code": "09" },
    { "name": "Tan-Tan (EH-partial)", "state_code": "TNT" },
    { "name": "Tanger-Assilah", "state_code": "TNG" },
    { "name": "Tanger-Tétouan-Al Hoceïma", "state_code": "01" },
    { "name": "Taounate", "state_code": "TAO" },
    { "name": "Taourirt", "state_code": "TAI" },
    { "name": "Tarfaya (EH-partial)", "state_code": "TAF" },
    { "name": "Taroudannt", "state_code": "TAR" },
    { "name": "Tata", "state_code": "TAT" },
    { "name": "Taza", "state_code": "TAZ" },
    { "name": "Tétouan", "state_code": "TET" },
    { "name": "Tinghir", "state_code": "TIN" },
    { "name": "Tiznit", "state_code": "TIZ" },
    { "name": "Youssoufia", "state_code": "YUS" },
    { "name": "Zagora", "state_code": "ZAG" }],
    "MZ": [{ "name": "Cabo Delgado Province", "state_code": "P" },
    { "name": "Gaza Province", "state_code": "G" },
    { "name": "Inhambane Province", "state_code": "I" },
    { "name": "Manica Province", "state_code": "B" },
    { "name": "Maputo", "state_code": "MPM" },
    { "name": "Maputo Province", "state_code": "L" },
    { "name": "Nampula Province", "state_code": "N" },
    { "name": "Niassa Province", "state_code": "A" },
    { "name": "Sofala Province", "state_code": "S" },
    { "name": "Tete Province", "state_code": "T" },
    { "name": "Zambezia Province", "state_code": "Q" }],
    "MM": [{ "name": "Ayeyarwady Region", "state_code": "07" },
    { "name": "Bago", "state_code": "02" },
    { "name": "Chin State", "state_code": "14" },
    { "name": "Kachin State", "state_code": "11" },
    { "name": "Kayah State", "state_code": "12" },
    { "name": "Kayin State", "state_code": "13" },
    { "name": "Magway Region", "state_code": "03" },
    { "name": "Mandalay Region", "state_code": "04" },
    { "name": "Mon State", "state_code": "15" },
    { "name": "Naypyidaw Union Territory", "state_code": "18" },
    { "name": "Rakhine State", "state_code": "16" },
    { "name": "Sagaing Region", "state_code": "01" },
    { "name": "Shan State", "state_code": "17" },
    { "name": "Tanintharyi Region", "state_code": "05" },
    { "name": "Yangon Region", "state_code": "06" }],
    "NA": [{ "name": "Erongo Region", "state_code": "ER" },
    { "name": "Hardap Region", "state_code": "HA" },
    { "name": "Karas Region", "state_code": "KA" },
    { "name": "Kavango East Region", "state_code": "KE" },
    { "name": "Kavango West Region", "state_code": "KW" },
    { "name": "Khomas Region", "state_code": "KH" },
    { "name": "Kunene Region", "state_code": "KU" },
    { "name": "Ohangwena Region", "state_code": "OW" },
    { "name": "Omaheke Region", "state_code": "OH" },
    { "name": "Omusati Region", "state_code": "OS" },
    { "name": "Oshana Region", "state_code": "ON" },
    { "name": "Oshikoto Region", "state_code": "OT" },
    { "name": "Otjozondjupa Region", "state_code": "OD" },
    { "name": "Zambezi Region", "state_code": "CA" }],
    "NR": [{ "name": "Aiwo District", "state_code": "01" },
    { "name": "Anabar District", "state_code": "02" },
    { "name": "Anetan District", "state_code": "03" },
    { "name": "Anibare District", "state_code": "04" },
    { "name": "Baiti District", "state_code": "05" },
    { "name": "Boe District", "state_code": "06" },
    { "name": "Buada District", "state_code": "07" },
    { "name": "Denigomodu District", "state_code": "08" },
    { "name": "Ewa District", "state_code": "09" },
    { "name": "Ijuw District", "state_code": "10" },
    { "name": "Meneng District", "state_code": "11" },
    { "name": "Nibok District", "state_code": "12" },
    { "name": "Uaboe District", "state_code": "13" },
    { "name": "Yaren District", "state_code": "14" }],
    "NP": [{ "name": "Bagmati Zone", "state_code": "BA" },
    { "name": "Bheri Zone", "state_code": "BH" },
    { "name": "Central Region", "state_code": "1" },
    { "name": "Dhaulagiri Zone", "state_code": "DH" },
    { "name": "Eastern Development Region", "state_code": "4" },
    { "name": "Far-Western Development Region", "state_code": "5" },
    { "name": "Gandaki Zone", "state_code": "GA" },
    { "name": "Janakpur Zone", "state_code": "JA" },
    { "name": "Karnali Zone", "state_code": "KA" },
    { "name": "Kosi Zone", "state_code": "KO" },
    { "name": "Lumbini Zone", "state_code": "LU" },
    { "name": "Mahakali Zone", "state_code": "MA" },
    { "name": "Mechi Zone", "state_code": "ME" },
    { "name": "Mid-Western Region", "state_code": "2" },
    { "name": "Narayani Zone", "state_code": "NA" },
    { "name": "Rapti Zone", "state_code": "RA" },
    { "name": "Sagarmatha Zone", "state_code": "SA" },
    { "name": "Seti Zone", "state_code": "SE" },
    { "name": "Western Region", "state_code": "3" }],
    "NL": [{ "name": "Bonaire", "state_code": "BQ1" },
    { "name": "Drenthe", "state_code": "DR" },
    { "name": "Flevoland", "state_code": "FL" },
    { "name": "Friesland", "state_code": "FR" },
    { "name": "Gelderland", "state_code": "GE" },
    { "name": "Groningen", "state_code": "GR" },
    { "name": "Limburg", "state_code": "LI" },
    { "name": "North Brabant", "state_code": "NB" },
    { "name": "North Holland", "state_code": "NH" },
    { "name": "Overijssel", "state_code": "OV" },
    { "name": "Saba", "state_code": "BQ2" },
    { "name": "Sint Eustatius", "state_code": "BQ3" },
    { "name": "South Holland", "state_code": "ZH" },
    { "name": "Utrecht", "state_code": "UT" },
    { "name": "Zeeland", "state_code": "ZE" }],
    "NC": [],
    "NZ": [{ "name": "Auckland Region", "state_code": "AUK" },
    { "name": "Bay of Plenty Region", "state_code": "BOP" },
    { "name": "Canterbury Region", "state_code": "CAN" },
    { "name": "Chatham Islands", "state_code": "CIT" },
    { "name": "Gisborne District", "state_code": "GIS" },
    { "name": "Hawke's Bay Region", "state_code": "HKB" },
    { "name": "Manawatu-Wanganui Region", "state_code": "MWT" },
    { "name": "Marlborough Region", "state_code": "MBH" },
    { "name": "Nelson Region", "state_code": "NSN" },
    { "name": "Northland Region", "state_code": "NTL" },
    { "name": "Otago Region", "state_code": "OTA" },
    { "name": "Southland Region", "state_code": "STL" },
    { "name": "Taranaki Region", "state_code": "TKI" },
    { "name": "Tasman District", "state_code": "TAS" },
    { "name": "Waikato Region", "state_code": "WKO" },
    { "name": "Wellington Region", "state_code": "WGN" },
    { "name": "West Coast Region", "state_code": "WTC" }],
    "NI": [{ "name": "Boaco", "state_code": "BO" },
    { "name": "Carazo", "state_code": "CA" },
    { "name": "Chinandega", "state_code": "CI" },
    { "name": "Chontales", "state_code": "CO" },
    { "name": "Estelí", "state_code": "ES" },
    { "name": "Granada", "state_code": "GR" },
    { "name": "Jinotega", "state_code": "JI" },
    { "name": "León", "state_code": "LE" },
    { "name": "Madriz", "state_code": "MD" },
    { "name": "Managua", "state_code": "MN" },
    { "name": "Masaya", "state_code": "MS" },
    { "name": "Matagalpa", "state_code": "MT" },
    { "name": "North Caribbean Coast", "state_code": "AN" },
    { "name": "Nueva Segovia", "state_code": "NS" },
    { "name": "Río San Juan", "state_code": "SJ" },
    { "name": "Rivas", "state_code": "RI" },
    { "name": "South Caribbean Coast", "state_code": "AS" }],
    "NE": [{ "name": "Agadez Region", "state_code": "1" },
    { "name": "Diffa Region", "state_code": "2" },
    { "name": "Dosso Region", "state_code": "3" },
    { "name": "Maradi Region", "state_code": "4" },
    { "name": "Tahoua Region", "state_code": "5" },
    { "name": "Tillabéri Region", "state_code": "6" },
    { "name": "Zinder Region", "state_code": "7" }],
    "NG": [{ "name": "Abia", "state_code": "AB" },
    { "name": "Abuja Federal Capital Territory", "state_code": "FC" },
    { "name": "Adamawa", "state_code": "AD" },
    { "name": "Akwa Ibom", "state_code": "AK" },
    { "name": "Anambra", "state_code": "AN" },
    { "name": "Bauchi", "state_code": "BA" },
    { "name": "Bayelsa", "state_code": "BY" },
    { "name": "Benue", "state_code": "BE" },
    { "name": "Borno", "state_code": "BO" },
    { "name": "Cross River", "state_code": "CR" },
    { "name": "Delta", "state_code": "DE" },
    { "name": "Ebonyi", "state_code": "EB" },
    { "name": "Edo", "state_code": "ED" },
    { "name": "Ekiti", "state_code": "EK" },
    { "name": "Enugu", "state_code": "EN" },
    { "name": "Gombe", "state_code": "GO" },
    { "name": "Imo", "state_code": "IM" },
    { "name": "Jigawa", "state_code": "JI" },
    { "name": "Kaduna", "state_code": "KD" },
    { "name": "Kano", "state_code": "KN" },
    { "name": "Katsina", "state_code": "KT" },
    { "name": "Kebbi", "state_code": "KE" },
    { "name": "Kogi", "state_code": "KO" },
    { "name": "Kwara", "state_code": "KW" },
    { "name": "Lagos", "state_code": "LA" },
    { "name": "Nasarawa", "state_code": "NA" },
    { "name": "Niger", "state_code": "NI" },
    { "name": "Ogun", "state_code": "OG" },
    { "name": "Ondo", "state_code": "ON" },
    { "name": "Osun", "state_code": "OS" },
    { "name": "Oyo", "state_code": "OY" },
    { "name": "Plateau", "state_code": "PL" },
    { "name": "Rivers", "state_code": "RI" },
    { "name": "Sokoto", "state_code": "SO" },
    { "name": "Taraba", "state_code": "TA" },
    { "name": "Yobe", "state_code": "YO" },
    { "name": "Zamfara", "state_code": "ZA" }],
    "NU": [],
    "NF": [],
    "KP": [{ "name": "Chagang Province", "state_code": "04" },
    { "name": "Kangwon Province", "state_code": "07" },
    { "name": "North Hamgyong Province", "state_code": "09" },
    { "name": "North Hwanghae Province", "state_code": "06" },
    { "name": "North Pyongan Province", "state_code": "03" },
    { "name": "Pyongyang", "state_code": "01" },
    { "name": "Rason", "state_code": "13" },
    { "name": "Ryanggang Province", "state_code": "10" },
    { "name": "South Hamgyong Province", "state_code": "08" },
    { "name": "South Hwanghae Province", "state_code": "05" },
    { "name": "South Pyongan Province", "state_code": "02" }],
    "MP": [],
    "NO": [{ "name": "Akershus", "state_code": "02" },
    { "name": "Buskerud", "state_code": "06" },
    { "name": "Finnmark", "state_code": "20" },
    { "name": "Hedmark", "state_code": "04" },
    { "name": "Hordaland", "state_code": "12" },
    { "name": "Jan Mayen", "state_code": "22" },
    { "name": "Møre og Romsdal", "state_code": "15" },
    { "name": "Nord-Trøndelag", "state_code": "17" },
    { "name": "Nordland", "state_code": "18" },
    { "name": "Oppland", "state_code": "05" },
    { "name": "Oslo", "state_code": "03" },
    { "name": "Østfold", "state_code": "01" },
    { "name": "Rogaland", "state_code": "11" },
    { "name": "Sogn og Fjordane", "state_code": "14" },
    { "name": "Sør-Trøndelag", "state_code": "16" },
    { "name": "Svalbard", "state_code": "21" },
    { "name": "Telemark", "state_code": "08" },
    { "name": "Troms", "state_code": "19" },
    { "name": "Trøndelag", "state_code": "50" },
    { "name": "Vest-Agder", "state_code": "10" },
    { "name": "Vestfold", "state_code": "07" }],
    "OM": [{ "name": "Ad Dakhiliyah Governorate", "state_code": "DA" },
    { "name": "Ad Dhahirah Governorate", "state_code": "ZA" },
    { "name": "Al Batinah North Governorate", "state_code": "BS" },
    { "name": "Al Batinah Region", "state_code": "BA" },
    { "name": "Al Batinah South Governorate", "state_code": "BJ" },
    { "name": "Al Buraimi Governorate", "state_code": "BU" },
    { "name": "Al Wusta Governorate", "state_code": "WU" },
    { "name": "Ash Sharqiyah North Governorate", "state_code": "SS" },
    { "name": "Ash Sharqiyah Region", "state_code": "SH" },
    { "name": "Ash Sharqiyah South Governorate", "state_code": "SJ" },
    { "name": "Dhofar Governorate", "state_code": "ZU" },
    { "name": "Musandam Governorate", "state_code": "MU" },
    { "name": "Muscat Governorate", "state_code": "MA" }],
    "PK": [{ "name": "Azad Kashmir", "state_code": "JK" },
    { "name": "Balochistan", "state_code": "BA" },
    { "name": "Federally Administered Tribal Areas", "state_code": "TA" },
    { "name": "Gilgit-Baltistan", "state_code": "GB" },
    { "name": "Islamabad Capital Territory", "state_code": "IS" },
    { "name": "Khyber Pakhtunkhwa", "state_code": "KP" },
    { "name": "Punjab", "state_code": "PB" },
    { "name": "Sindh", "state_code": "SD" }],
    "PW": [{ "name": "Aimeliik", "state_code": "002" },
    { "name": "Airai", "state_code": "004" },
    { "name": "Angaur", "state_code": "010" },
    { "name": "Hatohobei", "state_code": "050" },
    { "name": "Kayangel", "state_code": "100" },
    { "name": "Koror", "state_code": "150" },
    { "name": "Melekeok", "state_code": "212" },
    { "name": "Ngaraard", "state_code": "214" },
    { "name": "Ngarchelong", "state_code": "218" },
    { "name": "Ngardmau", "state_code": "222" },
    { "name": "Ngatpang", "state_code": "224" },
    { "name": "Ngchesar", "state_code": "226" },
    { "name": "Ngeremlengui", "state_code": "227" },
    { "name": "Ngiwal", "state_code": "228" },
    { "name": "Peleliu", "state_code": "350" },
    { "name": "Sonsorol", "state_code": "370" }],
    "PS": [],
    "PA": [{ "name": "Bocas del Toro Province", "state_code": "1" },
    { "name": "Chiriquí Province", "state_code": "4" },
    { "name": "Coclé Province", "state_code": "2" },
    { "name": "Colón Province", "state_code": "3" },
    { "name": "Darién Province", "state_code": "5" },
    { "name": "Emberá-Wounaan Comarca", "state_code": "EM" },
    { "name": "Guna Yala", "state_code": "KY" },
    { "name": "Herrera Province", "state_code": "6" },
    { "name": "Los Santos Province", "state_code": "7" },
    { "name": "Ngöbe-Buglé Comarca", "state_code": "NB" },
    { "name": "Panamá Oeste Province", "state_code": "10" },
    { "name": "Panamá Province", "state_code": "8" },
    { "name": "Veraguas Province", "state_code": "9" }],
    "PG": [{ "name": "Bougainville", "state_code": "NSB" },
    { "name": "Central Province", "state_code": "CPM" },
    { "name": "Chimbu Province", "state_code": "CPK" },
    { "name": "East New Britain", "state_code": "EBR" },
    { "name": "Eastern Highlands Province", "state_code": "EHG" },
    { "name": "Enga Province", "state_code": "EPW" },
    { "name": "Gulf", "state_code": "GPK" },
    { "name": "Hela", "state_code": "HLA" },
    { "name": "Jiwaka Province", "state_code": "JWK" },
    { "name": "Madang Province", "state_code": "MPM" },
    { "name": "Manus Province", "state_code": "MRL" },
    { "name": "Milne Bay Province", "state_code": "MBA" },
    { "name": "Morobe Province", "state_code": "MPL" },
    { "name": "New Ireland Province", "state_code": "NIK" },
    { "name": "Oro Province", "state_code": "NPP" },
    { "name": "Port Moresby", "state_code": "NCD" },
    { "name": "Sandaun Province", "state_code": "SAN" },
    { "name": "Southern Highlands Province", "state_code": "SHM" },
    { "name": "West New Britain Province", "state_code": "WBK" },
    { "name": "Western Highlands Province", "state_code": "WHM" },
    { "name": "Western Province", "state_code": "WPD" }],
    "PY": [{ "name": "Alto Paraguay Department", "state_code": "16" },
    { "name": "Alto Paraná Department", "state_code": "10" },
    { "name": "Amambay Department", "state_code": "13" },
    { "name": "Boquerón Department", "state_code": "19" },
    { "name": "Caaguazú", "state_code": "5" },
    { "name": "Caazapá", "state_code": "6" },
    { "name": "Canindeyú", "state_code": "14" },
    { "name": "Central Department", "state_code": "11" },
    { "name": "Concepción Department", "state_code": "1" },
    { "name": "Cordillera Department", "state_code": "3" },
    { "name": "Guairá Department", "state_code": "4" },
    { "name": "Itapúa", "state_code": "7" },
    { "name": "Misiones Department", "state_code": "8" },
    { "name": "Ñeembucú Department", "state_code": "12" },
    { "name": "Paraguarí Department", "state_code": "9" },
    { "name": "Presidente Hayes Department", "state_code": "15" },
    { "name": "San Pedro Department", "state_code": "2" }],
    "PE": [{ "name": "Amazonas", "state_code": "AMA" },
    { "name": "Áncash", "state_code": "ANC" },
    { "name": "Apurímac", "state_code": "APU" },
    { "name": "Arequipa", "state_code": "ARE" },
    { "name": "Ayacucho", "state_code": "AYA" },
    { "name": "Cajamarca", "state_code": "CAJ" },
    { "name": "Callao", "state_code": "CAL" },
    { "name": "Cusco", "state_code": "CUS" },
    { "name": "Huancavelica", "state_code": "HUV" },
    { "name": "Huanuco", "state_code": "HUC" },
    { "name": "Ica", "state_code": "ICA" },
    { "name": "Junín", "state_code": "JUN" },
    { "name": "La Libertad", "state_code": "LAL" },
    { "name": "Lambayeque", "state_code": "LAM" },
    { "name": "Lima", "state_code": "LIM" },
    { "name": "Loreto", "state_code": "LOR" },
    { "name": "Madre de Dios", "state_code": "MDD" },
    { "name": "Moquegua", "state_code": "MOQ" },
    { "name": "Pasco", "state_code": "PAS" },
    { "name": "Piura", "state_code": "PIU" },
    { "name": "Puno", "state_code": "PUN" },
    { "name": "San Martín", "state_code": "SAM" },
    { "name": "Tacna", "state_code": "TAC" },
    { "name": "Tumbes", "state_code": "TUM" },
    { "name": "Ucayali", "state_code": "UCA" }],
    "PH": [{ "name": "Abra", "state_code": "ABR" },
    { "name": "Agusan del Norte", "state_code": "AGN" },
    { "name": "Agusan del Sur", "state_code": "AGS" },
    { "name": "Aklan", "state_code": "AKL" },
    { "name": "Albay", "state_code": "ALB" },
    { "name": "Antique", "state_code": "ANT" },
    { "name": "Apayao", "state_code": "APA" },
    { "name": "Aurora", "state_code": "AUR" },
    { "name": "Autonomous Region in Muslim Mindanao", "state_code": "14" },
    { "name": "Basilan", "state_code": "BAS" },
    { "name": "Bataan", "state_code": "BAN" },
    { "name": "Batanes", "state_code": "BTN" },
    { "name": "Batangas", "state_code": "BTG" },
    { "name": "Benguet", "state_code": "BEN" },
    { "name": "Bicol Region", "state_code": "05" },
    { "name": "Biliran", "state_code": "BIL" },
    { "name": "Bohol", "state_code": "BOH" },
    { "name": "Bukidnon", "state_code": "BUK" },
    { "name": "Bulacan", "state_code": "BUL" },
    { "name": "Cagayan", "state_code": "CAG" },
    { "name": "Cagayan Valley", "state_code": "02" },
    { "name": "Calabarzon", "state_code": "40" },
    { "name": "Camarines Norte", "state_code": "CAN" },
    { "name": "Camarines Sur", "state_code": "CAS" },
    { "name": "Camiguin", "state_code": "CAM" },
    { "name": "Capiz", "state_code": "CAP" },
    { "name": "Caraga", "state_code": "13" },
    { "name": "Catanduanes", "state_code": "CAT" },
    { "name": "Cavite", "state_code": "CAV" },
    { "name": "Cebu", "state_code": "CEB" },
    { "name": "Central Luzon", "state_code": "03" },
    { "name": "Central Visayas", "state_code": "07" },
    { "name": "Compostela Valley", "state_code": "COM" },
    { "name": "Cordillera Administrative Region", "state_code": "15" },
    { "name": "Cotabato", "state_code": "NCO" },
    { "name": "Davao del Norte", "state_code": "DAV" },
    { "name": "Davao del Sur", "state_code": "DAS" },
    { "name": "Davao Occidental", "state_code": "DVO" },
    { "name": "Davao Oriental", "state_code": "DAO" },
    { "name": "Davao Region", "state_code": "11" },
    { "name": "Dinagat Islands", "state_code": "DIN" },
    { "name": "Eastern Samar", "state_code": "EAS" },
    { "name": "Eastern Visayas", "state_code": "08" },
    { "name": "Guimaras", "state_code": "GUI" },
    { "name": "Ifugao", "state_code": "IFU" },
    { "name": "Ilocos Norte", "state_code": "ILN" },
    { "name": "Ilocos Region", "state_code": "01" },
    { "name": "Ilocos Sur", "state_code": "ILS" },
    { "name": "Iloilo", "state_code": "ILI" },
    { "name": "Isabela", "state_code": "ISA" },
    { "name": "Kalinga", "state_code": "KAL" },
    { "name": "La Union", "state_code": "LUN" },
    { "name": "Laguna", "state_code": "LAG" },
    { "name": "Lanao del Norte", "state_code": "LAN" },
    { "name": "Lanao del Sur", "state_code": "LAS" },
    { "name": "Leyte", "state_code": "LEY" },
    { "name": "Maguindanao", "state_code": "MAG" },
    { "name": "Marinduque", "state_code": "MAD" },
    { "name": "Masbate", "state_code": "MAS" },
    { "name": "Metro Manila", "state_code": "NCR" },
    { "name": "Mimaropa", "state_code": "41" },
    { "name": "Misamis Occidental", "state_code": "MSC" },
    { "name": "Misamis Oriental", "state_code": "MSR" },
    { "name": "Mountain Province", "state_code": "MOU" },
    { "name": "Negros Occidental", "state_code": "NEC" },
    { "name": "Negros Oriental", "state_code": "NER" },
    { "name": "Northern Mindanao", "state_code": "10" },
    { "name": "Northern Samar", "state_code": "NSA" },
    { "name": "Nueva Ecija", "state_code": "NUE" },
    { "name": "Nueva Vizcaya", "state_code": "NUV" },
    { "name": "Occidental Mindoro", "state_code": "MDC" },
    { "name": "Oriental Mindoro", "state_code": "MDR" },
    { "name": "Palawan", "state_code": "PLW" },
    { "name": "Pampanga", "state_code": "PAM" },
    { "name": "Pangasinan", "state_code": "PAN" },
    { "name": "Quezon", "state_code": "QUE" },
    { "name": "Quirino", "state_code": "QUI" },
    { "name": "Rizal", "state_code": "RIZ" },
    { "name": "Romblon", "state_code": "ROM" },
    { "name": "Sarangani", "state_code": "SAR" },
    { "name": "Siquijor", "state_code": "SIG" },
    { "name": "Soccsksargen", "state_code": "12" },
    { "name": "Sorsogon", "state_code": "SOR" },
    { "name": "South Cotabato", "state_code": "SCO" },
    { "name": "Southern Leyte", "state_code": "SLE" },
    { "name": "Sultan Kudarat", "state_code": "SUK" },
    { "name": "Sulu", "state_code": "SLU" },
    { "name": "Surigao del Norte", "state_code": "SUN" },
    { "name": "Surigao del Sur", "state_code": "SUR" },
    { "name": "Tarlac", "state_code": "TAR" },
    { "name": "Tawi-Tawi", "state_code": "TAW" },
    { "name": "Western Visayas", "state_code": "06" },
    { "name": "Zambales", "state_code": "ZMB" },
    { "name": "Zamboanga del Norte", "state_code": "ZAN" },
    { "name": "Zamboanga del Sur", "state_code": "ZAS" },
    { "name": "Zamboanga Peninsula", "state_code": "09" },
    { "name": "Zamboanga Sibugay", "state_code": "ZSI" }],
    "PN": [],
    "PL": [{ "name": "Greater Poland Voivodeship", "state_code": "WP" },
    { "name": "Kuyavian-Pomeranian Voivodeship", "state_code": "KP" },
    { "name": "Lesser Poland Voivodeship", "state_code": "MA" },
    { "name": "Lower Silesian Voivodeship", "state_code": "DS" },
    { "name": "Lublin Voivodeship", "state_code": "LU" },
    { "name": "Lubusz Voivodeship", "state_code": "LB" },
    { "name": "Łódź Voivodeship", "state_code": "LD" },
    { "name": "Masovian Voivodeship", "state_code": "MZ" },
    { "name": "Opole Voivodeship", "state_code": "OP" },
    { "name": "Podkarpackie Voivodeship", "state_code": "PK" },
    { "name": "Podlaskie Voivodeship", "state_code": "PD" },
    { "name": "Pomeranian Voivodeship", "state_code": "PM" },
    { "name": "Silesian Voivodeship", "state_code": "SL" },
    { "name": "Świętokrzyskie Voivodeship", "state_code": "SK" },
    { "name": "Warmian-Masurian Voivodeship", "state_code": "WN" },
    { "name": "West Pomeranian Voivodeship", "state_code": "ZP" }],
    "PT": [{ "name": "Açores", "state_code": "20" },
    { "name": "Aveiro", "state_code": "01" },
    { "name": "Beja", "state_code": "02" },
    { "name": "Braga", "state_code": "03" },
    { "name": "Bragança", "state_code": "04" },
    { "name": "Castelo Branco", "state_code": "05" },
    { "name": "Coimbra", "state_code": "06" },
    { "name": "Évora", "state_code": "07" },
    { "name": "Faro", "state_code": "08" },
    { "name": "Guarda", "state_code": "09" },
    { "name": "Leiria", "state_code": "10" },
    { "name": "Lisbon", "state_code": "11" },
    { "name": "Madeira", "state_code": "30" },
    { "name": "Portalegre", "state_code": "12" },
    { "name": "Porto", "state_code": "13" },
    { "name": "Santarém", "state_code": "14" },
    { "name": "Setúbal", "state_code": "15" },
    { "name": "Viana do Castelo", "state_code": "16" },
    { "name": "Vila Real", "state_code": "17" },
    { "name": "Viseu", "state_code": "18" }],
    "PR": [],
    "QA": [{ "name": "Al Daayen", "state_code": "ZA" },
    { "name": "Al Khor", "state_code": "KH" },
    { "name": "Al Rayyan Municipality", "state_code": "RA" },
    { "name": "Al Wakrah", "state_code": "WA" },
    { "name": "Al-Shahaniya", "state_code": "SH" },
    { "name": "Doha", "state_code": "DA" },
    { "name": "Madinat ash Shamal", "state_code": "MS" },
    { "name": "Umm Salal Municipality", "state_code": "US" }],
    "RE": [],
    "RO": [{ "name": "Alba", "state_code": "AB" },
    { "name": "Arad County", "state_code": "AR" },
    { "name": "Arges", "state_code": "AG" },
    { "name": "Bacău County", "state_code": "BC" },
    { "name": "Bihor County", "state_code": "BH" },
    { "name": "Bistrița-Năsăud County", "state_code": "BN" },
    { "name": "Botoșani County", "state_code": "BT" },
    { "name": "Braila", "state_code": "BR" },
    { "name": "Brașov County", "state_code": "BV" },
    { "name": "Bucharest", "state_code": "B" },
    { "name": "Buzău County", "state_code": "BZ" },
    { "name": "Călărași County", "state_code": "CL" },
    { "name": "Caraș-Severin County", "state_code": "CS" },
    { "name": "Cluj County", "state_code": "CJ" },
    { "name": "Constanța County", "state_code": "CT" },
    { "name": "Covasna County", "state_code": "CV" },
    { "name": "Dâmbovița County", "state_code": "DB" },
    { "name": "Dolj County", "state_code": "DJ" },
    { "name": "Galați County", "state_code": "GL" },
    { "name": "Giurgiu County", "state_code": "GR" },
    { "name": "Gorj County", "state_code": "GJ" },
    { "name": "Harghita County", "state_code": "HR" },
    { "name": "Hunedoara County", "state_code": "HD" },
    { "name": "Ialomița County", "state_code": "IL" },
    { "name": "Iași County", "state_code": "IS" },
    { "name": "Ilfov County", "state_code": "IF" },
    { "name": "Maramureș County", "state_code": "MM" },
    { "name": "Mehedinți County", "state_code": "MH" },
    { "name": "Mureș County", "state_code": "MS" },
    { "name": "Neamț County", "state_code": "NT" },
    { "name": "Olt County", "state_code": "OT" },
    { "name": "Prahova County", "state_code": "PH" },
    { "name": "Sălaj County", "state_code": "SJ" },
    { "name": "Satu Mare County", "state_code": "SM" },
    { "name": "Sibiu County", "state_code": "SB" },
    { "name": "Suceava County", "state_code": "SV" },
    { "name": "Teleorman County", "state_code": "TR" },
    { "name": "Timiș County", "state_code": "TM" },
    { "name": "Tulcea County", "state_code": "TL" },
    { "name": "Vâlcea County", "state_code": "VL" },
    { "name": "Vaslui County", "state_code": "VS" },
    { "name": "Vrancea County", "state_code": "VN" }],
    "RU": [{ "name": "Altai Krai", "state_code": "ALT" },
    { "name": "Altai Republic", "state_code": "AL" },
    { "name": "Amur Oblast", "state_code": "AMU" },
    { "name": "Arkhangelsk", "state_code": "ARK" },
    { "name": "Astrakhan Oblast", "state_code": "AST" },
    { "name": "Belgorod Oblast", "state_code": "BEL" },
    { "name": "Bryansk Oblast", "state_code": "BRY" },
    { "name": "Chechen Republic", "state_code": "CE" },
    { "name": "Chelyabinsk Oblast", "state_code": "CHE" },
    { "name": "Chukotka Autonomous Okrug", "state_code": "CHU" },
    { "name": "Chuvash Republic", "state_code": "CU" },
    { "name": "Irkutsk", "state_code": "IRK" },
    { "name": "Ivanovo Oblast", "state_code": "IVA" },
    { "name": "Jewish Autonomous Oblast", "state_code": "YEV" },
    { "name": "Kabardino-Balkar Republic", "state_code": "KB" },
    { "name": "Kaliningrad", "state_code": "KGD" },
    { "name": "Kaluga Oblast", "state_code": "KLU" },
    { "name": "Kamchatka Krai", "state_code": "KAM" },
    { "name": "Karachay-Cherkess Republic", "state_code": "KC" },
    { "name": "Kemerovo Oblast", "state_code": "KEM" },
    { "name": "Khabarovsk Krai", "state_code": "KHA" },
    { "name": "Khanty-Mansi Autonomous Okrug", "state_code": "KHM" },
    { "name": "Kirov Oblast", "state_code": "KIR" },
    { "name": "Komi Republic", "state_code": "KO" },
    { "name": "Kostroma Oblast", "state_code": "KOS" },
    { "name": "Krasnodar Krai", "state_code": "KDA" },
    { "name": "Krasnoyarsk Krai", "state_code": "KYA" },
    { "name": "Kurgan Oblast", "state_code": "KGN" },
    { "name": "Kursk Oblast", "state_code": "KRS" },
    { "name": "Leningrad Oblast", "state_code": "LEN" },
    { "name": "Lipetsk Oblast", "state_code": "LIP" },
    { "name": "Magadan Oblast", "state_code": "MAG" },
    { "name": "Mari El Republic", "state_code": "ME" },
    { "name": "Moscow", "state_code": "MOW" },
    { "name": "Moscow Oblast", "state_code": "MOS" },
    { "name": "Murmansk Oblast", "state_code": "MUR" },
    { "name": "Nenets Autonomous Okrug", "state_code": "NEN" },
    { "name": "Nizhny Novgorod Oblast", "state_code": "NIZ" },
    { "name": "Novgorod Oblast", "state_code": "NGR" },
    { "name": "Novosibirsk", "state_code": "NVS" },
    { "name": "Omsk Oblast", "state_code": "OMS" },
    { "name": "Orenburg Oblast", "state_code": "ORE" },
    { "name": "Oryol Oblast", "state_code": "ORL" },
    { "name": "Penza Oblast", "state_code": "PNZ" },
    { "name": "Perm Krai", "state_code": "PER" },
    { "name": "Primorsky Krai", "state_code": "PRI" },
    { "name": "Pskov Oblast", "state_code": "PSK" },
    { "name": "Republic of Adygea", "state_code": "AD" },
    { "name": "Republic of Bashkortostan", "state_code": "BA" },
    { "name": "Republic of Buryatia", "state_code": "BU" },
    { "name": "Republic of Dagestan", "state_code": "DA" },
    { "name": "Republic of Ingushetia", "state_code": "IN" },
    { "name": "Republic of Kalmykia", "state_code": "KL" },
    { "name": "Republic of Karelia", "state_code": "KR" },
    { "name": "Republic of Khakassia", "state_code": "KK" },
    { "name": "Republic of Mordovia", "state_code": "MO" },
    { "name": "Republic of North Ossetia-Alania", "state_code": "SE" },
    { "name": "Republic of Tatarstan", "state_code": "TA" },
    { "name": "Rostov Oblast", "state_code": "ROS" },
    { "name": "Ryazan Oblast", "state_code": "RYA" },
    { "name": "Saint Petersburg", "state_code": "SPE" },
    { "name": "Sakha Republic", "state_code": "SA" },
    { "name": "Sakhalin", "state_code": "SAK" },
    { "name": "Samara Oblast", "state_code": "SAM" },
    { "name": "Saratov Oblast", "state_code": "SAR" },
    { "name": "Sevastopol", "state_code": "UA-40" },
    { "name": "Smolensk Oblast", "state_code": "SMO" },
    { "name": "Stavropol Krai", "state_code": "STA" },
    { "name": "Sverdlovsk", "state_code": "SVE" },
    { "name": "Tambov Oblast", "state_code": "TAM" },
    { "name": "Tomsk Oblast", "state_code": "TOM" },
    { "name": "Tula Oblast", "state_code": "TUL" },
    { "name": "Tuva Republic", "state_code": "TY" },
    { "name": "Tver Oblast", "state_code": "TVE" },
    { "name": "Tyumen Oblast", "state_code": "TYU" },
    { "name": "Udmurt Republic", "state_code": "UD" },
    { "name": "Ulyanovsk Oblast", "state_code": "ULY" },
    { "name": "Vladimir Oblast", "state_code": "VLA" },
    { "name": "Volgograd Oblast", "state_code": "VGG" },
    { "name": "Vologda Oblast", "state_code": "VLG" },
    { "name": "Voronezh Oblast", "state_code": "VOR" },
    { "name": "Yamalo-Nenets Autonomous Okrug", "state_code": "YAN" },
    { "name": "Yaroslavl Oblast", "state_code": "YAR" },
    { "name": "Zabaykalsky Krai", "state_code": "ZAB" }],
    "RW": [{ "name": "Eastern Province", "state_code": "02" },
    { "name": "Kigali district", "state_code": "01" },
    { "name": "Northern Province", "state_code": "03" },
    { "name": "Southern Province", "state_code": "05" },
    { "name": "Western Province", "state_code": "04" }],
    "SH": [],
    "KN": [{ "name": "Christ Church Nichola Town Parish", "state_code": "01" },
    { "name": "Nevis", "state_code": "N" },
    { "name": "Saint Anne Sandy Point Parish", "state_code": "02" },
    { "name": "Saint George Gingerland Parish", "state_code": "04" },
    { "name": "Saint James Windward Parish", "state_code": "05" },
    { "name": "Saint John Capisterre Parish", "state_code": "06" },
    { "name": "Saint John Figtree Parish", "state_code": "07" },
    { "name": "Saint Kitts", "state_code": "K" },
    { "name": "Saint Mary Cayon Parish", "state_code": "08" },
    { "name": "Saint Paul Capisterre Parish", "state_code": "09" },
    { "name": "Saint Paul Charlestown Parish", "state_code": "10" },
    { "name": "Saint Peter Basseterre Parish", "state_code": "11" },
    { "name": "Saint Thomas Lowland Parish", "state_code": "12" },
    { "name": "Saint Thomas Middle Island Parish", "state_code": "13" },
    { "name": "Trinity Palmetto Point Parish", "state_code": "15" }],
    "LC": [{ "name": "Anse la Raye Quarter", "state_code": "01" },
    { "name": "Canaries", "state_code": "12" },
    { "name": "Castries Quarter", "state_code": "02" },
    { "name": "Choiseul Quarter", "state_code": "03" },
    { "name": "Dauphin Quarter", "state_code": "04" },
    { "name": "Dennery Quarter", "state_code": "05" },
    { "name": "Gros Islet Quarter", "state_code": "06" },
    { "name": "Laborie Quarter", "state_code": "07" },
    { "name": "Micoud Quarter", "state_code": "08" },
    { "name": "Praslin Quarter", "state_code": "09" },
    { "name": "Soufrière Quarter", "state_code": "10" },
    { "name": "Vieux Fort Quarter", "state_code": "11" }],
    "PM": [],
    "VC": [{ "name": "Charlotte Parish", "state_code": "01" },
    { "name": "Grenadines Parish", "state_code": "06" },
    { "name": "Saint Andrew Parish", "state_code": "02" },
    { "name": "Saint David Parish", "state_code": "03" },
    { "name": "Saint George Parish", "state_code": "04" },
    { "name": "Saint Patrick Parish", "state_code": "05" }],
    "BL": [],
    "MF": [],
    "WS": [{ "name": "A'ana", "state_code": "AA" },
    { "name": "Aiga-i-le-Tai", "state_code": "AL" },
    { "name": "Atua", "state_code": "AT" },
    { "name": "Fa'asaleleaga", "state_code": "FA" },
    { "name": "Gaga'emauga", "state_code": "GE" },
    { "name": "Gaga'ifomauga", "state_code": "GI" },
    { "name": "Palauli", "state_code": "PA" },
    { "name": "Satupa'itea", "state_code": "SA" },
    { "name": "Tuamasaga", "state_code": "TU" },
    { "name": "Va'a-o-Fonoti", "state_code": "VF" },
    { "name": "Vaisigano", "state_code": "VS" }],
    "SM": [{ "name": "Acquaviva", "state_code": "01" },
    { "name": "Borgo Maggiore", "state_code": "06" },
    { "name": "Chiesanuova", "state_code": "02" },
    { "name": "Domagnano", "state_code": "03" },
    { "name": "Faetano", "state_code": "04" },
    { "name": "Fiorentino", "state_code": "05" },
    { "name": "Montegiardino", "state_code": "08" },
    { "name": "San Marino", "state_code": "07" },
    { "name": "Serravalle", "state_code": "09" }],
    "ST": [{ "name": "Príncipe Province", "state_code": "P" },
    { "name": "São Tomé Province", "state_code": "S" }],
    "SA": [{ "name": "'Asir", "state_code": "14" },
    { "name": "Al Bahah", "state_code": "11" },
    { "name": "Al Jawf", "state_code": "12" },
    { "name": "Al Madinah", "state_code": "03" },
    { "name": "Al-Qassim", "state_code": "05" },
    { "name": "Eastern Province", "state_code": "04" },
    { "name": "Ha'il", "state_code": "06" },
    { "name": "Jizan", "state_code": "09" },
    { "name": "Makkah", "state_code": "02" },
    { "name": "Najran", "state_code": "10" },
    { "name": "Northern Borders", "state_code": "08" },
    { "name": "Riyadh", "state_code": "01" },
    { "name": "Tabuk", "state_code": "07" }],
    "SN": [{ "name": "Dakar", "state_code": "DK" },
    { "name": "Diourbel Region", "state_code": "DB" },
    { "name": "Fatick", "state_code": "FK" },
    { "name": "Kaffrine", "state_code": "KA" },
    { "name": "Kaolack", "state_code": "KL" },
    { "name": "Kédougou", "state_code": "KE" },
    { "name": "Kolda", "state_code": "KD" },
    { "name": "Louga", "state_code": "LG" },
    { "name": "Matam", "state_code": "MT" },
    { "name": "Saint-Louis", "state_code": "SL" },
    { "name": "Sédhiou", "state_code": "SE" },
    { "name": "Tambacounda Region", "state_code": "TC" },
    { "name": "Thiès Region", "state_code": "TH" },
    { "name": "Ziguinchor", "state_code": "ZG" }],
    "RS": [{ "name": "Belgrade", "state_code": "00" },
    { "name": "Bor District", "state_code": "14" },
    { "name": "Braničevo District", "state_code": "11" },
    { "name": "Central Banat District", "state_code": "02" },
    { "name": "Jablanica District", "state_code": "23" },
    { "name": "Kolubara District", "state_code": "09" },
    { "name": "Mačva District", "state_code": "08" },
    { "name": "Moravica District", "state_code": "17" },
    { "name": "Nišava District", "state_code": "20" },
    { "name": "North Bačka District", "state_code": "01" },
    { "name": "North Banat District", "state_code": "03" },
    { "name": "Pčinja District", "state_code": "24" },
    { "name": "Pirot District", "state_code": "22" },
    { "name": "Podunavlje District", "state_code": "10" },
    { "name": "Pomoravlje District", "state_code": "13" },
    { "name": "Rasina District", "state_code": "19" },
    { "name": "Raška District", "state_code": "18" },
    { "name": "South Bačka District", "state_code": "06" },
    { "name": "South Banat District", "state_code": "04" },
    { "name": "Srem District", "state_code": "07" },
    { "name": "Šumadija District", "state_code": "12" },
    { "name": "Toplica District", "state_code": "21" },
    { "name": "Vojvodina", "state_code": "VO" },
    { "name": "West Bačka District", "state_code": "05" },
    { "name": "Zaječar District", "state_code": "15" },
    { "name": "Zlatibor District", "state_code": "16" }],
    "SC": [{ "name": "Anse Boileau", "state_code": "02" },
    { "name": "Anse Royale", "state_code": "05" },
    { "name": "Anse-aux-Pins", "state_code": "01" },
    { "name": "Au Cap", "state_code": "04" },
    { "name": "Baie Lazare", "state_code": "06" },
    { "name": "Baie Sainte Anne", "state_code": "07" },
    { "name": "Beau Vallon", "state_code": "08" },
    { "name": "Bel Air", "state_code": "09" },
    { "name": "Bel Ombre", "state_code": "10" },
    { "name": "Cascade", "state_code": "11" },
    { "name": "Glacis", "state_code": "12" },
    { "name": "Grand'Anse Mahé", "state_code": "13" },
    { "name": "Grand'Anse Praslin", "state_code": "14" },
    { "name": "La Digue", "state_code": "15" },
    { "name": "La Rivière Anglaise", "state_code": "16" },
    { "name": "Les Mamelles", "state_code": "24" },
    { "name": "Mont Buxton", "state_code": "17" },
    { "name": "Mont Fleuri", "state_code": "18" },
    { "name": "Plaisance", "state_code": "19" },
    { "name": "Pointe La Rue", "state_code": "20" },
    { "name": "Port Glaud", "state_code": "21" },
    { "name": "Roche Caiman", "state_code": "25" },
    { "name": "Saint Louis", "state_code": "22" },
    { "name": "Takamaka", "state_code": "23" }],
    "SL": [{ "name": "Eastern Province", "state_code": "E" },
    { "name": "Northern Province", "state_code": "N" },
    { "name": "Southern Province", "state_code": "S" },
    { "name": "Western Area", "state_code": "W" }],
    "SG": [{ "name": "Central Singapore Community Development Council", "state_code": "01" },
    { "name": "North East Community Development Council", "state_code": "02" },
    { "name": "North West Community Development Council", "state_code": "03" },
    { "name": "South East Community Development Council", "state_code": "04" },
    { "name": "South West Community Development Council", "state_code": "05" }],
    "SX": [],
    "SK": [{ "name": "Banská Bystrica Region", "state_code": "BC" },
    { "name": "Bratislava Region", "state_code": "BL" },
    { "name": "Košice Region", "state_code": "KI" },
    { "name": "Nitra Region", "state_code": "NI" },
    { "name": "Prešov Region", "state_code": "PV" },
    { "name": "Trenčín Region", "state_code": "TC" },
    { "name": "Trnava Region", "state_code": "TA" },
    { "name": "Žilina Region", "state_code": "ZI" }],
    "SI": [{ "name": "Ajdovščina Municipality", "state_code": "001" },
    { "name": "Ankaran Municipality", "state_code": "213" },
    { "name": "Beltinci Municipality", "state_code": "002" },
    { "name": "Benedikt Municipality", "state_code": "148" },
    { "name": "Bistrica ob Sotli Municipality", "state_code": "149" },
    { "name": "Bled Municipality", "state_code": "003" },
    { "name": "Bloke Municipality", "state_code": "150" },
    { "name": "Bohinj Municipality", "state_code": "004" },
    { "name": "Borovnica Municipality", "state_code": "005" },
    { "name": "Bovec Municipality", "state_code": "006" },
    { "name": "Braslovče Municipality", "state_code": "151" },
    { "name": "Brda Municipality", "state_code": "007" },
    { "name": "Brežice Municipality", "state_code": "009" },
    { "name": "Brezovica Municipality", "state_code": "008" },
    { "name": "Cankova Municipality", "state_code": "152" },
    { "name": "Cerklje na Gorenjskem Municipality", "state_code": "012" },
    { "name": "Cerknica Municipality", "state_code": "013" },
    { "name": "Cerkno Municipality", "state_code": "014" },
    { "name": "Cerkvenjak Municipality", "state_code": "153" },
    { "name": "City Municipality of Celje", "state_code": "011" },
    { "name": "City Municipality of Novo Mesto", "state_code": "085" },
    { "name": "Črenšovci Municipality", "state_code": "015" },
    { "name": "Črna na Koroškem Municipality", "state_code": "016" },
    { "name": "Črnomelj Municipality", "state_code": "017" },
    { "name": "Destrnik Municipality", "state_code": "018" },
    { "name": "Divača Municipality", "state_code": "019" },
    { "name": "Dobje Municipality", "state_code": "154" },
    { "name": "Dobrepolje Municipality", "state_code": "020" },
    { "name": "Dobrna Municipality", "state_code": "155" },
    { "name": "Dobrova–Polhov Gradec Municipality", "state_code": "021" },
    { "name": "Dobrovnik Municipality", "state_code": "156" },
    { "name": "Dol pri Ljubljani Municipality", "state_code": "022" },
    { "name": "Dolenjske Toplice Municipality", "state_code": "157" },
    { "name": "Domžale Municipality", "state_code": "023" },
    { "name": "Dornava Municipality", "state_code": "024" },
    { "name": "Dravograd Municipality", "state_code": "025" },
    { "name": "Duplek Municipality", "state_code": "026" },
    { "name": "Gorenja Vas–Poljane Municipality", "state_code": "027" },
    { "name": "Gorišnica Municipality", "state_code": "028" },
    { "name": "Gorje Municipality", "state_code": "207" },
    { "name": "Gornja Radgona Municipality", "state_code": "029" },
    { "name": "Gornji Grad Municipality", "state_code": "030" },
    { "name": "Gornji Petrovci Municipality", "state_code": "031" },
    { "name": "Grad Municipality", "state_code": "158" },
    { "name": "Grosuplje Municipality", "state_code": "032" },
    { "name": "Hajdina Municipality", "state_code": "159" },
    { "name": "Hoče–Slivnica Municipality", "state_code": "160" },
    { "name": "Hodoš Municipality", "state_code": "161" },
    { "name": "Horjul Municipality", "state_code": "162" },
    { "name": "Hrastnik Municipality", "state_code": "034" },
    { "name": "Hrpelje–Kozina Municipality", "state_code": "035" },
    { "name": "Idrija Municipality", "state_code": "036" },
    { "name": "Ig Municipality", "state_code": "037" },
    { "name": "Ivančna Gorica Municipality", "state_code": "039" },
    { "name": "Izola Municipality", "state_code": "040" },
    { "name": "Jesenice Municipality", "state_code": "041" },
    { "name": "Jezersko Municipality", "state_code": "163" },
    { "name": "Juršinci Municipality", "state_code": "042" },
    { "name": "Kamnik Municipality", "state_code": "043" },
    { "name": "Kanal ob Soči Municipality", "state_code": "044" },
    { "name": "Kidričevo Municipality", "state_code": "045" },
    { "name": "Kobarid Municipality", "state_code": "046" },
    { "name": "Kobilje Municipality", "state_code": "047" },
    { "name": "Kočevje Municipality", "state_code": "048" },
    { "name": "Komen Municipality", "state_code": "049" },
    { "name": "Komenda Municipality", "state_code": "164" },
    { "name": "Koper City Municipality", "state_code": "050" },
    { "name": "Kostanjevica na Krki Municipality", "state_code": "197" },
    { "name": "Kostel Municipality", "state_code": "165" },
    { "name": "Kozje Municipality", "state_code": "051" },
    { "name": "Kranj City Municipality", "state_code": "052" },
    { "name": "Kranjska Gora Municipality", "state_code": "053" },
    { "name": "Križevci Municipality", "state_code": "166" },
    { "name": "Kungota", "state_code": "055" },
    { "name": "Kuzma Municipality", "state_code": "056" },
    { "name": "Laško Municipality", "state_code": "057" },
    { "name": "Lenart Municipality", "state_code": "058" },
    { "name": "Lendava Municipality", "state_code": "059" },
    { "name": "Litija Municipality", "state_code": "060" },
    { "name": "Ljubljana City Municipality", "state_code": "061" },
    { "name": "Ljubno Municipality", "state_code": "062" },
    { "name": "Ljutomer Municipality", "state_code": "063" },
    { "name": "Log–Dragomer Municipality", "state_code": "208" },
    { "name": "Logatec Municipality", "state_code": "064" },
    { "name": "Loška Dolina Municipality", "state_code": "065" },
    { "name": "Loški Potok Municipality", "state_code": "066" },
    { "name": "Lovrenc na Pohorju Municipality", "state_code": "167" },
    { "name": "Luče Municipality", "state_code": "067" },
    { "name": "Lukovica Municipality", "state_code": "068" },
    { "name": "Majšperk Municipality", "state_code": "069" },
    { "name": "Makole Municipality", "state_code": "198" },
    { "name": "Maribor City Municipality", "state_code": "070" },
    { "name": "Markovci Municipality", "state_code": "168" },
    { "name": "Medvode Municipality", "state_code": "071" },
    { "name": "Mengeš Municipality", "state_code": "072" },
    { "name": "Metlika Municipality", "state_code": "073" },
    { "name": "Mežica Municipality", "state_code": "074" },
    { "name": "Miklavž na Dravskem Polju Municipality", "state_code": "169" },
    { "name": "Miren–Kostanjevica Municipality", "state_code": "075" },
    { "name": "Mirna Municipality", "state_code": "212" },
    { "name": "Mirna Peč Municipality", "state_code": "170" },
    { "name": "Mislinja Municipality", "state_code": "076" },
    { "name": "Mokronog–Trebelno Municipality", "state_code": "199" },
    { "name": "Moravče Municipality", "state_code": "077" },
    { "name": "Moravske Toplice Municipality", "state_code": "078" },
    { "name": "Mozirje Municipality", "state_code": "079" },
    { "name": "Municipality of Apače", "state_code": "195" },
    { "name": "Municipality of Cirkulane", "state_code": "196" },
    { "name": "Municipality of Ilirska Bistrica", "state_code": "038" },
    { "name": "Municipality of Krško", "state_code": "054" },
    { "name": "Municipality of Škofljica", "state_code": "123" },
    { "name": "Murska Sobota City Municipality", "state_code": "080" },
    { "name": "Muta Municipality", "state_code": "081" },
    { "name": "Naklo Municipality", "state_code": "082" },
    { "name": "Nazarje Municipality", "state_code": "083" },
    { "name": "Nova Gorica City Municipality", "state_code": "084" },
    { "name": "Odranci Municipality", "state_code": "086" },
    { "name": "Oplotnica", "state_code": "171" },
    { "name": "Ormož Municipality", "state_code": "087" },
    { "name": "Osilnica Municipality", "state_code": "088" },
    { "name": "Pesnica Municipality", "state_code": "089" },
    { "name": "Piran Municipality", "state_code": "090" },
    { "name": "Pivka Municipality", "state_code": "091" },
    { "name": "Podčetrtek Municipality", "state_code": "092" },
    { "name": "Podlehnik Municipality", "state_code": "172" },
    { "name": "Podvelka Municipality", "state_code": "093" },
    { "name": "Poljčane Municipality", "state_code": "200" },
    { "name": "Polzela Municipality", "state_code": "173" },
    { "name": "Postojna Municipality", "state_code": "094" },
    { "name": "Prebold Municipality", "state_code": "174" },
    { "name": "Preddvor Municipality", "state_code": "095" },
    { "name": "Prevalje Municipality", "state_code": "175" },
    { "name": "Ptuj City Municipality", "state_code": "096" },
    { "name": "Puconci Municipality", "state_code": "097" },
    { "name": "Rače–Fram Municipality", "state_code": "098" },
    { "name": "Radeče Municipality", "state_code": "099" },
    { "name": "Radenci Municipality", "state_code": "100" },
    { "name": "Radlje ob Dravi Municipality", "state_code": "101" },
    { "name": "Radovljica Municipality", "state_code": "102" },
    { "name": "Ravne na Koroškem Municipality", "state_code": "103" },
    { "name": "Razkrižje Municipality", "state_code": "176" },
    { "name": "Rečica ob Savinji Municipality", "state_code": "209" },
    { "name": "Renče–Vogrsko Municipality", "state_code": "201" },
    { "name": "Ribnica Municipality", "state_code": "104" },
    { "name": "Ribnica na Pohorju Municipality", "state_code": "177" },
    { "name": "Rogaška Slatina Municipality", "state_code": "106" },
    { "name": "Rogašovci Municipality", "state_code": "105" },
    { "name": "Rogatec Municipality", "state_code": "107" },
    { "name": "Ruše Municipality", "state_code": "108" },
    { "name": "Šalovci Municipality", "state_code": "033" },
    { "name": "Selnica ob Dravi Municipality", "state_code": "178" },
    { "name": "Semič Municipality", "state_code": "109" },
    { "name": "Šempeter–Vrtojba Municipality", "state_code": "183" },
    { "name": "Šenčur Municipality", "state_code": "117" },
    { "name": "Šentilj Municipality", "state_code": "118" },
    { "name": "Šentjernej Municipality", "state_code": "119" },
    { "name": "Šentjur Municipality", "state_code": "120" },
    { "name": "Šentrupert Municipality", "state_code": "211" },
    { "name": "Sevnica Municipality", "state_code": "110" },
    { "name": "Sežana Municipality", "state_code": "111" },
    { "name": "Škocjan Municipality", "state_code": "121" },
    { "name": "Škofja Loka Municipality", "state_code": "122" },
    { "name": "Slovenj Gradec City Municipality", "state_code": "112" },
    { "name": "Slovenska Bistrica Municipality", "state_code": "113" },
    { "name": "Slovenske Konjice Municipality", "state_code": "114" },
    { "name": "Šmarje pri Jelšah Municipality", "state_code": "124" },
    { "name": "Šmarješke Toplice Municipality", "state_code": "206" },
    { "name": "Šmartno ob Paki Municipality", "state_code": "125" },
    { "name": "Šmartno pri Litiji Municipality", "state_code": "194" },
    { "name": "Sodražica Municipality", "state_code": "179" },
    { "name": "Solčava Municipality", "state_code": "180" },
    { "name": "Šoštanj Municipality", "state_code": "126" },
    { "name": "Središče ob Dravi", "state_code": "202" },
    { "name": "Starše Municipality", "state_code": "115" },
    { "name": "Štore Municipality", "state_code": "127" },
    { "name": "Straža Municipality", "state_code": "203" },
    { "name": "Sveta Ana Municipality", "state_code": "181" },
    { "name": "Sveta Trojica v Slovenskih Goricah Municipality", "state_code": "204" },
    { "name": "Sveti Andraž v Slovenskih Goricah Municipality", "state_code": "182" },
    { "name": "Sveti Jurij ob Ščavnici Municipality", "state_code": "116" },
    { "name": "Sveti Jurij v Slovenskih Goricah Municipality", "state_code": "210" },
    { "name": "Sveti Tomaž Municipality", "state_code": "205" },
    { "name": "Tabor Municipality", "state_code": "184" },
    { "name": "Tišina Municipality", "state_code": "010" },
    { "name": "Tolmin Municipality", "state_code": "128" },
    { "name": "Trbovlje Municipality", "state_code": "129" },
    { "name": "Trebnje Municipality", "state_code": "130" },
    { "name": "Trnovska Vas Municipality", "state_code": "185" },
    { "name": "Tržič Municipality", "state_code": "131" },
    { "name": "Trzin Municipality", "state_code": "186" },
    { "name": "Turnišče Municipality", "state_code": "132" },
    { "name": "Velika Polana Municipality", "state_code": "187" },
    { "name": "Velike Lašče Municipality", "state_code": "134" },
    { "name": "Veržej Municipality", "state_code": "188" },
    { "name": "Videm Municipality", "state_code": "135" },
    { "name": "Vipava Municipality", "state_code": "136" },
    { "name": "Vitanje Municipality", "state_code": "137" },
    { "name": "Vodice Municipality", "state_code": "138" },
    { "name": "Vojnik Municipality", "state_code": "139" },
    { "name": "Vransko Municipality", "state_code": "189" },
    { "name": "Vrhnika Municipality", "state_code": "140" },
    { "name": "Vuzenica Municipality", "state_code": "141" },
    { "name": "Zagorje ob Savi Municipality", "state_code": "142" },
    { "name": "Žalec Municipality", "state_code": "190" },
    { "name": "Zavrč Municipality", "state_code": "143" },
    { "name": "Železniki Municipality", "state_code": "146" },
    { "name": "Žetale Municipality", "state_code": "191" },
    { "name": "Žiri Municipality", "state_code": "147" },
    { "name": "Žirovnica Municipality", "state_code": "192" },
    { "name": "Zreče Municipality", "state_code": "144" },
    { "name": "Žužemberk Municipality", "state_code": "193" }],
    "SB": [{ "name": "Central Province", "state_code": "CE" },
    { "name": "Choiseul Province", "state_code": "CH" },
    { "name": "Guadalcanal Province", "state_code": "GU" },
    { "name": "Honiara", "state_code": "CT" },
    { "name": "Isabel Province", "state_code": "IS" },
    { "name": "Makira-Ulawa Province", "state_code": "MK" },
    { "name": "Malaita Province", "state_code": "ML" },
    { "name": "Rennell and Bellona Province", "state_code": "RB" },
    { "name": "Temotu Province", "state_code": "TE" },
    { "name": "Western Province", "state_code": "WE" }],
    "SO": [{ "name": "Awdal Region", "state_code": "AW" },
    { "name": "Bakool", "state_code": "BK" },
    { "name": "Banaadir", "state_code": "BN" },
    { "name": "Bari", "state_code": "BR" },
    { "name": "Bay", "state_code": "BY" },
    { "name": "Galguduud", "state_code": "GA" },
    { "name": "Gedo", "state_code": "GE" },
    { "name": "Hiran", "state_code": "HI" },
    { "name": "Lower Juba", "state_code": "JH" },
    { "name": "Lower Shebelle", "state_code": "SH" },
    { "name": "Middle Juba", "state_code": "JD" },
    { "name": "Middle Shebelle", "state_code": "SD" },
    { "name": "Mudug", "state_code": "MU" },
    { "name": "Nugal", "state_code": "NU" },
    { "name": "Sanaag Region", "state_code": "SA" },
    { "name": "Togdheer Region", "state_code": "TO" }],
    "ZA": [{ "name": "Eastern Cape", "state_code": "EC" },
    { "name": "Free State", "state_code": "FS" },
    { "name": "Gauteng", "state_code": "GP" },
    { "name": "KwaZulu-Natal", "state_code": "KZN" },
    { "name": "Limpopo", "state_code": "LP" },
    { "name": "Mpumalanga", "state_code": "MP" },
    { "name": "North West", "state_code": "NW" },
    { "name": "Northern Cape", "state_code": "NC" },
    { "name": "Western Cape", "state_code": "WC" }],
    "GS": [],
    "KR": [{ "name": "Busan", "state_code": "26" },
    { "name": "Daegu", "state_code": "27" },
    { "name": "Daejeon", "state_code": "30" },
    { "name": "Gangwon Province", "state_code": "42" },
    { "name": "Gwangju", "state_code": "29" },
    { "name": "Gyeonggi Province", "state_code": "41" },
    { "name": "Incheon", "state_code": "28" },
    { "name": "Jeju", "state_code": "49" },
    { "name": "North Chungcheong Province", "state_code": "43" },
    { "name": "North Gyeongsang Province", "state_code": "47" },
    { "name": "North Jeolla Province", "state_code": "45" },
    { "name": "Sejong City", "state_code": "50" },
    { "name": "Seoul", "state_code": "11" },
    { "name": "South Chungcheong Province", "state_code": "44" },
    { "name": "South Gyeongsang Province", "state_code": "48" },
    { "name": "South Jeolla Province", "state_code": "46" },
    { "name": "Ulsan", "state_code": "31" }],
    "SS": [{ "name": "Central Equatoria", "state_code": "EC" },
    { "name": "Eastern Equatoria", "state_code": "EE" },
    { "name": "Jonglei State", "state_code": "JG" },
    { "name": "Lakes", "state_code": "LK" },
    { "name": "Northern Bahr el Ghazal", "state_code": "BN" },
    { "name": "Unity", "state_code": "UY" },
    { "name": "Upper Nile", "state_code": "NU" },
    { "name": "Warrap", "state_code": "WR" },
    { "name": "Western Bahr el Ghazal", "state_code": "BW" },
    { "name": "Western Equatoria", "state_code": "EW" }],
    "ES": [{ "name": "Andalusia", "state_code": "AN" },
    { "name": "Aragon", "state_code": "AR" },
    { "name": "Asturias", "state_code": "AS" },
    { "name": "Ávila", "state_code": "AV" },
    { "name": "Balearic Islands", "state_code": "PM" },
    { "name": "Basque Country", "state_code": "PV" },
    { "name": "Burgos Province", "state_code": "BU" },
    { "name": "Canary Islands", "state_code": "CN" },
    { "name": "Cantabria", "state_code": "CB" },
    { "name": "Castile and León", "state_code": "CL" },
    { "name": "Castilla La Mancha", "state_code": "CM" },
    { "name": "Catalonia", "state_code": "CT" },
    { "name": "Ceuta", "state_code": "CE" },
    { "name": "Extremadura", "state_code": "EX" },
    { "name": "Galicia", "state_code": "GA" },
    { "name": "La Rioja", "state_code": "RI" },
    { "name": "Léon", "state_code": "LE" },
    { "name": "Madrid", "state_code": "MD" },
    { "name": "Melilla", "state_code": "ML" },
    { "name": "Murcia", "state_code": "MC" },
    { "name": "Navarra", "state_code": "NC" },
    { "name": "Palencia Province", "state_code": "P" },
    { "name": "Salamanca Province", "state_code": "SA" },
    { "name": "Segovia Province", "state_code": "SG" },
    { "name": "Soria Province", "state_code": "SO" },
    { "name": "Valencia", "state_code": "VC" },
    { "name": "Valladolid Province", "state_code": "VA" },
    { "name": "Zamora Province", "state_code": "ZA" }],
    "LK": [{ "name": "Ampara District", "state_code": "52" },
    { "name": "Anuradhapura District", "state_code": "71" },
    { "name": "Badulla District", "state_code": "81" },
    { "name": "Batticaloa District", "state_code": "51" },
    { "name": "Central Province", "state_code": "2" },
    { "name": "Colombo District", "state_code": "11" },
    { "name": "Eastern Province", "state_code": "5" },
    { "name": "Galle District", "state_code": "31" },
    { "name": "Gampaha District", "state_code": "12" },
    { "name": "Hambantota District", "state_code": "33" },
    { "name": "Jaffna District", "state_code": "41" },
    { "name": "Kalutara District", "state_code": "13" },
    { "name": "Kandy District", "state_code": "21" },
    { "name": "Kegalle District", "state_code": "92" },
    { "name": "Kilinochchi District", "state_code": "42" },
    { "name": "Mannar District", "state_code": "43" },
    { "name": "Matale District", "state_code": "22" },
    { "name": "Matara District", "state_code": "32" },
    { "name": "Monaragala District", "state_code": "82" },
    { "name": "Mullaitivu District", "state_code": "45" },
    { "name": "North Central Province", "state_code": "7" },
    { "name": "North Western Province", "state_code": "6" },
    { "name": "Northern Province", "state_code": "4" },
    { "name": "Nuwara Eliya District", "state_code": "23" },
    { "name": "Polonnaruwa District", "state_code": "72" },
    { "name": "Puttalam District", "state_code": "62" },
    { "name": "Ratnapura district", "state_code": "91" },
    { "name": "Sabaragamuwa Province", "state_code": "9" },
    { "name": "Southern Province", "state_code": "3" },
    { "name": "Trincomalee District", "state_code": "53" },
    { "name": "Uva Province", "state_code": "8" },
    { "name": "Vavuniya District", "state_code": "44" },
    { "name": "Western Province", "state_code": "1" }],
    "SD": [{ "name": "Al Jazirah", "state_code": "GZ" },
    { "name": "Al Qadarif", "state_code": "GD" },
    { "name": "Blue Nile", "state_code": "NB" },
    { "name": "Central Darfur", "state_code": "DC" },
    { "name": "East Darfur", "state_code": "DE" },
    { "name": "Kassala", "state_code": "KA" },
    { "name": "Khartoum", "state_code": "KH" },
    { "name": "North Darfur", "state_code": "DN" },
    { "name": "North Kordofan", "state_code": "KN" },
    { "name": "Northern", "state_code": "NO" },
    { "name": "Red Sea", "state_code": "RS" },
    { "name": "River Nile", "state_code": "NR" },
    { "name": "Sennar", "state_code": "SI" },
    { "name": "South Darfur", "state_code": "DS" },
    { "name": "South Kordofan", "state_code": "KS" },
    { "name": "West Darfur", "state_code": "DW" },
    { "name": "West Kordofan", "state_code": "GK" },
    { "name": "White Nile", "state_code": "NW" }],
    "SR": [{ "name": "Brokopondo District", "state_code": "BR" },
    { "name": "Commewijne District", "state_code": "CM" },
    { "name": "Coronie District", "state_code": "CR" },
    { "name": "Marowijne District", "state_code": "MA" },
    { "name": "Nickerie District", "state_code": "NI" },
    { "name": "Para District", "state_code": "PR" },
    { "name": "Paramaribo District", "state_code": "PM" },
    { "name": "Saramacca District", "state_code": "SA" },
    { "name": "Sipaliwini District", "state_code": "SI" },
    { "name": "Wanica District", "state_code": "WA" }],
    "SJ": [],
    "SZ": [{ "name": "Hhohho District", "state_code": "HH" },
    { "name": "Lubombo District", "state_code": "LU" },
    { "name": "Manzini District", "state_code": "MA" },
    { "name": "Shiselweni District", "state_code": "SH" }],
    "SE": [{ "name": "Blekinge", "state_code": "K" },
    { "name": "Dalarna County", "state_code": "W" },
    { "name": "Gävleborg County", "state_code": "X" },
    { "name": "Gotland County", "state_code": "I" },
    { "name": "Halland County", "state_code": "N" },
    { "name": "Jönköping County", "state_code": "F" },
    { "name": "Kalmar County", "state_code": "H" },
    { "name": "Kronoberg County", "state_code": "G" },
    { "name": "Norrbotten County", "state_code": "BD" },
    { "name": "Örebro County", "state_code": "T" },
    { "name": "Östergötland County", "state_code": "E" },
    { "name": "Skåne County", "state_code": "M" },
    { "name": "Södermanland County", "state_code": "D" },
    { "name": "Stockholm County", "state_code": "AB" },
    { "name": "Uppsala County", "state_code": "C" },
    { "name": "Värmland County", "state_code": "S" },
    { "name": "Västerbotten County", "state_code": "AC" },
    { "name": "Västernorrland County", "state_code": "Y" },
    { "name": "Västmanland County", "state_code": "U" },
    { "name": "Västra Götaland County", "state_code": "O" }],
    "CH": [{ "name": "Aargau", "state_code": "AG" },
    { "name": "Appenzell Ausserrhoden", "state_code": "AR" },
    { "name": "Appenzell Innerrhoden", "state_code": "AI" },
    { "name": "Basel-Land", "state_code": "BL" },
    { "name": "Basel-Stadt", "state_code": "BS" },
    { "name": "Bern", "state_code": "BE" },
    { "name": "Fribourg", "state_code": "FR" },
    { "name": "Geneva", "state_code": "GE" },
    { "name": "Glarus", "state_code": "GL" },
    { "name": "Graubünden", "state_code": "GR" },
    { "name": "Jura", "state_code": "JU" },
    { "name": "Lucerne", "state_code": "LU" },
    { "name": "Neuchâtel", "state_code": "NE" },
    { "name": "Nidwalden", "state_code": "NW" },
    { "name": "Obwalden", "state_code": "OW" },
    { "name": "Schaffhausen", "state_code": "SH" },
    { "name": "Schwyz", "state_code": "SZ" },
    { "name": "Solothurn", "state_code": "SO" },
    { "name": "St. Gallen", "state_code": "SG" },
    { "name": "Thurgau", "state_code": "TG" },
    { "name": "Ticino", "state_code": "TI" },
    { "name": "Uri", "state_code": "UR" },
    { "name": "Valais", "state_code": "VS" },
    { "name": "Vaud", "state_code": "VD" },
    { "name": "Zug", "state_code": "ZG" },
    { "name": "Zürich", "state_code": "ZH" }],
    "SY": [{ "name": "Al-Hasakah Governorate", "state_code": "HA" },
    { "name": "Al-Raqqah Governorate", "state_code": "RA" },
    { "name": "Aleppo Governorate", "state_code": "HL" },
    { "name": "As-Suwayda Governorate", "state_code": "SU" },
    { "name": "Damascus Governorate", "state_code": "DI" },
    { "name": "Daraa Governorate", "state_code": "DR" },
    { "name": "Deir ez-Zor Governorate", "state_code": "DY" },
    { "name": "Hama Governorate", "state_code": "HM" },
    { "name": "Homs Governorate", "state_code": "HI" },
    { "name": "Idlib Governorate", "state_code": "ID" },
    { "name": "Latakia Governorate", "state_code": "LA" },
    { "name": "Quneitra Governorate", "state_code": "QU" },
    { "name": "Rif Dimashq Governorate", "state_code": "RD" },
    { "name": "Tartus Governorate", "state_code": "TA" }],
    "TW": [{ "name": "Changhua", "state_code": "CHA" },
    { "name": "Chiayi", "state_code": "CYI" },
    { "name": "Chiayi", "state_code": "CYQ" },
    { "name": "Hsinchu", "state_code": "HSQ" },
    { "name": "Hsinchu", "state_code": "HSZ" },
    { "name": "Hualien", "state_code": "HUA" },
    { "name": "Kaohsiung", "state_code": "KHH" },
    { "name": "Keelung", "state_code": "KEE" },
    { "name": "Kinmen", "state_code": "KIN" },
    { "name": "Lienchiang", "state_code": "LIE" },
    { "name": "Miaoli", "state_code": "MIA" },
    { "name": "Nantou", "state_code": "NAN" },
    { "name": "New Taipei", "state_code": "NWT" },
    { "name": "Penghu", "state_code": "PEN" },
    { "name": "Pingtung", "state_code": "PIF" },
    { "name": "Taichung", "state_code": "TXG" },
    { "name": "Tainan", "state_code": "TNN" },
    { "name": "Taipei", "state_code": "TPE" },
    { "name": "Taitung", "state_code": "TTT" },
    { "name": "Taoyuan", "state_code": "TAO" },
    { "name": "Yilan", "state_code": "ILA" },
    { "name": "Yunlin", "state_code": "YUN" }],
    "TJ": [{ "name": "districts of Republican Subordination", "state_code": "RA" },
    { "name": "Gorno-Badakhshan Autonomous Province", "state_code": "GB" },
    { "name": "Khatlon Province", "state_code": "KT" },
    { "name": "Sughd Province", "state_code": "SU" }],
    "TZ": [{ "name": "Arusha", "state_code": "01" },
    { "name": "Dar es Salaam", "state_code": "02" },
    { "name": "Dodoma", "state_code": "03" },
    { "name": "Geita", "state_code": "27" },
    { "name": "Iringa", "state_code": "04" },
    { "name": "Kagera", "state_code": "05" },
    { "name": "Katavi", "state_code": "28" },
    { "name": "Kigoma", "state_code": "08" },
    { "name": "Kilimanjaro", "state_code": "09" },
    { "name": "Lindi", "state_code": "12" },
    { "name": "Manyara", "state_code": "26" },
    { "name": "Mara", "state_code": "13" },
    { "name": "Mbeya", "state_code": "14" },
    { "name": "Morogoro", "state_code": "16" },
    { "name": "Mtwara", "state_code": "17" },
    { "name": "Mwanza", "state_code": "18" },
    { "name": "Njombe", "state_code": "29" },
    { "name": "Pemba North", "state_code": "06" },
    { "name": "Pemba South", "state_code": "10" },
    { "name": "Pwani", "state_code": "19" },
    { "name": "Rukwa", "state_code": "20" },
    { "name": "Ruvuma", "state_code": "21" },
    { "name": "Shinyanga", "state_code": "22" },
    { "name": "Simiyu", "state_code": "30" },
    { "name": "Singida", "state_code": "23" },
    { "name": "Songwe", "state_code": "31" },
    { "name": "Tabora", "state_code": "24" },
    { "name": "Tanga", "state_code": "25" },
    { "name": "Zanzibar North", "state_code": "07" },
    { "name": "Zanzibar South", "state_code": "11" },
    { "name": "Zanzibar West", "state_code": "15" }],
    "TH": [{ "name": "Amnat Charoen", "state_code": "37" },
    { "name": "Ang Thong", "state_code": "15" },
    { "name": "Bangkok", "state_code": "10" },
    { "name": "Bueng Kan", "state_code": "38" },
    { "name": "Buri Ram", "state_code": "31" },
    { "name": "Chachoengsao", "state_code": "24" },
    { "name": "Chai Nat", "state_code": "18" },
    { "name": "Chaiyaphum", "state_code": "36" },
    { "name": "Chanthaburi", "state_code": "22" },
    { "name": "Chiang Mai", "state_code": "50" },
    { "name": "Chiang Rai", "state_code": "57" },
    { "name": "Chon Buri", "state_code": "20" },
    { "name": "Chumphon", "state_code": "86" },
    { "name": "Kalasin", "state_code": "46" },
    { "name": "Kamphaeng Phet", "state_code": "62" },
    { "name": "Kanchanaburi", "state_code": "71" },
    { "name": "Khon Kaen", "state_code": "40" },
    { "name": "Krabi", "state_code": "81" },
    { "name": "Lampang", "state_code": "52" },
    { "name": "Lamphun", "state_code": "51" },
    { "name": "Loei", "state_code": "42" },
    { "name": "Lop Buri", "state_code": "16" },
    { "name": "Mae Hong Son", "state_code": "58" },
    { "name": "Maha Sarakham", "state_code": "44" },
    { "name": "Mukdahan", "state_code": "49" },
    { "name": "Nakhon Nayok", "state_code": "26" },
    { "name": "Nakhon Pathom", "state_code": "73" },
    { "name": "Nakhon Phanom", "state_code": "48" },
    { "name": "Nakhon Ratchasima", "state_code": "30" },
    { "name": "Nakhon Sawan", "state_code": "60" },
    { "name": "Nakhon Si Thammarat", "state_code": "80" },
    { "name": "Nan", "state_code": "55" },
    { "name": "Narathiwat", "state_code": "96" },
    { "name": "Nong Bua Lam Phu", "state_code": "39" },
    { "name": "Nong Khai", "state_code": "43" },
    { "name": "Nonthaburi", "state_code": "12" },
    { "name": "Pathum Thani", "state_code": "13" },
    { "name": "Pattani", "state_code": "94" },
    { "name": "Pattaya", "state_code": "S" },
    { "name": "Phangnga", "state_code": "82" },
    { "name": "Phatthalung", "state_code": "93" },
    { "name": "Phayao", "state_code": "56" },
    { "name": "Phetchabun", "state_code": "67" },
    { "name": "Phetchaburi", "state_code": "76" },
    { "name": "Phichit", "state_code": "66" },
    { "name": "Phitsanulok", "state_code": "65" },
    { "name": "Phra Nakhon Si Ayutthaya", "state_code": "14" },
    { "name": "Phrae", "state_code": "54" },
    { "name": "Phuket", "state_code": "83" },
    { "name": "Prachin Buri", "state_code": "25" },
    { "name": "Prachuap Khiri Khan", "state_code": "77" },
    { "name": "Ranong", "state_code": "85" },
    { "name": "Ratchaburi", "state_code": "70" },
    { "name": "Rayong", "state_code": "21" },
    { "name": "Roi Et", "state_code": "45" },
    { "name": "Sa Kaeo", "state_code": "27" },
    { "name": "Sakon Nakhon", "state_code": "47" },
    { "name": "Samut Prakan", "state_code": "11" },
    { "name": "Samut Sakhon", "state_code": "74" },
    { "name": "Samut Songkhram", "state_code": "75" },
    { "name": "Saraburi", "state_code": "19" },
    { "name": "Satun", "state_code": "91" },
    { "name": "Si Sa Ket", "state_code": "33" },
    { "name": "Sing Buri", "state_code": "17" },
    { "name": "Songkhla", "state_code": "90" },
    { "name": "Sukhothai", "state_code": "64" },
    { "name": "Suphan Buri", "state_code": "72" },
    { "name": "Surat Thani", "state_code": "84" },
    { "name": "Surin", "state_code": "32" },
    { "name": "Tak", "state_code": "63" },
    { "name": "Trang", "state_code": "92" },
    { "name": "Trat", "state_code": "23" },
    { "name": "Ubon Ratchathani", "state_code": "34" },
    { "name": "Udon Thani", "state_code": "41" },
    { "name": "Uthai Thani", "state_code": "61" },
    { "name": "Uttaradit", "state_code": "53" },
    { "name": "Yala", "state_code": "95" },
    { "name": "Yasothon", "state_code": "35" }],
    "BS": [{ "name": "Acklins", "state_code": "AK" },
    { "name": "Acklins and Crooked Islands", "state_code": "AC" },
    { "name": "Berry Islands", "state_code": "BY" },
    { "name": "Bimini", "state_code": "BI" },
    { "name": "Black Point", "state_code": "BP" },
    { "name": "Cat Island", "state_code": "CI" },
    { "name": "Central Abaco", "state_code": "CO" },
    { "name": "Central Andros", "state_code": "CS" },
    { "name": "Central Eleuthera", "state_code": "CE" },
    { "name": "Crooked Island", "state_code": "CK" },
    { "name": "East Grand Bahama", "state_code": "EG" },
    { "name": "Exuma", "state_code": "EX" },
    { "name": "Freeport", "state_code": "FP" },
    { "name": "Fresh Creek", "state_code": "FC" },
    { "name": "Governor's Harbour", "state_code": "GH" },
    { "name": "Grand Cay", "state_code": "GC" },
    { "name": "Green Turtle Cay", "state_code": "GT" },
    { "name": "Harbour Island", "state_code": "HI" },
    { "name": "High Rock", "state_code": "HR" },
    { "name": "Hope Town", "state_code": "HT" },
    { "name": "Inagua", "state_code": "IN" },
    { "name": "Kemps Bay", "state_code": "KB" },
    { "name": "Long Island", "state_code": "LI" },
    { "name": "Mangrove Cay", "state_code": "MC" },
    { "name": "Marsh Harbour", "state_code": "MH" },
    { "name": "Mayaguana District", "state_code": "MG" },
    { "name": "New Providence", "state_code": "NP" },
    { "name": "Nichollstown and Berry Islands", "state_code": "NB" },
    { "name": "North Abaco", "state_code": "NO" },
    { "name": "North Andros", "state_code": "NS" },
    { "name": "North Eleuthera", "state_code": "NE" },
    { "name": "Ragged Island", "state_code": "RI" },
    { "name": "Rock Sound", "state_code": "RS" },
    { "name": "Rum Cay District", "state_code": "RC" },
    { "name": "San Salvador and Rum Cay", "state_code": "SR" },
    { "name": "San Salvador Island", "state_code": "SS" },
    { "name": "Sandy Point", "state_code": "SP" },
    { "name": "South Abaco", "state_code": "SO" },
    { "name": "South Andros", "state_code": "SA" },
    { "name": "South Eleuthera", "state_code": "SE" },
    { "name": "Spanish Wells", "state_code": "SW" },
    { "name": "West Grand Bahama", "state_code": "WG" }],
    "TG": [{ "name": "Centrale Region", "state_code": "C" },
    { "name": "Kara Region", "state_code": "K" },
    { "name": "Maritime", "state_code": "M" },
    { "name": "Plateaux Region", "state_code": "P" },
    { "name": "Savanes Region", "state_code": "S" }],
    "TK": [],
    "TO": [{ "name": "Haʻapai", "state_code": "02" },
    { "name": "ʻEua", "state_code": "01" },
    { "name": "Niuas", "state_code": "03" },
    { "name": "Tongatapu", "state_code": "04" },
    { "name": "Vavaʻu", "state_code": "05" }],
    "TT": [{ "name": "Arima", "state_code": "ARI" },
    { "name": "Chaguanas", "state_code": "CHA" },
    { "name": "Couva-Tabaquite-Talparo Regional Corporation", "state_code": "CTT" },
    { "name": "Diego Martin Regional Corporation", "state_code": "DMN" },
    { "name": "Eastern Tobago", "state_code": "ETO" },
    { "name": "Penal-Debe Regional Corporation", "state_code": "PED" },
    { "name": "Point Fortin", "state_code": "PTF" },
    { "name": "Port of Spain", "state_code": "POS" },
    { "name": "Princes Town Regional Corporation", "state_code": "PRT" },
    { "name": "Rio Claro-Mayaro Regional Corporation", "state_code": "MRC" },
    { "name": "San Fernando", "state_code": "SFO" },
    { "name": "San Juan-Laventille Regional Corporation", "state_code": "SJL" },
    { "name": "Sangre Grande Regional Corporation", "state_code": "SGE" },
    { "name": "Siparia Regional Corporation", "state_code": "SIP" },
    { "name": "Tunapuna-Piarco Regional Corporation", "state_code": "TUP" },
    { "name": "Western Tobago", "state_code": "WTO" }],
    "TN": [{ "name": "Ariana Governorate", "state_code": "12" },
    { "name": "Ben Arous Governorate", "state_code": "13" },
    { "name": "Bizerte Governorate", "state_code": "23" },
    { "name": "Gabès Governorate", "state_code": "81" },
    { "name": "Gafsa Governorate", "state_code": "71" },
    { "name": "Jendouba Governorate", "state_code": "32" },
    { "name": "Kairouan Governorate", "state_code": "41" },
    { "name": "Kasserine Governorate", "state_code": "42" },
    { "name": "Kassrine", "state_code": "31" },
    { "name": "Kebili Governorate", "state_code": "73" },
    { "name": "Kef Governorate", "state_code": "33" },
    { "name": "Mahdia Governorate", "state_code": "53" },
    { "name": "Manouba Governorate", "state_code": "14" },
    { "name": "Medenine Governorate", "state_code": "82" },
    { "name": "Monastir Governorate", "state_code": "52" },
    { "name": "Sfax Governorate", "state_code": "61" },
    { "name": "Sidi Bouzid Governorate", "state_code": "43" },
    { "name": "Siliana Governorate", "state_code": "34" },
    { "name": "Sousse Governorate", "state_code": "51" },
    { "name": "Tataouine Governorate", "state_code": "83" },
    { "name": "Tozeur Governorate", "state_code": "72" },
    { "name": "Tunis Governorate", "state_code": "11" },
    { "name": "Zaghouan Governorate", "state_code": "22" }],
    "TR": [{ "name": "Adana", "state_code": "01" },
    { "name": "Adıyaman", "state_code": "02" },
    { "name": "Afyonkarahisar", "state_code": "03" },
    { "name": "Ağrı", "state_code": "04" },
    { "name": "Aksaray", "state_code": "68" },
    { "name": "Amasya", "state_code": "05" },
    { "name": "Ankara", "state_code": "06" },
    { "name": "Antalya", "state_code": "07" },
    { "name": "Ardahan", "state_code": "75" },
    { "name": "Artvin", "state_code": "08" },
    { "name": "Aydın", "state_code": "09" },
    { "name": "Balıkesir", "state_code": "10" },
    { "name": "Bartın", "state_code": "74" },
    { "name": "Batman", "state_code": "72" },
    { "name": "Bayburt", "state_code": "69" },
    { "name": "Bilecik", "state_code": "11" },
    { "name": "Bingöl", "state_code": "12" },
    { "name": "Bitlis", "state_code": "13" },
    { "name": "Bolu", "state_code": "14" },
    { "name": "Burdur", "state_code": "15" },
    { "name": "Bursa", "state_code": "16" },
    { "name": "Çanakkale", "state_code": "17" },
    { "name": "Çankırı", "state_code": "18" },
    { "name": "Çorum", "state_code": "19" },
    { "name": "Denizli", "state_code": "20" },
    { "name": "Diyarbakır", "state_code": "21" },
    { "name": "Düzce", "state_code": "81" },
    { "name": "Edirne", "state_code": "22" },
    { "name": "Elazığ", "state_code": "23" },
    { "name": "Erzincan", "state_code": "24" },
    { "name": "Erzurum", "state_code": "25" },
    { "name": "Eskişehir", "state_code": "26" },
    { "name": "Gaziantep", "state_code": "27" },
    { "name": "Giresun", "state_code": "28" },
    { "name": "Gümüşhane", "state_code": "29" },
    { "name": "Hakkâri", "state_code": "30" },
    { "name": "Hatay", "state_code": "31" },
    { "name": "Iğdır", "state_code": "76" },
    { "name": "Isparta", "state_code": "32" },
    { "name": "Istanbul", "state_code": "34" },
    { "name": "İzmir", "state_code": "35" },
    { "name": "Kahramanmaraş", "state_code": "46" },
    { "name": "Karabük", "state_code": "78" },
    { "name": "Karaman", "state_code": "70" },
    { "name": "Kars", "state_code": "36" },
    { "name": "Kastamonu", "state_code": "37" },
    { "name": "Kayseri", "state_code": "38" },
    { "name": "Kilis", "state_code": "79" },
    { "name": "Kırıkkale", "state_code": "71" },
    { "name": "Kırklareli", "state_code": "39" },
    { "name": "Kırşehir", "state_code": "40" },
    { "name": "Kocaeli", "state_code": "41" },
    { "name": "Konya", "state_code": "42" },
    { "name": "Kütahya", "state_code": "43" },
    { "name": "Malatya", "state_code": "44" },
    { "name": "Manisa", "state_code": "45" },
    { "name": "Mardin", "state_code": "47" },
    { "name": "Mersin", "state_code": "33" },
    { "name": "Muğla", "state_code": "48" },
    { "name": "Muş", "state_code": "49" },
    { "name": "Nevşehir", "state_code": "50" },
    { "name": "Niğde", "state_code": "51" },
    { "name": "Ordu", "state_code": "52" },
    { "name": "Osmaniye", "state_code": "80" },
    { "name": "Rize", "state_code": "53" },
    { "name": "Sakarya", "state_code": "54" },
    { "name": "Samsun", "state_code": "55" },
    { "name": "Şanlıurfa", "state_code": "63" },
    { "name": "Siirt", "state_code": "56" },
    { "name": "Sinop", "state_code": "57" },
    { "name": "Sivas", "state_code": "58" },
    { "name": "Şırnak", "state_code": "73" },
    { "name": "Tekirdağ", "state_code": "59" },
    { "name": "Tokat", "state_code": "60" },
    { "name": "Trabzon", "state_code": "61" },
    { "name": "Tunceli", "state_code": "62" },
    { "name": "Uşak", "state_code": "64" },
    { "name": "Van", "state_code": "65" },
    { "name": "Yalova", "state_code": "77" },
    { "name": "Yozgat", "state_code": "66" },
    { "name": "Zonguldak", "state_code": "67" }],
    "TM": [{ "name": "Ahal Region", "state_code": "A" },
    { "name": "Ashgabat", "state_code": "S" },
    { "name": "Balkan Region", "state_code": "B" },
    { "name": "Daşoguz Region", "state_code": "D" },
    { "name": "Lebap Region", "state_code": "L" },
    { "name": "Mary Region", "state_code": "M" }],
    "TC": [],
    "TV": [{ "name": "Funafuti", "state_code": "FUN" },
    { "name": "Nanumanga", "state_code": "NMG" },
    { "name": "Nanumea", "state_code": "NMA" },
    { "name": "Niutao Island Council", "state_code": "NIT" },
    { "name": "Nui", "state_code": "NUI" },
    { "name": "Nukufetau", "state_code": "NKF" },
    { "name": "Nukulaelae", "state_code": "NKL" },
    { "name": "Vaitupu", "state_code": "VAI" }],
    "UG": [{ "name": "Abim District", "state_code": "314" },
    { "name": "Adjumani District", "state_code": "301" },
    { "name": "Agago District", "state_code": "322" },
    { "name": "Alebtong District", "state_code": "323" },
    { "name": "Amolatar District", "state_code": "315" },
    { "name": "Amudat District", "state_code": "324" },
    { "name": "Amuria District", "state_code": "216" },
    { "name": "Amuru District", "state_code": "316" },
    { "name": "Apac District", "state_code": "302" },
    { "name": "Arua District", "state_code": "303" },
    { "name": "Budaka District", "state_code": "217" },
    { "name": "Bududa District", "state_code": "218" },
    { "name": "Bugiri District", "state_code": "201" },
    { "name": "Buhweju District", "state_code": "420" },
    { "name": "Buikwe District", "state_code": "117" },
    { "name": "Bukedea District", "state_code": "219" },
    { "name": "Bukomansimbi District", "state_code": "118" },
    { "name": "Bukwo District", "state_code": "220" },
    { "name": "Bulambuli District", "state_code": "225" },
    { "name": "Buliisa District", "state_code": "416" },
    { "name": "Bundibugyo District", "state_code": "401" },
    { "name": "Bunyangabu District", "state_code": "430" },
    { "name": "Bushenyi District", "state_code": "402" },
    { "name": "Busia District", "state_code": "202" },
    { "name": "Butaleja District", "state_code": "221" },
    { "name": "Butambala District", "state_code": "119" },
    { "name": "Butebo District", "state_code": "233" },
    { "name": "Buvuma District", "state_code": "120" },
    { "name": "Buyende District", "state_code": "226" },
    { "name": "Central Region", "state_code": "C" },
    { "name": "Dokolo District", "state_code": "317" },
    { "name": "Eastern Region", "state_code": "E" },
    { "name": "Gomba District", "state_code": "121" },
    { "name": "Gulu District", "state_code": "304" },
    { "name": "Ibanda District", "state_code": "417" },
    { "name": "Iganga District", "state_code": "203" },
    { "name": "Isingiro District", "state_code": "418" },
    { "name": "Jinja District", "state_code": "204" },
    { "name": "Kaabong District", "state_code": "318" },
    { "name": "Kabale District", "state_code": "404" },
    { "name": "Kabarole District", "state_code": "405" },
    { "name": "Kaberamaido District", "state_code": "213" },
    { "name": "Kagadi District", "state_code": "427" },
    { "name": "Kakumiro District", "state_code": "428" },
    { "name": "Kalangala District", "state_code": "101" },
    { "name": "Kaliro District", "state_code": "222" },
    { "name": "Kalungu District", "state_code": "122" },
    { "name": "Kampala District", "state_code": "102" },
    { "name": "Kamuli District", "state_code": "205" },
    { "name": "Kamwenge District", "state_code": "413" },
    { "name": "Kanungu District", "state_code": "414" },
    { "name": "Kapchorwa District", "state_code": "206" },
    { "name": "Kasese District", "state_code": "406" },
    { "name": "Katakwi District", "state_code": "207" },
    { "name": "Kayunga District", "state_code": "112" },
    { "name": "Kibaale District", "state_code": "407" },
    { "name": "Kiboga District", "state_code": "103" },
    { "name": "Kibuku District", "state_code": "227" },
    { "name": "Kiruhura District", "state_code": "419" },
    { "name": "Kiryandongo District", "state_code": "421" },
    { "name": "Kisoro District", "state_code": "408" },
    { "name": "Kitgum District", "state_code": "305" },
    { "name": "Koboko District", "state_code": "319" },
    { "name": "Kole District", "state_code": "325" },
    { "name": "Kotido District", "state_code": "306" },
    { "name": "Kumi District", "state_code": "208" },
    { "name": "Kween District", "state_code": "228" },
    { "name": "Kyankwanzi District", "state_code": "123" },
    { "name": "Kyegegwa District", "state_code": "422" },
    { "name": "Kyenjojo District", "state_code": "415" },
    { "name": "Kyotera District", "state_code": "125" },
    { "name": "Lamwo District", "state_code": "326" },
    { "name": "Lira District", "state_code": "307" },
    { "name": "Luuka District", "state_code": "229" },
    { "name": "Luwero District", "state_code": "104" },
    { "name": "Lwengo District", "state_code": "124" },
    { "name": "Lyantonde District", "state_code": "114" },
    { "name": "Manafwa District", "state_code": "223" },
    { "name": "Maracha District", "state_code": "320" },
    { "name": "Masaka District", "state_code": "105" },
    { "name": "Masindi District", "state_code": "409" },
    { "name": "Mayuge District", "state_code": "214" },
    { "name": "Mbale District", "state_code": "209" },
    { "name": "Mbarara District", "state_code": "410" },
    { "name": "Mitooma District", "state_code": "423" },
    { "name": "Mityana District", "state_code": "115" },
    { "name": "Moroto District", "state_code": "308" },
    { "name": "Moyo District", "state_code": "309" },
    { "name": "Mpigi District", "state_code": "106" },
    { "name": "Mubende District", "state_code": "107" },
    { "name": "Mukono District", "state_code": "108" },
    { "name": "Nakapiripirit District", "state_code": "311" },
    { "name": "Nakaseke District", "state_code": "116" },
    { "name": "Nakasongola District", "state_code": "109" },
    { "name": "Namayingo District", "state_code": "230" },
    { "name": "Namisindwa District", "state_code": "234" },
    { "name": "Namutumba District", "state_code": "224" },
    { "name": "Napak District", "state_code": "327" },
    { "name": "Nebbi District", "state_code": "310" },
    { "name": "Ngora District", "state_code": "231" },
    { "name": "Northern Region", "state_code": "N" },
    { "name": "Ntoroko District", "state_code": "424" },
    { "name": "Ntungamo District", "state_code": "411" },
    { "name": "Nwoya District", "state_code": "328" },
    { "name": "Omoro District", "state_code": "331" },
    { "name": "Otuke District", "state_code": "329" },
    { "name": "Oyam District", "state_code": "321" },
    { "name": "Pader District", "state_code": "312" },
    { "name": "Pakwach District", "state_code": "332" },
    { "name": "Pallisa District", "state_code": "210" },
    { "name": "Rakai District", "state_code": "110" },
    { "name": "Rubanda District", "state_code": "429" },
    { "name": "Rubirizi District", "state_code": "425" },
    { "name": "Rukiga District", "state_code": "431" },
    { "name": "Rukungiri District", "state_code": "412" },
    { "name": "Sembabule District", "state_code": "111" },
    { "name": "Serere District", "state_code": "232" },
    { "name": "Sheema District", "state_code": "426" },
    { "name": "Sironko District", "state_code": "215" },
    { "name": "Soroti District", "state_code": "211" },
    { "name": "Tororo District", "state_code": "212" },
    { "name": "Wakiso District", "state_code": "113" },
    { "name": "Western Region", "state_code": "W" },
    { "name": "Yumbe District", "state_code": "313" },
    { "name": "Zombo District", "state_code": "330" }],
    "UA": [{ "name": "Autonomous Republic of Crimea", "state_code": "43" },
    { "name": "Cherkaska oblast", "state_code": "71" },
    { "name": "Chernihivska oblast", "state_code": "74" },
    { "name": "Chernivetska oblast", "state_code": "77" },
    { "name": "Dnipropetrovska oblast", "state_code": "12" },
    { "name": "Donetska oblast", "state_code": "14" },
    { "name": "Ivano-Frankivska oblast", "state_code": "26" },
    { "name": "Kharkivska oblast", "state_code": "63" },
    { "name": "Khersonska oblast", "state_code": "65" },
    { "name": "Khmelnytska oblast", "state_code": "68" },
    { "name": "Kirovohradska oblast", "state_code": "35" },
    { "name": "Kyiv", "state_code": "30" },
    { "name": "Kyivska oblast", "state_code": "32" },
    { "name": "Luhanska oblast", "state_code": "09" },
    { "name": "Lvivska oblast", "state_code": "46" },
    { "name": "Mykolaivska oblast", "state_code": "48" },
    { "name": "Odeska oblast", "state_code": "51" },
    { "name": "Poltavska oblast", "state_code": "53" },
    { "name": "Rivnenska oblast", "state_code": "56" },
    { "name": "Sumska oblast", "state_code": "59" },
    { "name": "Ternopilska oblast", "state_code": "61" },
    { "name": "Vinnytska oblast", "state_code": "05" },
    { "name": "Volynska oblast", "state_code": "07" },
    { "name": "Zakarpatska Oblast", "state_code": "21" },
    { "name": "Zaporizka oblast", "state_code": "23" },
    { "name": "Zhytomyrska oblast", "state_code": "18" }],
    "AE": [{ "name": "Abu Dhabi Emirate", "state_code": "AZ" },
    { "name": "Ajman Emirate", "state_code": "AJ" },
    { "name": "Dubai", "state_code": "DU" },
    { "name": "Fujairah", "state_code": "FU" },
    { "name": "Ras al-Khaimah", "state_code": "RK" },
    { "name": "Sharjah Emirate", "state_code": "SH" },
    { "name": "Umm al-Quwain", "state_code": "UQ" }],
    "GB": [{ "name": "Aberdeen", "state_code": "ABE" },
    { "name": "Aberdeenshire", "state_code": "ABD" },
    { "name": "Angus", "state_code": "ANS" },
    { "name": "Antrim", "state_code": "ANT" },
    { "name": "Antrim and Newtownabbey", "state_code": "ANN" },
    { "name": "Ards", "state_code": "ARD" },
    { "name": "Ards and North Down", "state_code": "AND" },
    { "name": "Argyll and Bute", "state_code": "AGB" },
    { "name": "Armagh City and District Council", "state_code": "ARM" },
    { "name": "Armagh, Banbridge and Craigavon", "state_code": "ABC" },
    { "name": "Ascension Island", "state_code": "SH-AC" },
    { "name": "Ballymena Borough", "state_code": "BLA" },
    { "name": "Ballymoney", "state_code": "BLY" },
    { "name": "Banbridge", "state_code": "BNB" },
    { "name": "Barnsley", "state_code": "BNS" },
    { "name": "Bath and North East Somerset", "state_code": "BAS" },
    { "name": "Bedford", "state_code": "BDF" },
    { "name": "Belfast district", "state_code": "BFS" },
    { "name": "Birmingham", "state_code": "BIR" },
    { "name": "Blackburn with Darwen", "state_code": "BBD" },
    { "name": "Blackpool", "state_code": "BPL" },
    { "name": "Blaenau Gwent County Borough", "state_code": "BGW" },
    { "name": "Bolton", "state_code": "BOL" },
    { "name": "Bournemouth", "state_code": "BMH" },
    { "name": "Bracknell Forest", "state_code": "BRC" },
    { "name": "Bradford", "state_code": "BRD" },
    { "name": "Bridgend County Borough", "state_code": "BGE" },
    { "name": "Brighton and Hove", "state_code": "BNH" },
    { "name": "Buckinghamshire", "state_code": "BKM" },
    { "name": "Bury", "state_code": "BUR" },
    { "name": "Caerphilly County Borough", "state_code": "CAY" },
    { "name": "Calderdale", "state_code": "CLD" },
    { "name": "Cambridgeshire", "state_code": "CAM" },
    { "name": "Carmarthenshire", "state_code": "CMN" },
    { "name": "Carrickfergus Borough Council", "state_code": "CKF" },
    { "name": "Castlereagh", "state_code": "CSR" },
    { "name": "Causeway Coast and Glens", "state_code": "CCG" },
    { "name": "Central Bedfordshire", "state_code": "CBF" },
    { "name": "Ceredigion", "state_code": "CGN" },
    { "name": "Cheshire East", "state_code": "CHE" },
    { "name": "Cheshire West and Chester", "state_code": "CHW" },
    { "name": "City and County of Cardiff", "state_code": "CRF" },
    { "name": "City and County of Swansea", "state_code": "SWA" },
    { "name": "City of Bristol", "state_code": "BST" },
    { "name": "City of Derby", "state_code": "DER" },
    { "name": "City of Kingston upon Hull", "state_code": "KHL" },
    { "name": "City of Leicester", "state_code": "LCE" },
    { "name": "City of London", "state_code": "LND" },
    { "name": "City of Nottingham", "state_code": "NGM" },
    { "name": "City of Peterborough", "state_code": "PTE" },
    { "name": "City of Plymouth", "state_code": "PLY" },
    { "name": "City of Portsmouth", "state_code": "POR" },
    { "name": "City of Southampton", "state_code": "STH" },
    { "name": "City of Stoke-on-Trent", "state_code": "STE" },
    { "name": "City of Sunderland", "state_code": "SND" },
    { "name": "City of Westminster", "state_code": "WSM" },
    { "name": "City of Wolverhampton", "state_code": "WLV" },
    { "name": "City of York", "state_code": "YOR" },
    { "name": "Clackmannanshire", "state_code": "CLK" },
    { "name": "Coleraine Borough Council", "state_code": "CLR" },
    { "name": "Conwy County Borough", "state_code": "CWY" },
    { "name": "Cookstown District Council", "state_code": "CKT" },
    { "name": "Cornwall", "state_code": "CON" },
    { "name": "County Durham", "state_code": "DUR" },
    { "name": "Coventry", "state_code": "COV" },
    { "name": "Craigavon Borough Council", "state_code": "CGV" },
    { "name": "Cumbria", "state_code": "CMA" },
    { "name": "Darlington", "state_code": "DAL" },
    { "name": "Denbighshire", "state_code": "DEN" },
    { "name": "Derbyshire", "state_code": "DBY" },
    { "name": "Derry City and Strabane", "state_code": "DRS" },
    { "name": "Derry City Council", "state_code": "DRY" },
    { "name": "Devon", "state_code": "DEV" },
    { "name": "Doncaster", "state_code": "DNC" },
    { "name": "Dorset", "state_code": "DOR" },
    { "name": "Down District Council", "state_code": "DOW" },
    { "name": "Dudley", "state_code": "DUD" },
    { "name": "Dumfries and Galloway", "state_code": "DGY" },
    { "name": "Dundee", "state_code": "DND" },
    { "name": "Dungannon and South Tyrone Borough Council", "state_code": "DGN" },
    { "name": "East Ayrshire", "state_code": "EAY" },
    { "name": "East Dunbartonshire", "state_code": "EDU" },
    { "name": "East Lothian", "state_code": "ELN" },
    { "name": "East Renfrewshire", "state_code": "ERW" },
    { "name": "East Riding of Yorkshire", "state_code": "ERY" },
    { "name": "East Sussex", "state_code": "ESX" },
    { "name": "Edinburgh", "state_code": "EDH" },
    { "name": "England", "state_code": "ENG" },
    { "name": "Essex", "state_code": "ESS" },
    { "name": "Falkirk", "state_code": "FAL" },
    { "name": "Fermanagh and Omagh", "state_code": "FMO" },
    { "name": "Fermanagh District Council", "state_code": "FER" },
    { "name": "Fife", "state_code": "FIF" },
    { "name": "Flintshire", "state_code": "FLN" },
    { "name": "Gateshead", "state_code": "GAT" },
    { "name": "Glasgow", "state_code": "GLG" },
    { "name": "Gloucestershire", "state_code": "GLS" },
    { "name": "Gwynedd", "state_code": "GWN" },
    { "name": "Halton", "state_code": "HAL" },
    { "name": "Hampshire", "state_code": "HAM" },
    { "name": "Hartlepool", "state_code": "HPL" },
    { "name": "Herefordshire", "state_code": "HEF" },
    { "name": "Hertfordshire", "state_code": "HRT" },
    { "name": "Highland", "state_code": "HLD" },
    { "name": "Inverclyde", "state_code": "IVC" },
    { "name": "Isle of Wight", "state_code": "IOW" },
    { "name": "Isles of Scilly", "state_code": "IOS" },
    { "name": "Kent", "state_code": "KEN" },
    { "name": "Kirklees", "state_code": "KIR" },
    { "name": "Knowsley", "state_code": "KWL" },
    { "name": "Lancashire", "state_code": "LAN" },
    { "name": "Larne Borough Council", "state_code": "LRN" },
    { "name": "Leeds", "state_code": "LDS" },
    { "name": "Leicestershire", "state_code": "LEC" },
    { "name": "Limavady Borough Council", "state_code": "LMV" },
    { "name": "Lincolnshire", "state_code": "LIN" },
    { "name": "Lisburn and Castlereagh", "state_code": "LBC" },
    { "name": "Lisburn City Council", "state_code": "LSB" },
    { "name": "Liverpool", "state_code": "LIV" },
    { "name": "London Borough of Barking and Dagenham", "state_code": "BDG" },
    { "name": "London Borough of Barnet", "state_code": "BNE" },
    { "name": "London Borough of Bexley", "state_code": "BEX" },
    { "name": "London Borough of Brent", "state_code": "BEN" },
    { "name": "London Borough of Bromley", "state_code": "BRY" },
    { "name": "London Borough of Camden", "state_code": "CMD" },
    { "name": "London Borough of Croydon", "state_code": "CRY" },
    { "name": "London Borough of Ealing", "state_code": "EAL" },
    { "name": "London Borough of Enfield", "state_code": "ENF" },
    { "name": "London Borough of Hackney", "state_code": "HCK" },
    { "name": "London Borough of Hammersmith and Fulham", "state_code": "HMF" },
    { "name": "London Borough of Haringey", "state_code": "HRY" },
    { "name": "London Borough of Harrow", "state_code": "HRW" },
    { "name": "London Borough of Havering", "state_code": "HAV" },
    { "name": "London Borough of Hillingdon", "state_code": "HIL" },
    { "name": "London Borough of Hounslow", "state_code": "HNS" },
    { "name": "London Borough of Islington", "state_code": "ISL" },
    { "name": "London Borough of Lambeth", "state_code": "LBH" },
    { "name": "London Borough of Lewisham", "state_code": "LEW" },
    { "name": "London Borough of Merton", "state_code": "MRT" },
    { "name": "London Borough of Newham", "state_code": "NWM" },
    { "name": "London Borough of Redbridge", "state_code": "RDB" },
    { "name": "London Borough of Richmond upon Thames", "state_code": "RIC" },
    { "name": "London Borough of Southwark", "state_code": "SWK" },
    { "name": "London Borough of Sutton", "state_code": "STN" },
    { "name": "London Borough of Tower Hamlets", "state_code": "TWH" },
    { "name": "London Borough of Waltham Forest", "state_code": "WFT" },
    { "name": "London Borough of Wandsworth", "state_code": "WND" },
    { "name": "Magherafelt District Council", "state_code": "MFT" },
    { "name": "Manchester", "state_code": "MAN" },
    { "name": "Medway", "state_code": "MDW" },
    { "name": "Merthyr Tydfil County Borough", "state_code": "MTY" },
    { "name": "Metropolitan Borough of Wigan", "state_code": "WGN" },
    { "name": "Mid and East Antrim", "state_code": "MEA" },
    { "name": "Mid Ulster", "state_code": "MUL" },
    { "name": "Middlesbrough", "state_code": "MDB" },
    { "name": "Midlothian", "state_code": "MLN" },
    { "name": "Milton Keynes", "state_code": "MIK" },
    { "name": "Monmouthshire", "state_code": "MON" },
    { "name": "Moray", "state_code": "MRY" },
    { "name": "Moyle District Council", "state_code": "MYL" },
    { "name": "Neath Port Talbot County Borough", "state_code": "NTL" },
    { "name": "Newcastle upon Tyne", "state_code": "NET" },
    { "name": "Newport", "state_code": "NWP" },
    { "name": "Newry and Mourne District Council", "state_code": "NYM" },
    { "name": "Newry, Mourne and Down", "state_code": "NMD" },
    { "name": "Newtownabbey Borough Council", "state_code": "NTA" },
    { "name": "Norfolk", "state_code": "NFK" },
    { "name": "North Ayrshire", "state_code": "NAY" },
    { "name": "North Down Borough Council", "state_code": "NDN" },
    { "name": "North East Lincolnshire", "state_code": "NEL" },
    { "name": "North Lanarkshire", "state_code": "NLK" },
    { "name": "North Lincolnshire", "state_code": "NLN" },
    { "name": "North Somerset", "state_code": "NSM" },
    { "name": "North Tyneside", "state_code": "NTY" },
    { "name": "North Yorkshire", "state_code": "NYK" },
    { "name": "Northamptonshire", "state_code": "NTH" },
    { "name": "Northern Ireland", "state_code": "NIR" },
    { "name": "Northumberland", "state_code": "NBL" },
    { "name": "Nottinghamshire", "state_code": "NTT" },
    { "name": "Oldham", "state_code": "OLD" },
    { "name": "Omagh District Council", "state_code": "OMH" },
    { "name": "Orkney Islands", "state_code": "ORK" },
    { "name": "Outer Hebrides", "state_code": "ELS" },
    { "name": "Oxfordshire", "state_code": "OXF" },
    { "name": "Pembrokeshire", "state_code": "PEM" },
    { "name": "Perth and Kinross", "state_code": "PKN" },
    { "name": "Poole", "state_code": "POL" },
    { "name": "Powys", "state_code": "POW" },
    { "name": "Reading", "state_code": "RDG" },
    { "name": "Redcar and Cleveland", "state_code": "RCC" },
    { "name": "Renfrewshire", "state_code": "RFW" },
    { "name": "Rhondda Cynon Taf", "state_code": "RCT" },
    { "name": "Rochdale", "state_code": "RCH" },
    { "name": "Rotherham", "state_code": "ROT" },
    { "name": "Royal Borough of Greenwich", "state_code": "GRE" },
    { "name": "Royal Borough of Kensington and Chelsea", "state_code": "KEC" },
    { "name": "Royal Borough of Kingston upon Thames", "state_code": "KTT" },
    { "name": "Rutland", "state_code": "RUT" },
    { "name": "Saint Helena", "state_code": "SH-HL" },
    { "name": "Salford", "state_code": "SLF" },
    { "name": "Sandwell", "state_code": "SAW" },
    { "name": "Scotland", "state_code": "SCT" },
    { "name": "Scottish Borders", "state_code": "SCB" },
    { "name": "Sefton", "state_code": "SFT" },
    { "name": "Sheffield", "state_code": "SHF" },
    { "name": "Shetland Islands", "state_code": "ZET" },
    { "name": "Shropshire", "state_code": "SHR" },
    { "name": "Slough", "state_code": "SLG" },
    { "name": "Solihull", "state_code": "SOL" },
    { "name": "Somerset", "state_code": "SOM" },
    { "name": "South Ayrshire", "state_code": "SAY" },
    { "name": "South Gloucestershire", "state_code": "SGC" },
    { "name": "South Lanarkshire", "state_code": "SLK" },
    { "name": "South Tyneside", "state_code": "STY" },
    { "name": "Southend-on-Sea", "state_code": "SOS" },
    { "name": "St Helens", "state_code": "SHN" },
    { "name": "Staffordshire", "state_code": "STS" },
    { "name": "Stirling", "state_code": "STG" },
    { "name": "Stockport", "state_code": "SKP" },
    { "name": "Stockton-on-Tees", "state_code": "STT" },
    { "name": "Strabane District Council", "state_code": "STB" },
    { "name": "Suffolk", "state_code": "SFK" },
    { "name": "Surrey", "state_code": "SRY" },
    { "name": "Swindon", "state_code": "SWD" },
    { "name": "Tameside", "state_code": "TAM" },
    { "name": "Telford and Wrekin", "state_code": "TFW" },
    { "name": "Thurrock", "state_code": "THR" },
    { "name": "Torbay", "state_code": "TOB" },
    { "name": "Torfaen", "state_code": "TOF" },
    { "name": "Trafford", "state_code": "TRF" },
    { "name": "United Kingdom", "state_code": "UKM" },
    { "name": "Vale of Glamorgan", "state_code": "VGL" },
    { "name": "Wakefield", "state_code": "WKF" },
    { "name": "Wales", "state_code": "WLS" },
    { "name": "Walsall", "state_code": "WLL" },
    { "name": "Warrington", "state_code": "WRT" },
    { "name": "Warwickshire", "state_code": "WAR" },
    { "name": "West Berkshire", "state_code": "WBK" },
    { "name": "West Dunbartonshire", "state_code": "WDU" },
    { "name": "West Lothian", "state_code": "WLN" },
    { "name": "West Sussex", "state_code": "WSX" },
    { "name": "Wiltshire", "state_code": "WIL" },
    { "name": "Windsor and Maidenhead", "state_code": "WNM" },
    { "name": "Wirral", "state_code": "WRL" },
    { "name": "Wokingham", "state_code": "WOK" },
    { "name": "Worcestershire", "state_code": "WOR" },
    { "name": "Wrexham County Borough", "state_code": "WRX" }],
    "US": [{ "name": "Alabama", "state_code": "AL" },
    { "name": "Alaska", "state_code": "AK" },
    { "name": "American Samoa", "state_code": "AS" },
    { "name": "Arizona", "state_code": "AZ" },
    { "name": "Arkansas", "state_code": "AR" },
    { "name": "Baker Island", "state_code": "UM-81" },
    { "name": "California", "state_code": "CA" },
    { "name": "Colorado", "state_code": "CO" },
    { "name": "Connecticut", "state_code": "CT" },
    { "name": "Delaware", "state_code": "DE" },
    { "name": "District of Columbia", "state_code": "DC" },
    { "name": "Florida", "state_code": "FL" },
    { "name": "Georgia", "state_code": "GA" },
    { "name": "Guam", "state_code": "GU" },
    { "name": "Hawaii", "state_code": "HI" },
    { "name": "Howland Island", "state_code": "UM-84" },
    { "name": "Idaho", "state_code": "ID" },
    { "name": "Illinois", "state_code": "IL" },
    { "name": "Indiana", "state_code": "IN" },
    { "name": "Iowa", "state_code": "IA" },
    { "name": "Jarvis Island", "state_code": "UM-86" },
    { "name": "Johnston Atoll", "state_code": "UM-67" },
    { "name": "Kansas", "state_code": "KS" },
    { "name": "Kentucky", "state_code": "KY" },
    { "name": "Kingman Reef", "state_code": "UM-89" },
    { "name": "Louisiana", "state_code": "LA" },
    { "name": "Maine", "state_code": "ME" },
    { "name": "Maryland", "state_code": "MD" },
    { "name": "Massachusetts", "state_code": "MA" },
    { "name": "Michigan", "state_code": "MI" },
    { "name": "Midway Atoll", "state_code": "UM-71" },
    { "name": "Minnesota", "state_code": "MN" },
    { "name": "Mississippi", "state_code": "MS" },
    { "name": "Missouri", "state_code": "MO" },
    { "name": "Montana", "state_code": "MT" },
    { "name": "Navassa Island", "state_code": "UM-76" },
    { "name": "Nebraska", "state_code": "NE" },
    { "name": "Nevada", "state_code": "NV" },
    { "name": "New Hampshire", "state_code": "NH" },
    { "name": "New Jersey", "state_code": "NJ" },
    { "name": "New Mexico", "state_code": "NM" },
    { "name": "New York", "state_code": "NY" },
    { "name": "North Carolina", "state_code": "NC" },
    { "name": "North Dakota", "state_code": "ND" },
    { "name": "Northern Mariana Islands", "state_code": "MP" },
    { "name": "Ohio", "state_code": "OH" },
    { "name": "Oklahoma", "state_code": "OK" },
    { "name": "Oregon", "state_code": "OR" },
    { "name": "Palmyra Atoll", "state_code": "UM-95" },
    { "name": "Pennsylvania", "state_code": "PA" },
    { "name": "Puerto Rico", "state_code": "PR" },
    { "name": "Rhode Island", "state_code": "RI" },
    { "name": "South Carolina", "state_code": "SC" },
    { "name": "South Dakota", "state_code": "SD" },
    { "name": "Tennessee", "state_code": "TN" },
    { "name": "Texas", "state_code": "TX" },
    { "name": "United States Minor Outlying Islands", "state_code": "UM" },
    { "name": "United States Virgin Islands", "state_code": "VI" },
    { "name": "Utah", "state_code": "UT" },
    { "name": "Vermont", "state_code": "VT" },
    { "name": "Virginia", "state_code": "VA" },
    { "name": "Wake Island", "state_code": "UM-79" },
    { "name": "Washington", "state_code": "WA" },
    { "name": "West Virginia", "state_code": "WV" },
    { "name": "Wisconsin", "state_code": "WI" },
    { "name": "Wyoming", "state_code": "WY" }],
    "UM": [],
    "UY": [{ "name": "Artigas Department", "state_code": "AR" },
    { "name": "Canelones Department", "state_code": "CA" },
    { "name": "Cerro Largo Department", "state_code": "CL" },
    { "name": "Colonia Department", "state_code": "CO" },
    { "name": "Durazno Department", "state_code": "DU" },
    { "name": "Flores Department", "state_code": "FS" },
    { "name": "Florida Department", "state_code": "FD" },
    { "name": "Lavalleja Department", "state_code": "LA" },
    { "name": "Maldonado Department", "state_code": "MA" },
    { "name": "Montevideo Department", "state_code": "MO" },
    { "name": "Paysandú Department", "state_code": "PA" },
    { "name": "Río Negro Department", "state_code": "RN" },
    { "name": "Rivera Department", "state_code": "RV" },
    { "name": "Rocha Department", "state_code": "RO" },
    { "name": "Salto Department", "state_code": "SA" },
    { "name": "San José Department", "state_code": "SJ" },
    { "name": "Soriano Department", "state_code": "SO" },
    { "name": "Tacuarembó Department", "state_code": "TA" },
    { "name": "Treinta y Tres Department", "state_code": "TT" }],
    "UZ": [{ "name": "Andijan Region", "state_code": "AN" },
    { "name": "Bukhara Region", "state_code": "BU" },
    { "name": "Fergana Region", "state_code": "FA" },
    { "name": "Jizzakh Region", "state_code": "JI" },
    { "name": "Karakalpakstan", "state_code": "QR" },
    { "name": "Namangan Region", "state_code": "NG" },
    { "name": "Navoiy Region", "state_code": "NW" },
    { "name": "Qashqadaryo Region", "state_code": "QA" },
    { "name": "Samarqand Region", "state_code": "SA" },
    { "name": "Sirdaryo Region", "state_code": "SI" },
    { "name": "Surxondaryo Region", "state_code": "SU" },
    { "name": "Tashkent", "state_code": "TK" },
    { "name": "Tashkent Region", "state_code": "TO" },
    { "name": "Xorazm Region", "state_code": "XO" }],
    "VU": [{ "name": "Malampa", "state_code": "MAP" },
    { "name": "Penama", "state_code": "PAM" },
    { "name": "Sanma", "state_code": "SAM" },
    { "name": "Shefa", "state_code": "SEE" },
    { "name": "Tafea", "state_code": "TAE" },
    { "name": "Torba", "state_code": "TOB" }],
    "VA": [],
    "VE": [{ "name": "Amazonas", "state_code": "Z" },
    { "name": "Anzoátegui", "state_code": "B" },
    { "name": "Apure", "state_code": "C" },
    { "name": "Aragua", "state_code": "D" },
    { "name": "Barinas", "state_code": "E" },
    { "name": "Bolívar", "state_code": "F" },
    { "name": "Carabobo", "state_code": "G" },
    { "name": "Cojedes", "state_code": "H" },
    { "name": "Delta Amacuro", "state_code": "Y" },
    { "name": "Distrito Capital", "state_code": "A" },
    { "name": "Falcón", "state_code": "I" },
    { "name": "Federal Dependencies of Venezuela", "state_code": "W" },
    { "name": "Guárico", "state_code": "J" },
    { "name": "La Guaira", "state_code": "X" },
    { "name": "Lara", "state_code": "K" },
    { "name": "Mérida", "state_code": "L" },
    { "name": "Miranda", "state_code": "M" },
    { "name": "Monagas", "state_code": "N" },
    { "name": "Nueva Esparta", "state_code": "O" },
    { "name": "Portuguesa", "state_code": "P" },
    { "name": "Sucre", "state_code": "R" },
    { "name": "Táchira", "state_code": "S" },
    { "name": "Trujillo", "state_code": "T" },
    { "name": "Yaracuy", "state_code": "U" },
    { "name": "Zulia", "state_code": "V" }],
    "VN": [{ "name": "An Giang", "state_code": "44" },
    { "name": "Bà Rịa-Vũng Tàu", "state_code": "43" },
    { "name": "Bắc Giang", "state_code": "54" },
    { "name": "Bắc Kạn", "state_code": "53" },
    { "name": "Bạc Liêu", "state_code": "55" },
    { "name": "Bắc Ninh", "state_code": "56" },
    { "name": "Bến Tre", "state_code": "50" },
    { "name": "Bình Dương", "state_code": "57" },
    { "name": "Bình Định", "state_code": "31" },
    { "name": "Bình Phước", "state_code": "58" },
    { "name": "Bình Thuận", "state_code": "40" },
    { "name": "Cà Mau", "state_code": "59" },
    { "name": "Cần Thơ", "state_code": "CT" },
    { "name": "Cao Bằng", "state_code": "04" },
    { "name": "Đà Nẵng", "state_code": "DN" },
    { "name": "Đắk Lắk", "state_code": "33" },
    { "name": "Đắk Nông", "state_code": "72" },
    { "name": "Điện Biên", "state_code": "71" },
    { "name": "Đồng Nai", "state_code": "39" },
    { "name": "Đồng Tháp", "state_code": "45" },
    { "name": "Gia Lai", "state_code": "30" },
    { "name": "Hà Giang", "state_code": "03" },
    { "name": "Hà Nam", "state_code": "63" },
    { "name": "Hà Nội", "state_code": "HN" },
    { "name": "Hà Tĩnh", "state_code": "23" },
    { "name": "Hải Dương", "state_code": "61" },
    { "name": "Hải Phòng", "state_code": "HP" },
    { "name": "Hậu Giang", "state_code": "73" },
    { "name": "Hồ Chí Minh", "state_code": "SG" },
    { "name": "Hòa Bình", "state_code": "14" },
    { "name": "Hưng Yên", "state_code": "66" },
    { "name": "Khánh Hòa", "state_code": "34" },
    { "name": "Kiên Giang", "state_code": "47" },
    { "name": "Kon Tum", "state_code": "28" },
    { "name": "Lai Châu", "state_code": "01" },
    { "name": "Lâm Đồng", "state_code": "35" },
    { "name": "Lạng Sơn", "state_code": "09" },
    { "name": "Lào Cai", "state_code": "02" },
    { "name": "Long An", "state_code": "41" },
    { "name": "Nam Định", "state_code": "67" },
    { "name": "Nghệ An", "state_code": "22" },
    { "name": "Ninh Bình", "state_code": "18" },
    { "name": "Ninh Thuận", "state_code": "36" },
    { "name": "Phú Thọ", "state_code": "68" },
    { "name": "Phú Yên", "state_code": "32" },
    { "name": "Quảng Bình", "state_code": "24" },
    { "name": "Quảng Nam", "state_code": "27" },
    { "name": "Quảng Ngãi", "state_code": "29" },
    { "name": "Quảng Ninh", "state_code": "13" },
    { "name": "Quảng Trị", "state_code": "25" },
    { "name": "Sóc Trăng", "state_code": "52" },
    { "name": "Sơn La", "state_code": "05" },
    { "name": "Tây Ninh", "state_code": "37" },
    { "name": "Thái Bình", "state_code": "20" },
    { "name": "Thái Nguyên", "state_code": "69" },
    { "name": "Thanh Hóa", "state_code": "21" },
    { "name": "Thừa Thiên-Huế", "state_code": "26" },
    { "name": "Tiền Giang", "state_code": "46" },
    { "name": "Trà Vinh", "state_code": "51" },
    { "name": "Tuyên Quang", "state_code": "07" },
    { "name": "Vĩnh Long", "state_code": "49" },
    { "name": "Vĩnh Phúc", "state_code": "70" },
    { "name": "Yên Bái", "state_code": "06" }],
    "VG": [],
    "VI": [],
    "WF": [],
    "EH": [],
    "YE": [{ "name": "'Adan Governorate", "state_code": "AD" },
    { "name": "'Amran Governorate", "state_code": "AM" },
    { "name": "Abyan Governorate", "state_code": "AB" },
    { "name": "Al Bayda' Governorate", "state_code": "BA" },
    { "name": "Al Hudaydah Governorate", "state_code": "HU" },
    { "name": "Al Jawf Governorate", "state_code": "JA" },
    { "name": "Al Mahrah Governorate", "state_code": "MR" },
    { "name": "Al Mahwit Governorate", "state_code": "MW" },
    { "name": "Dhamar Governorate", "state_code": "DH" },
    { "name": "Hadhramaut Governorate", "state_code": "HD" },
    { "name": "Hajjah Governorate", "state_code": "HJ" },
    { "name": "Ibb Governorate", "state_code": "IB" },
    { "name": "Lahij Governorate", "state_code": "LA" },
    { "name": "Ma'rib Governorate", "state_code": "MA" },
    { "name": "Raymah Governorate", "state_code": "RA" },
    { "name": "Saada Governorate", "state_code": "SD" },
    { "name": "Sana'a", "state_code": "SA" },
    { "name": "Sana'a Governorate", "state_code": "SN" },
    { "name": "Shabwah Governorate", "state_code": "SH" },
    { "name": "Socotra Governorate", "state_code": "SU" },
    { "name": "Ta'izz Governorate", "state_code": "TA" }],

    "ZM": [
        { "name": "Central Province", "state_code": "02" },
        { "name": "Copperbelt Province", "state_code": "08" },
        { "name": "Eastern Province", "state_code": "03" },
        { "name": "Luapula Province", "state_code": "04" },
        { "name": "Lusaka Province", "state_code": "09" },
        { "name": "Muchinga Province", "state_code": "10" },
        { "name": "Northern Province", "state_code": "05" },
        { "name": "Northwestern Province", "state_code": "06" },
        { "name": "Southern Province", "state_code": "07" },
        { "name": "Western Province", "state_code": "01" }
    ],


    "ZW": [{ "name": "Bulawayo Province", "state_code": "BU" },
    { "name": "Harare Province", "state_code": "HA" },
    { "name": "Manicaland", "state_code": "MA" },
    { "name": "Mashonaland Central Province", "state_code": "MC" },
    { "name": "Mashonaland East Province", "state_code": "ME" },
    { "name": "Mashonaland West Province", "state_code": "MW" },
    { "name": "Masvingo Province", "state_code": "MV" },
    { "name": "Matabeleland North Province", "state_code": "MN" },
    { "name": "Matabeleland South Province", "state_code": "MS" },
    { "name": "Midlands Province", "state_code": "MI" }]
}