import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import logo from '../img/logo.png'
import success from '../img/success.svg'

export const LoginSuccess = () => {

  const navigate = useNavigate();

  const [data, setdata] = useState([])

  useEffect(() => {
    let cookie = document.cookie
    let cookie_obj = {}
    if (cookie == '') {
      navigate('/')
    }

    cookie.split(';').map(el => {
      let data = el.split('=');
      if (data.length == 2) {
        cookie_obj[data[0].trim()] = data[1].trim()
      }
      else {
        cookie_obj[data[0].trim()] = data[0].trim()
      }
    })

    setdata(cookie_obj);
  }, [])

  return (
    <div className='loginsuccess'>
      <div>
        <img className='logo' src={logo} />
        <div className='btom_nav txt2'>Juliette Inc.</div>
        <div className='txt1'>Thanks for registering! <img src={success} /></div>
        <div className='txt2'>{data.card_type?.toUpperCase()} ending with {data.card_cvv} has been successfully added. </div>
      </div>
    </div>
  )
}                 
