import './style/main.css'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useEffect } from 'react';

import { Login } from './Components/Login';
import { LoginSuccess } from './Components/LoginSuccess';

function App() {

  let count = 3

  const card_type_animation = document.getElementsByClassName('card_type_animation')

  var timeout = '';

  useEffect(() => {
    timeout = clearTimeout()
  }, [])

  const set_card_to_show = () => {
    // animates the 4rth card in the card no to change infinitely
    timeout = clearTimeout()
    if (count + 1 > 3) {
      count = -1
    }
    count += 1;
    [...card_type_animation].map(el => el.className = 'card_type_animation not_visible');
    if (card_type_animation[count]) {

      card_type_animation[count].className = 'card_type_animation visible'
    }
    timeout = setTimeout(() => set_card_to_show(), 1500)
  }

  set_card_to_show()

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/success' element={<LoginSuccess />} />
          <Route path='/*' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
