import React from 'react'
import network_err from '../img/network_err.svg'

export const LoginFailure = () => {
  return (
    <div className='loginfailure'>
      <div className='modal'>
        <img src={network_err} />
        <div className='txt1'>Check your network connection</div>
        <div className='txt2'>There was some problem getting started. Please check your network connection.</div>
        <div className='try_again' onClick={()=>window.location.reload()}>Try Again !</div>
      </div>
    </div>
  )
}
